// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import {
  removeStorageData,getStorageData
} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface RowData {
  id: string | number;
  attributes: {
    first_name: string;
    last_name: string;
    email: string;
    job_title: string;
    role_id: string;
    members: number;
    dataTime: string;
  };
}
interface SearchList {
  attributes: any;
  id: string | number;
  first_name: string;
  last_name:string;
  email: string;
  teams: string;
  role: string;
  invited_status: boolean;
}

interface Errors {
  memberFirstName: string;
  memberLastName: string;
  memberEmail: string;
  jobTitle: string;
  role: string;
}

interface MembersList {
  attributes: any;
  id: string | number;
  first_name: string;
  last_name:string;
  email: string;
  teams: string;
  role: string;
  invited_status: boolean;
}

interface TeamData {
  id:number | string;
  teamName:string;
}

interface S {
  messageToggle: boolean;
  tostifymessage: string;
  status: string;
  loading: boolean;
  isAddMemberModalOpen: boolean;
  users: any;
  inputName: string;
  selectedMembers: any;
  newValue: string;
  anchorEl: any;
  filteredUsers: any;
  selectedUsers: any;
  teamName: string;
  teamId: number;
  memberError: string;
  rows: RowData[];
  membersList: MembersList[];
  searchList: SearchList[];
  isRoleOpen: boolean;
  selectedRoles: string[];
  isJobTitleOpen: boolean;
  selectedJobTitle: string[];
  isApproveOpen: boolean;
  activated: string[];
  isRenameModalOpen: boolean;
  selectedRow: null | RowData;
  isAddNot: boolean;
  selectedOrder: string[];
  isDeleteMemberModalOpen: boolean;
  teamNameRenameError: boolean;
  teamRenameBlankError: boolean;
  teamRename: string;
  historyLocationState: string;
  role: string | undefined;
  memberEmail: string | undefined;
  memberFirstName: string | undefined;
  memberLastName: string | undefined;
  jobTitle: string | undefined;
  isEditDetailModalOpen: boolean;
  memberFirstNameError: boolean;
  memberLastNameError: boolean;
  memberEmailError: boolean;
  jobTitleError: boolean;
  emailFormatError: boolean;
  memberDetailError: boolean;
  isMoveMemberModalOpen:boolean;
  emailChanged: boolean;
  teamOptions:string;
  teamData:TeamData[];
  teamNameTitle:any;
  moveMemberError:string;
  isCreateTeamModalOpen:boolean;
  blankError:boolean;
  teamNameError:boolean;
  errorData:string;
  teamNameData:string;
  jobTitles:any;

  searchValue:string;
  currentPage:number;
  rowsPerPage:number;
  dataRow:any;
  totalRecords:any;
  totalMembers:number;
}

interface SS {
  id: any;
}

export default class AddNewMemberController extends BlockComponent<
  Props,
  S,
  SS
> {
  getMembersListCallId: string = "";
  postCreateMemberCallId: string = "";
  getMembersListSelectCallId: string = "";
  editTeamNameCallId: string = "";
  deleteRemoveMemberCallId: string = "";
  editMemberDetailCallId: string = "";
  getTeamListCallId:string="";
  postMoveMemberCallId:string="";
  postCreateTeamCallId:string="";
  getMembersListJobTitleSelectCallId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      messageToggle: true,
      tostifymessage: "",
      status: "",
      loading: false,
      isAddMemberModalOpen: false,
      inputName: "",
      selectedMembers: [],
      newValue: "",
      anchorEl: null,
      teamName: "",
      users: [],
      filteredUsers: [],
      selectedUsers: [],
      teamId: 0,
      memberError: "",
      rows: [],
      membersList: [],
      isRoleOpen: false,
      selectedRoles: [],
      isJobTitleOpen: false,
      selectedJobTitle: [],
      isApproveOpen: false,
      activated: [],
      isRenameModalOpen: false,
      selectedRow: null,
      searchList: [],
      isAddNot: false,
      selectedOrder: [],
      isDeleteMemberModalOpen: false,
      teamNameRenameError: false,
      teamRenameBlankError: false,
      teamRename: "",
      historyLocationState: "",
      memberFirstName: "",
      memberLastName: "",
      memberEmail: "",
      jobTitle: "",
      role: "owner",
      isEditDetailModalOpen: false,
      memberFirstNameError: false,
      memberLastNameError: false,
      memberEmailError: false,
      jobTitleError: false,
      emailFormatError: false,
      memberDetailError: false,
      isMoveMemberModalOpen:false,
      emailChanged: false,
      teamOptions:"",
      teamData:[],
      teamNameTitle:[],
      moveMemberError:"",
      isCreateTeamModalOpen:false,
      blankError:false,
      teamNameError:false,
      errorData:"",
      teamNameData:"",
      searchValue:"",
      jobTitles: [], 
      currentPage:1,
      rowsPerPage:10,
      dataRow:[],
      totalRecords:[],
      totalMembers:0,


    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.setState({
      historyLocationState: this.props.navigation?.getParam("title"),
    });
    const id = this.props.navigation?.getParam("id");
    const successData = await getStorageData("successData");

    if (this.state.historyLocationState) {
      this.setState({
        teamName: this.state.historyLocationState,
      });
    }
    if (id) {
      this.setState({
        teamId: id,
      });
    }
    if (successData) {
      this.setState(
        {
          messageToggle: true,
          tostifymessage:
            "You’ve created a new team, start adding members to it.",
          status: "success",
        },
        () => {
          removeStorageData("successData");
          this.closeMessageBox();
        }
      );
    } else if (successData == null) {
      this.setState({
        messageToggle: false,
      });
    }
    this.setState(
      {
        selectedOrder: ["a-z"],
      }, () =>{
        this.getMembersListSelectApi() 
      }
    );
  
    await this.getMembersListApi();
    await this.getMembersListSelectApi();
    this.getMembersListJobTitleSelectApi();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      switch (apiCallId) {
        case this.getMembersListCallId:
          this.handleMembersListResponse(responseJson);
          break;
  
        case this.postCreateMemberCallId:
          this.handleCreateMemberResponse(responseJson);
          break;
          case this.getMembersListSelectCallId:
           this.handleMemberSelectListResponse(responseJson)
            break;
          
          
        case this.deleteRemoveMemberCallId:
          this.handleRemoveMemberResponse(responseJson);
          break;
  
        case this.editTeamNameCallId:
          this.handleEditTeamResponse(responseJson);
          break;
  
        case this.editMemberDetailCallId:
          this.handleMemberUpdateResponse(responseJson);
          break;
  
        case this.getTeamListCallId:
          this.handleTeamListResponse(responseJson);
          break;
  
        case this.postMoveMemberCallId:
          this.handleMoveMemberResponse(responseJson);
          break;
        case this.getMembersListJobTitleSelectCallId:
          this.handleMemberListJobTitleResponse(responseJson)
          break;
        default:
          runEngine.debugLog("Unhandled API call", apiCallId);
          break;
      }
      if(apiCallId === this.postCreateTeamCallId){
        this.handleCreateTeamResponse(responseJson);
      }
    }
  }

  handleMemberSelectListResponse = (responseJson: any) => {
  
    const formattedData = responseJson.data
      .map((member: any, index: number) => ({
        id: `${index}`,
        ...member,     
      }))
      .filter((item: any) => item !== null); 

    this.setState({
      membersList: formattedData,  
      searchList: formattedData,   
      totalMembers: responseJson.pagy?.total_count || 0, 
      totalRecords: responseJson.pagy?.total_count || 0, 
      loading: false,            
    });
  
  };

  handleMemberListJobTitleResponse = (responseJson:any) =>{
    const jobTitles = Array.from(
      new Set(
        responseJson.data
          .map((member: any) => member.attributes?.job_title)
          .filter(Boolean) 
      )
    );
    this.setState({
      jobTitles
    })
  }
  
  async handleEditTeamResponse(responseJson: any) {
    if (responseJson.data) {
      this.props.navigation.navigate(
        "AddNewMember",
        { title: this.state.teamRename, id: this.state.teamId },
        { title: this.state.teamName, id: this.state.teamId }
      );
      this.setState({
        messageToggle: true,
        tostifymessage: "The team has been successfully renamed.",
        status: "success",
        historyLocationState: this.state.teamRename,
        teamName: this.state.teamRename,
      });

      setTimeout(() => {
        this.setState({ messageToggle: false, tostifymessage: "", status: "" });
      }, 3000);
      this.handleRenameModal();
      this.handleClose();
    } else if (responseJson.errors == "Team name can't be blank") {
      this.setState({ teamRenameBlankError: true, teamNameRenameError: false });
    } else if (responseJson.errors == "Team name already exists") {
      this.setState({ teamRenameBlankError: false, teamNameRenameError: true });
    }
  }

  filterAvailableUsers(
    users: Array<any>,
    selectedUsers: Array<any>,
    inputName: string
  ): Array<any> {
    return users.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      return (
        fullName.includes(inputName.toLowerCase()) &&
        !selectedUsers.some(
          (selectedUser) =>
            selectedUser.value.toLowerCase() === fullName
        )
      );
    });
  }
  
  getMembersListSelectApi = async (search = "",page = 1, limit = this.state.rowsPerPage) => {
    if (search != "") {
    }
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "Application/json",
      token: token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getMembersListSelectCallId = requestMsg.messageId;

    if (search != "") {
      this.setState({
        isAddNot: true,
      });
    }

    const roleFilterQuery = this.state.selectedRoles
      .map((role) => `&role[]=${role.toLowerCase()}`)
      .join("");

    const jobFilterQuery = this.state.selectedJobTitle
      .map((job) => `&job_title[]=${job.toLowerCase()}`)
      .join("");

      const activateFilterQuery = this.state.activated.length === 2
      ? ""
      : this.state.activated
          .map((activate) => {
            if (activate === "Activated") {
              return `&status=active`;
            } else if (activate === "Not Activated") {
              return `&status=inactive`;
            }
          })
          .join("");
    

    const sortFilterQuery = this.state.selectedOrder
      .map((sort) => `&sort=${sort.toLowerCase()}`)
      .join("");

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMembersListSelectEndPoint}/${this.state.teamId}/list_members?search=${search}${roleFilterQuery}${jobFilterQuery}${activateFilterQuery}${sortFilterQuery}&page_no=${page}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  getMembersListJobTitleSelectApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "Application/json",
      token: token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getMembersListJobTitleSelectCallId = requestMsg.messageId;

    const jobFilterQuery = this.state.selectedJobTitle
      .map((job) => `&job_title[]=${job.toLowerCase()}`)
      .join("");

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMembersListSelectEndPoint}/${this.state.teamId}/list_members?${jobFilterQuery}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  getMembersListApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "Application/json",
      token: token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getMembersListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetMembersListEndPoint}/list_all_members`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  handleMembersListResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        users: responseJson.members,
      });
    } else {
      this.setState({
        users: [],
      });
    }
  };

  closeMessageBox = () => {
    setTimeout(() => {
      this.setState({ messageToggle: false });
    }, 2000);
  };

  backBtn = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "TeamList");
    this.send(msg);
  };

  handleOpenModal = () => {
    this.getMembersListApi();
    this.setState({
      isAddMemberModalOpen: true,
      memberError:""
    });
  };

  handleAddMemberModal = () =>
    this.setState((prev) => ({
      isAddMemberModalOpen: !prev.isAddMemberModalOpen,
      memberError:"",
    }));

  handleMemberAdd = (user: any) => {
    this.setState((prevState) => ({
      selectedUsers: [
        ...prevState.selectedUsers,
        { id: user.id, value: `${user.first_name} ${user.last_name}`, 
        label: `${user.first_name} ${user.last_name}` },
      ],
    }));
  };

  filterUsers = (users: any, selectedUsers: any) => {
    return users
      .filter((user: { first_name: any; last_name: any }) => {
        const fullName = `${user.first_name} ${user.last_name}`;
        return !selectedUsers.some(
          (selectedUser: { value: any }) => selectedUser.value === fullName
        );
      })
      .map((user: { first_name: any; last_name: any }) => ({
        value: `${user.first_name} ${user.last_name}`,
        label: `${user.first_name} ${user.last_name}`,
      }));
  };
  

  handleInputChange = (inputValue: string) => {
    this.setState({ inputName: inputValue });
  };

  handleAddMemberData = async () => {
    const data = this.state.selectedUsers.map((obj: any) => parseInt(obj.id));
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };

    const body = {
      account_ids: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCreateMemberEndPoint}/${this.state.teamId}/add_members`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCreateMemberResponse = (responseJson: any) => {
    if (!responseJson.error) {
      if (responseJson.message == "No members added") {
      }
      this.setState({
        isAddMemberModalOpen: false,
        selectedMembers: [],
        selectedUsers: [],

        messageToggle: true,
        tostifymessage: "New members added to the team.",
        status: "success",
      });
      this.getMembersListSelectApi();
      setTimeout(() => {
        this.setState({ messageToggle: false });
      }, 2000);
    } else {
      if (responseJson.error == "No members selected for addition") {
        this.setState({
          memberError: responseJson.error,
        });
      } else if (responseJson.error == "Account must exist") {
        this.setState({
          memberError: "Account must exist",
        })
      }else if (responseJson.error =="User already exists in the member list"){
        this.setState({
          memberError: "User already exists in the member list",
        })
          }
        
    }
  };

  handleChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
  ) => {
    const { name, value } = event.target;
    const isClear = (value as string[]).includes("clear");
    if (name === "role") {
      this.setState(
        {
          selectedRoles: isClear ? [] : (value as string[]),
          isAddNot: true,
        },
        this.getMembersListSelectApi
      );
    } else if (name == "job") {
      this.setState(
        {
          selectedJobTitle:isClear ? [] : value as string[],
          isAddNot: true,
        },
        this.getMembersListSelectApi
      );
    } else if (name == "activated") {
      console.log(value)
      this.setState(
        {
          activated:isClear ? [] : value as string[],
          isAddNot: true,
        },
        this.getMembersListSelectApi
      );
    } else if (name === "sort") {
      this.setState(
        {
          selectedOrder: [value as string], 
          isAddNot: true,
        },
        this.getMembersListSelectApi
      );
    }
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, row: RowData) => {
    this.setState({ anchorEl: event.currentTarget, selectedRow: row });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedRow: null });
  };
  handleRenameModal = () => {
    this.setState((prev) => ({
      isRenameModalOpen: !prev.isRenameModalOpen,
      teamRename: prev.teamName,
      teamNameRenameError: false,
      teamRenameBlankError: false,
    }));
  };

  handleTeamNameChange = (newName: string) => {
    this.setState({ teamRename: newName });
  };

  editTeamNameApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const body = {
      data: {
        attributes: {
          team_name: this.state.teamRename,
        },
      },
    };

    this.editTeamNameCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamEndPoint}/${this.state.teamId}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  getRoleStyles(role: string) {
    let backgroundColor;
    switch (role) {
      case "owner":
        backgroundColor = "#237182";
        break;
      case "employee":
        backgroundColor = "#C5E0E7";
        break;
      default:
        backgroundColor = "#E0EDF0";
    }
    const color = role === "manager" ? "#237182" : "#fff";
    return { backgroundColor, color };
  }

  handleRemoveMember = () => {
    this.setState((prev) => ({
      isDeleteMemberModalOpen: !prev.isDeleteMemberModalOpen,
      anchorEl: null,
    }));
  };

  handleRemoveMemberApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteRemoveMemberCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeMemberEndPoint}/${this.state.teamId}/remove_member/${this.state.selectedRow?.id}?remove_member_from_team=true`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  handleRemoveMemberResponse = (responseJson:any) => {
    if (responseJson.message) {
      toast.success(`The team member has been successfully removed.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
      });
      this.setState({ isDeleteMemberModalOpen: false });
      this.getMembersListSelectApi();
    }
  };
  

  handleSubmit = async () => {
    let isValid = true;

    if (!this.state.memberFirstName) {
      this.setState({ memberFirstNameError: true });
      isValid = false;
    } else {
      this.setState({ memberFirstNameError: false });
    }

    if (!this.state.memberLastName) {
      this.setState({ memberLastNameError: true });
      isValid = false;
    } else {
      this.setState({ memberLastNameError: false });
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!this.state.memberEmail) {
      this.setState({ memberEmailError: true });
      isValid = false;
    } else if (!emailRegex.test(this.state.memberEmail.trim())) {
      this.setState({ emailFormatError: true });
      isValid = false;
    } else {
      this.setState({ memberEmailError: false, emailFormatError: false });
    }

    if (!this.state.jobTitle) {
      this.setState({ jobTitleError: true });
      isValid = false;
    } else {
      this.setState({ jobTitleError: false });
    }

    if (isValid) {
      const token = await getStorageData("token");
      const header = {"Content-Type": configJSON.ContentType,
        token: token,};

      const body = {
        membership: {  first_name: this.state.memberFirstName?.trim(),
          last_name: this.state.memberLastName?.trim(),
 email: this.state.memberEmail?.trim().toLowerCase(),
          job_title: this.state.jobTitle?.trim(),
          role_id: this.state.role?.trim(),
        }, };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editMemberDetailCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.editMemberDetailsEndPoint}/update_members?member_id=${this.state.selectedRow?.id}`);


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header) );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

      this.setState({
        emailChanged:
          this.state.memberEmail?.trim() !==
          this.state.selectedRow?.attributes.email,
      });
    }
  };

  handleMemberUpdateResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      if (this.state.emailChanged) {
        toast.success(
          `The member's email has been updated. They need to verify their new email address.`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            theme: "light",
          }
        );
      } else {
        toast.success(`Member details have been successfully edited.`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          closeButton: false,
          theme: "light",
        });
      }

      this.getMembersListSelectApi();
      this.setState({
        isEditDetailModalOpen: false,
      });
    }
  };

  ternarHandler = (condition: boolean, option1: any, option2: any) =>
    condition ? option1 : option2;

  handleRoleClick = (value: string) => {
    this.setState({ role: value });
  };

  handleEditDetailModal = () => {
    this.setState({
      anchorEl: null,
      isEditDetailModalOpen: !this.state.isEditDetailModalOpen,
      memberFirstName: this.state.selectedRow?.attributes.first_name,
      memberLastName: this.state.selectedRow?.attributes.last_name,
      memberEmail: this.state.selectedRow?.attributes.email,
      jobTitle: this.state.selectedRow?.attributes.job_title,
      role: this.state.selectedRow?.attributes.role_id,
      jobTitleError:false,
      memberEmailError:false,
      emailFormatError:false,
      memberLastNameError:false,
      memberFirstNameError:false
    });
  };

  handleInputDataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name as keyof typeof prevState]: value,
    }));
  };



  handleMoveMemberToOtherTeam = () => {
    this.setState((prev) => ({
      isMoveMemberModalOpen: !prev.isMoveMemberModalOpen,
      anchorEl: null,
      teamNameTitle:null,
      moveMemberError:"",
    }));
  };

  fetchTeamsApi = async() =>{
      const token = await getStorageData("token");
      const header = {
        "Content-Type": "Application/json",  token: token,
      };
  
      const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );
  
      this.getTeamListCallId = requestMsg.messageId;
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),   `${configJSON.GetTeamListEndPoint}`
      );
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),    JSON.stringify(header));
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),   configJSON.validationApiMethodType);
  
  runEngine.sendMessage(requestMsg.id, requestMsg);
  
      return true; 
  }
  handleTeamListResponse=(responseJson:any)=>{
    const teamData: TeamData[] = responseJson.data.map((item: any) => ({
      id: item?.id,
      teamName: item?.attributes.team_name
    }));
  
  this.setState({ teamData });
 
  }

  handleCreateTeamCancelButton = () =>{
    this.setState((prev) => ({
      isCreateTeamModalOpen: !prev.isCreateTeamModalOpen,
      teamNameData: "",
      teamNameError: false,
      blankError:false,
      errorData:"",
      isMoveMemberModalOpen:true
    }));
  }

  handleCreateTeamSubmit = async() =>{
    const token = await getStorageData("token");

    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };
   
    const body = {
      data:{
        attributes:{
          "account_id":this.state.selectedRow?.id,
          "new_team_name": this.state.teamNameData
        }
      }
    }
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateTeamCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.postMoveMemberEndPoint}/${this.state.teamId}/move_member`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCreateTeamResponse = async (responseJson:any) => {
  
    if (responseJson.errors && responseJson.errors.length > 0) {
      const errorMessage = responseJson.errors[0];
  
      if (errorMessage === "Team name already exists") {
        this.setState({
          errorData: "Team name already exists",
        });
      } else if (errorMessage === "Team name can't be blank") {
        this.setState({
          errorData: "Team name can't be blank",
        });
      } else {
        this.setState({
          errorData: errorMessage, 
        });
      }
    } else if (responseJson.error === "New team not found") {
      this.setState({
        errorData: "Team cannot be blank.",
      });
    } else if (responseJson.message) {
      toast.success(`The user has been successfully moved to another team.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
      this.setState({
        isCreateTeamModalOpen: false,
        isMoveMemberModalOpen: false,
      });
      this.getMembersListSelectApi();
      setTimeout(()=>{
        this.props.navigation.navigate(
          "AddNewMember",
          { title: responseJson.data.team_name, id: responseJson.data.id },
          { title: responseJson.data.team_name, id: responseJson.data.id }
        );
        window.location.reload()
      },1000)
    }
  };






  handleMoveMemberSubmit=async()=>{
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };

    const body = {
      data:{
        attributes:{
          "account_id":this.state.selectedRow?.id,
          "new_team_id": this.state.teamNameTitle?.value
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postMoveMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postMoveMemberEndPoint}/${this.state.teamId}/move_member`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleMoveMemberResponse = (responseJson:any) => {
  if (responseJson.message) {
    toast.success(`The user has been successfully moved to another team.`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
    });
    this.setState({
      isMoveMemberModalOpen: false,
    });
  setTimeout(()=>{
    this.props.navigation.navigate(
      "AddNewMember",
      { title: responseJson.data.team_name, id: responseJson.data.id },
      { title: responseJson.data.team_name, id: responseJson.data.id }
    );
    window.location.reload()
  },1000)
    this.getMembersListSelectApi();
  } else if (responseJson.error) {
    this.setState({ moveMemberError: responseJson.error });
  } };

  
  

    handleCreateTeamName = (teamNameData: string) => {
      this.setState({ teamNameData, teamNameError: false });
    };

    handleCreateTeamModal = () =>
      this.setState((prev) => ({
        isCreateTeamModalOpen: !prev.isCreateTeamModalOpen,
        isMoveMemberModalOpen:false,
        teamNameData:"",
        teamNameError:false,
        blankError:false,
        errorData:""
      }));

      clearSearch = () => {
        this.setState({ searchValue: "" },()=>{
          this.getMembersListSelectApi()
        });
      };

      setPage = (page:any) => {
        this.setState({ currentPage: page }, () => {
          this.fetchTeamsData(page);
        });
      };
      


      fetchTeamsData = (page: number) => {
        const {  rowsPerPage } = this.state;
        this.getMembersListSelectApi("", page, rowsPerPage); 
      };
      
      
      handleNextPage = () => {
        const { currentPage, rowsPerPage, totalRecords } = this.state;
        const totalPages = Math.ceil(totalRecords / rowsPerPage);
      
        if (currentPage < totalPages) {
          const nextPage = currentPage + 1;
          this.setState({ currentPage: nextPage }, () => {
            this.fetchTeamsData(nextPage);  
          });
        }
      };
      
      
      
      handlePreviousPage = () => {
        const { currentPage } = this.state;
      
        if (currentPage > 1) {
          const previousPage = currentPage - 1;
          this.setState({ currentPage: previousPage }, () => {
            this.fetchTeamsData(previousPage); 
          });
        }
      };

  
  
}

// Customizable Area End
