import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface ProjectPlan {
  id: string;
  type: string;
  attributes: ProjectPlanAttributes;
}

interface ProjectPlanAttributes {
  title: string;
  description: string; 
  price: number | null;
  logo: string | null; 
}

interface ProjectPlansResponse {
  data: ProjectPlan[]; 
}
// Customizable Area End

interface S {
  // Customizable Area Start
  selectedPlan: number;
  projectPlanList : ProjectPlan[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class ProjectPlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProjectPlanListCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedPlan: 0,
      projectPlanList : []
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
        }
      }
    }
    this.receive1(message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getProjectPlanList();
  }
  handleBackClick = () => this.props.navigation.goBack();

  handlePlanClick = (id: number) => this.setState({ selectedPlan: id });


  getProjectPlanList=()=>{
    const header = {
      "Content-Type": configJSON.getProjectPlanListApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectPlanListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectPlanListAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProjectPlanListAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receive1=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getProjectPlanListCallId) {
        this.ProjectPlanListResponse(responseJson);
      }
    }

  }

  ProjectPlanListResponse = (responseJson:ProjectPlansResponse) =>{
    this.setState({projectPlanList:responseJson.data});
  }

  handleCreateProjectNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CreateEditProjectPortfolio"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.selectedPlan);
    this.send(message);
  };
  // Customizable Area End
}
