Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.roomCostBreakdown = "Room Cost Breakdown ";
exports.currency = '£'
exports.totalCost= "Total Cost:"
exports.viewMoreBtn = 'View more'
exports.data = [
  { name: "Room Name", value: 400 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 200 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 300 },
];
exports.COLORS = ["#60A5FA", "#F87171", "#FCD34D", "#FF8042", '#F472B6', '#ABCDD5', '#818CF8', '#34D399'];

// Customizable Area End