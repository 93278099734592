import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
// Customizable Area End
export interface Props2 {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchInputText:string
  projectList:any[]
  projectId:number
  external:boolean
  internal:boolean
  dummyArray:any[],
  anchorElTSpeci:any,
  isAddMemberModalOpen:boolean
  isOpenRightSection:boolean
  selectedUsers: any;
  inputName: string;
  users: any;
  active:string,




  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController2 extends BlockComponent<Props2, S, SS> {
  // Customizable Area Start
  getProjectListApiCallId:string = '';
  getMembersListCallId: string = "";
  postCreateMemberCallId: string="";

  
  // Customizable Area End

  constructor(props: Props2) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      searchInputText:'',
      projectList:[],
      projectId:0,
      external:false,
      internal:false,
      dummyArray:[1,2,3,4,5],
      anchorElTSpeci:null,
      isAddMemberModalOpen: false,
      isOpenRightSection:false,
      selectedUsers: [],
      inputName: "",
      users: [],
      active:"member"




      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
   this.getProjectList()
   this.getMembersListApi()
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
    }
    if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    }
    console.log("res123====",responseJson);
    
    if (this.getProjectListApiCallId === apiRequestCallId) {
        if(!responseJson.errors) {
          this.handleProjectListResponse(responseJson)
        }
    
  



    }
    if (this.getMembersListCallId === apiRequestCallId) {
     console.log("= list wwwwwwwww==>", responseJson)
     this.handleMembersListResponse(responseJson);

    }
    if (this.postCreateMemberCallId === apiRequestCallId) {
      console.log("log123===createmamber", responseJson)
 
     }

  }
  handleMembersListResponse = (responseJson: any) => {
    console.log("member+++++++>",responseJson)
    if (!responseJson.errors) {
      this.setState({
        users: responseJson.members,
      });
    } else {
      this.setState({
        users: [],
      });
    }
  };
  handleSearchInputChange = (search: string) => {
         this.setState({searchInputText:search})
  };

  getProjectList = async () => {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleProjectListResponse = (res:any)=>{
    if(!res.data) {
      this.setState({projectList: []})
    }else
       this.setState({projectList:res.data})
  }

  handleProjectId = (projectId:number)=>{
    if(this.state.projectId === projectId){
      this.setState({projectId:0})
    }else{
      this.setState({projectId:projectId})
    }
  }


  handleSpeciChange = (event: any)=>{
    this.setState({anchorElTSpeci:event.currentTarget})
  }
  openRightSection=()=>{
this.setState({isOpenRightSection:true})
  }
  cancle=()=>{
    this.setState({isOpenRightSection:false})
  }

  handleClose3 = ()=>{
    this.setState({anchorElTSpeci:null})
  }
  OpenADDmemberModel=()=>{
    this.setState({isAddMemberModalOpen:true})
    this.getMembersListApi()
  }
  handleAddMemberModal = () =>
    this.setState((prev) => ({
      isAddMemberModalOpen: !prev.isAddMemberModalOpen,
      
    }));
 
  getMembersListApi = async () => {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMembersListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/teams/list_all_members`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  filterUsers = (users: any, selectedUsers: any) => {
    return users
      .filter(
        (user: { user_name: any }) =>
          !selectedUsers.some(
            (selectedUser: { value: any }) =>
              selectedUser.value === user.user_name
          )
      )
      .map((user: { user_name: any }) => ({
        value: user.user_name,
        label: user.user_name,
      }));
  };
  handleInputChange = (inputValue:any) => {
    this.setState({ inputName: inputValue });
  };
  handleMemberAdd = (user: any) => {    
    this.setState((prevState) => ({
      selectedUsers: [
        ...prevState.selectedUsers,
        { id: user.id, value: user.first_name, label: user.first_name },
      ],
    }));
    
  };
  filterAvailableUsers(
    users: Array<any>,
    selectedUsers: Array<any>,
    inputName: string
  ): Array<any> {
    return users?.filter(
      (user) =>
        user?.first_name?.toLowerCase().includes(inputName.toLowerCase()) &&
        !selectedUsers.some(
          (selectedUser) => selectedUser.value === user?.first_name
        )
        
    );
  }
  handleAddMemberData = async () => {
    const data = this.state.selectedUsers.map((obj: any) => parseInt(obj.id));    
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };
    const body=  {
      "chat_membership": {
          "chat_channel_id": 19,
          "account_id": data
      }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postCreateMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     ` bx_block_chat/chat_memberships`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
   handleClick = (item: any) => {
    this.setState({active:item})
  };
  // Customizable Area End
}
