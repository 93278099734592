// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
const { NavigationActions, StackActions } = require("react-navigation");

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface FormValues {
  projectName: string;
  clientDetails: string;
  teamSize: string;
  projectUrl: string;
  developerEmail: string;
  developerFullName: string;
  firstName: string;
  projectDescription: string;
  lastName: string;
  postCode: string;
  city: string;
  email: string;
  streetName: string;
  phoneNumber: string;
  startDate: Date | null;
  endDate: Date | null;
  country: string;
  state: string;
  countryCode: string;
  draft:boolean,
  
}
interface PortfolioProjects {
  id: string;
  start_date:string;
  title: string;
  location: string;
  draft: boolean;
  status:string;
  cost: string; 
  files_uploaded: boolean;
  description?: string;
}

interface S {
  isModalVisible: boolean;
  filterValue: string;
  isDeleteModalOpen: boolean;
  token: string;
  searchInputText: string;
  deleteProjectID: string;
  isFilterMenuOpen: Element | null;
  isDialogOpen: boolean;
  loading: boolean;
  pageNumber: number | null;
  anchorEl: any;
  cost: string;
  title: string;
  open: false;
  location: string;
  projects: ({
    start_date: string | number | Date;
    startDate: string | number | Date;
    id: string;
    title: string;
    location: string;
    draft: boolean;
    status: string;
    cost: string;
    files_uploaded: boolean;
  } | PortfolioProjects)[];
  activeTab: number;
  specialistCostPlan: boolean;
  sortType: { label: string; value: string }[];
  sortTypeValue: string;
  formValues: FormValues;
  isEditing: { [key: string]: boolean };
  tempName: { [key: string]: string };
  errors: { [key: string]: string | null };
  selectedProjectId:string | null;
  project_id :string;
  portfolioProjects : PortfolioProjects[];
  openDialogLeave:boolean,
  openDialog:boolean,
  path: string;
  handleDuplicateProject: (() => void) | null;
  userRole:string,
  searchText: string,
}

interface SS {
  id: any;
}

export default class ProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetProjectsListCallId: string | null = "";
  apiDeleteProjectsListCallId: string | null = "";
  apiDeleteProjectByIdCallId: string | null = "";
  apiRenameProjectByIdCallId: string | null = "";
  apiDuplicateProjectByIdCallId: string | null = "";
  apiGetProjectByIdCallId: string | null = "";
  apiGetProfileCallId: string | null = "";

  constructor(props: Props) {
    
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isModalVisible: false,
      filterValue: "All Projects",
      isDeleteModalOpen: false,
      token: "",
      searchInputText: "",
      deleteProjectID: "",
      isFilterMenuOpen: null,
      isDialogOpen: false,
      loading: false,
      pageNumber: 1,
      anchorEl: null,
      cost: "",
      title: "",
      open: false,
      location: "",
      activeTab: 0,
      projects: [],
      specialistCostPlan: false,
      sortType: [
        { label: "Recent", value: "date_desc" },
        { label: "Earliest", value: "date_asc" },
        { label: "Cost", value: "cost" },
      ],
      sortTypeValue: "date_desc",
      formValues: {
        projectName: "",
        draft:false,
        clientDetails: "",
        teamSize: "",
        projectUrl: "",
        startDate: null,
        endDate: null,
        developerEmail: "",
        developerFullName: "",
        firstName: "",
        projectDescription: "",
        lastName: "",
        postCode: "",
        city: "",
        email: "",
        streetName: "",
        phoneNumber: "",
        country: "",
        state: "",
        countryCode: "+44",
      },
      isEditing: {},
      tempName: {},
      errors: {},
      selectedProjectId :null,
      project_id : "",
      portfolioProjects: [] as PortfolioProjects[],
      openDialogLeave:false, 
      openDialog:false,
      path: '',
      handleDuplicateProject:null,
      userRole:'',
      searchText: '',
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
resolveGetProjectDetails: ((value?: unknown) => void) | null = null;




  handleCreateNewProject=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProjectPlan");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message); 

  }
  handleSortByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    this.setState({ sortTypeValue: newValue }, () => {});
};

  handleToggleChange = () => {
    this.setState((prevState) => ({
      specialistCostPlan: !prevState.specialistCostPlan,
    }));
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ activeTab: newValue });
  };

  handleClick = (project_id:any,event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiGetProjectsListCallId: {
          this.getProjectListHandleResponse(responseJson);
          break;
        }
        case this.apiGetProfileCallId:{
          this.getProfileHandleResponse(responseJson)          
        }
      }
    }
    this.receive1(message);
    this.receive2(message);
    this.receive3(message);
  }
  receive1=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiDeleteProjectByIdCallId) {
          this.getProjectDeleteByIdHandleResponse();
        }
      }
    }
  }
  receive2=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiDuplicateProjectByIdCallId) {
          this.getProjectDuplicateHandleResponse(responseJson);
        }
      }
    }
  }
  receive3=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGetProjectByIdCallId) {
          this.getProjectByIdHandleResponse(responseJson);
          if (this.resolveGetProjectDetails) {
            this.resolveGetProjectDetails(); 
            this.resolveGetProjectDetails = null;
        }
        }
      }
    }
  }

  async componentDidMount() {
    const token = await getStorageData("token");
    this.setState({ token });
    this.getProjectsListData();
    document.addEventListener('mousedown', this.handleClickOutside);
    this.getProfile();
  }


async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
}

handleClickOutside = (event: MouseEvent) => {
  if (
      this.state.selectedProjectId &&
      !(event.target as HTMLElement).closest('.menu') &&
      !(event.target as HTMLElement).closest('.rename-input')
  ) {
      this.setState({ selectedProjectId: null });
  }
};


  getProjectListHandleResponse = (responseJson: any) => {
    const projects = responseJson.data.map((project: any) => ({
      id: project.id,
      start_date: project.attributes.start_date,
      title: project.attributes.project_name,
      location: project.attributes.addressable?.city || '',
      draft :  project.attributes.draft,
      status: project.attributes.status,
      cost: project.attributes.cost || "N/A",
      files_uploaded : project.attributes.files_uploaded,
      created_at : project.attributes.addressable?.created_at,
    }));
    this.setState({ projects, loading: false });
  };

  getProjectsListData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllProjectsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearch(text: string) {
    this.getProjectsListData();
    this.setState({ searchInputText: text });
  }

  changeApi(type: string) {
    this.setState(
      {
        filterValue: type,
        isModalVisible: false,
        projects: [],
        searchInputText: "",
        pageNumber: 1,
      },
      () => {
        this.getProjectsListData();
      }
    );
  }

  goBack() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  openFilterMenu() {
    this.setState({ isModalVisible: true });
  }

  deleteModal(projectID: string) {
    this.setState({ isDeleteModalOpen: true, deleteProjectID: projectID });
  }

  closeDeleteModal() {
    this.setState({ isDeleteModalOpen: false });
  }

  filterModal() {
    this.setState({ isModalVisible: false });
  }

  naviagteToCreatePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectPlan"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  naviagteToEditPage = (projectId: string) => {
    const message: Message = new Message(
      "Dummy text because Enum does not exist"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData("Dummy text because Enum does not exist", projectId);
    this.send(message);
  };

  naviagteToViewPage = (projectId: string) => {
    const message: Message = new Message(
      "Dummy text because Enum does not exist"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData("Dummy text because Enum does not exist", projectId);
    this.send(message);
  };
  loadMoreData = () => {
    if (this.state.pageNumber !== null) {
      this.setState({
        pageNumber: Number(this.state.pageNumber) + 1,
      });
    }
  };

  getProfile = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProfileHandleResponse = (responseJson: any) => {
        this.setState({userRole:responseJson.data.attributes.role_id})
  };

  logOutFunction = () => {
    removeStorageData("token");
    this.props.navigation.dispatch(
      StackActions.reset({
        index: 0,
        actions: [
          NavigationActions.navigate({
            routeName: "EmailAccountLoginScreen",
          }),
        ],
      })
    );
  };
  handleOpenDialog=()=>{
    this.setState({openDialog:true})
    }
    handleOpenDialogLeave = (path: string) => {
      const { projectName, projectDescription } = this.state.formValues; 
      if (!projectName || !projectDescription) {
          this.props.navigation.navigate(path);
      } else {    
          this.setState({ 
              path, 
              openDialogLeave: true 
          });
      }
  };
  
  
  
    handleCloseDialog=()=>{
      this.setState({openDialog:false,openDialogLeave:false,selectedProjectId:null})
  
    }

    openDuplicateDialog = (project_id:any) => {
      this.setState({
        openDialogLeave: true,
        handleDuplicateProject: () => this.handleDuplicateProject(project_id) 
      });
     
    }
  handleOpenDraft = (project_id:any,draftStat:any,fileUploadStat:any) =>{
    if(draftStat && !fileUploadStat){
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CreateEditProjectPortfolio"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `3/project_id/${project_id}`);
    this.send(message);
  }else if(draftStat && fileUploadStat){

      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "ProjectCostPlanUpload"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), `3/project_id/${project_id}`);
      this.send(message);

  }
  
  }

  handleClose = () => {
    this.setState({ anchorEl: null});
  };
  toggleProjectMenu = (projectId: string) => {
    this.setState(prevState => ({
        selectedProjectId: prevState.selectedProjectId === projectId ? null : projectId
    }));
};
  handleRenameProject = (projectId: string) => {
    this.handleClose();
    const project = this.state.projects.find(p => p.id === projectId);
    if (project) {
        this.setState(prevState => ({
            isEditing: {
                ...prevState.isEditing,
                [projectId]: true,
            },
            tempName: {
                ...prevState.tempName,
                [projectId]: project.title,
            },
            errors: {
                ...prevState.errors,
                [projectId]: null,
            }
        }));
    }
   
};

handleInputChange = (projectId: string, event: React.ChangeEvent<HTMLInputElement>) => {
  const newValue = event.target.value;
  this.setState(prevState => ({
      tempName: { ...prevState.tempName, [projectId]: newValue },
      errors: { 
          ...prevState.errors, 
          [projectId]: newValue.trim() === '' ? 'Project name cannot be empty' : null 
      }
  }));
};

handleKeyDown = (projectId: string, event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === "Enter") {
      event.preventDefault(); 
      this.saveProjectName(projectId);
  }
};



handleBlur = (projectId: string) => {
    const name = this.state.tempName[projectId] || '';
    if (name.trim() === '') {
        this.setState(prevState => ({
            errors: { ...prevState.errors, [projectId]: 'Project name cannot be empty' }
        }));
    } else {
        this.saveProjectName(projectId);
    }
};

saveProjectName = (projectId: string) => {
    const newName = this.state.tempName[projectId];
    this.setState(prevState => ({
        projects: prevState.projects.map(project =>
            project.id === projectId ? { ...project, title: newName } : project
        ),
        isEditing: {
            ...prevState.isEditing,
            [projectId]: false,
        },
        errors: { ...prevState.errors, [projectId]: null }
    }), () => {
        localStorage.setItem('projects', JSON.stringify(this.state.projects)); 
      

        const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const body = {
      project: {
        project_name: newName
      }
     };  

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRenameProjectByIdCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.renameProjectByIdAPIEndPoint}/${projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.renameAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    });
};



  

getProjectDetailsById=(project_id:any)=>{

  return new Promise((resolve) => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectByIdCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getProjectByIdAPIEndPoint}/${project_id}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.resolveGetProjectDetails = resolve;
});

}
getProjectByIdHandleResponse=(responseJson:any) =>{
 
  this.setState({formValues:{
    projectName: responseJson.data.attributes.project_name,
    clientDetails: "",
    teamSize: "",
    projectUrl: "",
    developerEmail: "",
    developerFullName: "",
    firstName: responseJson.data.attributes.addressable.name,
    projectDescription: responseJson.data.attributes.description,
    lastName: responseJson.data.attributes.addressable.last_name,
    postCode: responseJson.data.attributes.addressable.post_code,
    city: responseJson.data.attributes.addressable.city,
    email: responseJson.data.attributes.addressable.email,
    streetName: responseJson.data.attributes.addressable.address,
    phoneNumber: responseJson.data.attributes.addressable.phone,
    startDate: responseJson.data.attributes.start_date,
    endDate: responseJson.data.attributes.end_date,
    country: responseJson.data.attributes.addressable.country,
    state: responseJson.data.attributes.addressable.state,
    countryCode: responseJson.data.attributes.addressable.country,
    draft: responseJson.data.attributes.draft,
   
  }})


}

getPlanName = (planId: string | number | null) => {
  switch (planId) {
    case "1":
      return "automated_cost_plan";
    case "2":
      return 'manual_cost_plan';
    case "3":
      return "specialist_cost_plan";
    default:
      break;
  }
}
 
  handleDuplicateProject=async(project_id:any)=>{
    this.handleClose();
    this.handleCloseDialog();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDuplicateProjectByIdCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.duplicateProjectByIdAPIEndPoint}/${project_id}/duplicate`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.duplicateAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }
  handleDeleteProject=(project_id:any)=>{
    this.handleClose();
    this.setState({project_id:project_id})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteProjectByIdCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteProjectByIdAPIEndPoint}/${project_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  getProjectDeleteByIdHandleResponse = () => {
    const project_id = this.state.project_id;
    const updatedProjects = this.state.projects.filter(
      project => project.id.toString() !== project_id.toString()
    );
    this.setState({ projects: updatedProjects }, () => {  
    });
  }
  
  getProjectDuplicateHandleResponse=(responseJson:any) =>{
    const newProject: PortfolioProjects = {
        id: responseJson.data.id,
        start_date : responseJson.data.attributes.start_date,
        title: responseJson.data.attributes.project_name,
        location: responseJson.data.attributes.addressable?.city || "",
        draft: responseJson.data.attributes.draft,
        status : responseJson.data.attributes.status,
        cost: responseJson.data.attributes.cost || "N/A", 
        files_uploaded: false,
        description: responseJson.data.attributes.description || "",
    };

  
    this.setState(prevState => {
        const updatedProjects = prevState.projects.filter(
            project => project.id !== newProject.id
        );

       
        return { projects: [...updatedProjects, newProject] };
    });

  }
  

}
// Customizable Area End
