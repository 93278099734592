// Customizable Area Start
export const filterIcon = require("../assets/filter.svg");
export const backIcon = require("../assets/backIcon.svg");
export const mockIcon = require("../assets/mock_icon.png");
export const file_upload = require("../assets/file_upload.png");
export const buildNewIcon = require("../assets/build_new_icon.png");
export const extendIcon = require("../assets/extend_icon.png");
export const remodelIcon = require("../assets/remodel_icon.png");
export const refurbishIcon = require("../assets/refurbish_icon.png");
export const addMember = require("../assets/add-member.png");
export const addProjectFrame = require("../assets/Frame.svg");
export const DraftIcon = require("../assets/DraftIcon.svg");
export const ProposedIcon = require("../assets/ProposedIcon.svg");
export const OngoingIcon = require("../assets/OngoingIcon.svg");
export const PausedIcon = require("../assets/PausedIcon.svg");
export const CompletedIcon = require("../assets/CompletedIcon.svg");
export const bedImage = require("../assets/image_bed.png")
export const roundPlusIcon = require("../assets/roundplusicon.png")
export const threeDotIcon = require("../assets/button_icon.png")
export const downArrowIcon = require("../assets/downArrow.png")
export const dollarIcon = require("../assets/dollar.png")
export const  upArrowIcon = require("../assets/upArrow.png")
export const  rightArrowIcon = require("../assets/rightArrow.png")
export const  calendarIcon = require("../assets/calendar.png")
export const  blackDownArrowIcon = require("../assets/blackDownArrow.png")
export const  frameIcon = require("../assets/Frame.png")



// Customizable Area End
