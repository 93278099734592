// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";

//API CONSTANTS
exports.getAllProjectsAPIEndPoint = "bx_block_profile/projects";
exports.getMyProjectsAPIEndPoint = "bx_block_projectportfolio/my_project";
exports.createProjectEndPoint = "bx_block_profile/projects";
exports.viewProjectsAPIEndPoint = "bx_block_projectportfolio/project_portfolios/";
exports.deleteProjectAPIEndPoint = "bx_block_projectportfolio/project_portfolios/";
exports.uploadMediaPresignUrlAPIEndPoint = "upload_media";
exports.bulkUploadAPIEndPoint = "bx_block_upload_media/bulk_upload";
exports.deleteUploadMediaAPIEndPoint = "upload_media/"
exports.getSpecialistCostPlanListAPiEndPoint = "bx_block_profile/sections"

exports.postAPiEndMethod = "POST"
exports.getAPiEndMethod = "GET"
exports.putAPiEndMethod = "PUT"
exports.patchAPiEndMethod = "PATCH"
exports.deleteAPiEndMethod = "DELETE"
exports.getProjectPlanListApiContentType = "application/json";
exports.getProjectPlanListAPiEndPoint = "bx_block_custom_forms/project_plans";
exports.getProjectPlanListAPiEndMethod = "GET"
exports.deleteProjectUploadFileAPiEndPoint = "bx_block_profile/projects";
exports.deleteProjectUploadFileAPiEndMethod = "DELETE";
exports.uploadProjectFileAPiEndPoint = "bx_block_profile/projects/upload_files";
exports.uploadProjectFileAPiEndMethod = "POST";
exports.stripeAPiEndPoint = "bx_block_profile/projects";
exports.stripeAPiEndMethod = "POST";
exports.getProjectByIdAPIEndPoint = "bx_block_profile/projects";
exports.deleteProjectByIdAPIEndPoint = "bx_block_profile/projects";
exports.deleteAPiEndMethod = "DELETE";
exports.duplicateProjectByIdAPIEndPoint = "bx_block_profile/projects";
exports.duplicateAPiEndMethod = "POST";
exports.renameProjectByIdAPIEndPoint = "bx_block_profile/projects";
exports.renameAPiEndMethod = "PATCH";

//API CONSTANTS

//Labels
exports.labelTitleText = "Project Portfolio";
exports.labelAllProjectText = "All Project";
exports.labelMyProjectText = "My Project";
exports.labelProjectNameText = "Project Name";
exports.labelStartDateNameText = "Start Date";
exports.labelEndDateNameText = "End Date";
exports.labelEditBtnText = "Edit";
exports.labelDeleteBtnText = "Delete";
exports.labelConfirmBtnText = "Yes";
exports.labelRejectBtnBtnText = "No";
exports.labelSubmitBtnBtnText = "Submit";
exports.labelAddProjectText = "Add Project";
exports.labelEditProjectText = "Edit Project";
exports.labelViewProjectText = "View Project Details";
exports.labelSearchInputText = "Add Project";
exports.labelsCreateFormFields = {
  projectName: { label: "Project Name", placeHolder: "Enter Project Name" },
  projectDescription: { label: "Project Description", placeHolder: "Enter Project Description" },
  clientDetails: { label: "Client Details", placeHolder: "Enter Client Details" },
  teamSize: { label: "Team Size", placeHolder: "Enter Team Size" },
  projectUrl: { label: "Project URL", placeHolder: "Enter Team Size" },
  developerEmail: { label: "Email", placeHolder: "Enter Email" },
  developerFullName: { label: "Full Name", placeHolder: "Enter Full Name" },
  phoneNumber: { label: "Phone Number", placeHolder: "Enter Phone Number" },
  startDate: { label: "Start Date", placeHolder: "Enter Start Date" },
  endDate: { label: "End Date", placeHolder: "Enter End Date" },
  projectImages: { label: "Project Images", placeHolder: "" },
  projectDocuments: { label: "Project Documents", placeHolder: "" },
}

exports.deleteConfirmDialogTitle = "Delete Project Confirmation";
exports.deleteConfirmDialogDetailText = "Are you sure you want to delete this project?";
exports.getProfileEndPoint = 'account_block/accounts/profile'
//Labels
exports.countryCodesArray = [
  { code: 'CA', name: 'Canada', dial_code: '+1' },
  { code: 'GB', name: 'United Kingdom', dial_code: '+44' },
  { code: 'AU', name: 'Australia', dial_code: '+61' },
  { code: 'DE', name: 'Germany', dial_code: '+49' },
  { code: 'FR', name: 'France', dial_code: '+33' },
  { code: 'IT', name: 'Italy', dial_code: '+39' },
  { code: 'JP', name: 'Japan', dial_code: '+81' },
  { code: 'BR', name: 'Brazil', dial_code: '+55' },
  { code: 'IN', name: 'India', dial_code: '+91' },
  { code: 'CN', name: 'China', dial_code: '+86' },
  { code: 'MX', name: 'Mexico', dial_code: '+52' },
  { code: 'ZA', name: 'South Africa', dial_code: '+27' },
  { code: 'AR', name: 'Argentina', dial_code: '+54' },
  { code: 'RU', name: 'Russia', dial_code: '+7' },
  { code: 'ES', name: 'Spain', dial_code: '+34' },
  { code: 'KR', name: 'South Korea', dial_code: '+82' },
  { code: 'TR', name: 'Turkey', dial_code: '+90' },
  { code: 'EG', name: 'Egypt', dial_code: '+20' },
  { code: 'NG', name: 'Nigeria', dial_code: '+234' },
  { code: 'SA', name: 'Saudi Arabia', dial_code: '+966' },
  { code: 'ID', name: 'Indonesia', dial_code: '+62' },
  { code: 'PK', name: 'Pakistan', dial_code: '+92' },
  { code: 'BD', name: 'Bangladesh', dial_code: '+880' },
  { code: 'TH', name: 'Thailand', dial_code: '+66' },
  { code: 'VN', name: 'Vietnam', dial_code: '+84' },
];

exports.projectTypeEndPoint = 'bx_block_profile/property_types'
exports.projectScopesCategoriesEndPoint = 'bx_block_categories/scope_categories'

// Customizable Area End
