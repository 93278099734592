export const insertPhoto = require("../assets/insert_photo.png");
export const chatNotFoundImg = require("../assets/chatempty.png");
export const chatNotFoundTxt = require("../assets/chattext.png");
export const chatGroupNotFoundImg = require("../assets/chatNotFound.png");
export const chatGroupNotFoundTxt = require("../assets/chatGroupTxt.png");
export const threeDotIcon = require("../assets/button_icon.png")
export const lockIcon = require("../assets/image_lock_outline_24px.png")
export const extIcon = require("../assets/chipbasic_small.png")
export const SearchIcon1 = require("../assets/Search.png")
export const pin = require("../assets/pin.png")
export const channalname_info_icon = require("../assets/channalname_info_icon.png")
export const attachment_icon = require("../assets/attachment_icon.png")
export const chatOptions = require("../assets/chatOptions.png")
export const profile = require("../assets/profile.png")
export const delete_icon = require("../assets/delete_icon.png")
export const reply_icon = require("../assets/reply_icon.png")
export const pinpoint_icon = require("../assets/pinpoint.png")
export const add_member = require("../assets/add_member.png")
export const cancle = require("../assets/cancle.png")
export const leave_icon = require("../assets/leave_icon.png")








