// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Cfcustomform12 from "../../blocks/cfcustomform12/src/Cfcustomform12";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions.web";
import CustomisableBusinessProfiles from "../../blocks/customisableuserprofiles/src/CustomisableBusinessProfiles"
import CustomisablePersonalProfiles from "../../blocks/customisableuserprofiles/src/CustomisablePersonalProfiles"
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web.tsx"
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails.web";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import Videos from "../../blocks/videos/src/Videos";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import DataStorage from "../../blocks/datastorage/src/DataStorage";
import Cfelectricalplandrawing from "../../blocks/cfelectricalplandrawing/src/Cfelectricalplandrawing";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Cfdrawingrecognition from "../../blocks/cfdrawingrecognition/src/Cfdrawingrecognition";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfcustomform13 from "../../blocks/cfcustomform13/src/Cfcustomform13";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Cfplumbingplandrawing from "../../blocks/cfplumbingplandrawing/src/Cfplumbingplandrawing";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import Cfcustomform14 from "../../blocks/cfcustomform14/src/Cfcustomform14";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import Cfcustomform17 from "../../blocks/cfcustomform17/src/Cfcustomform17";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cfcustomform16 from "../../blocks/cfcustomform16/src/Cfcustomform16";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Cfarearecognitiontool from "../../blocks/cfarearecognitiontool/src/Cfarearecognitiontool";
import Objectidentifier from "../../blocks/objectidentifier/src/Objectidentifier";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Maps from "../../blocks/maps/src/Maps";
import Chat2 from "../../blocks/chat/src/Chat2.web.tsx"
import ViewChat from "../../blocks/chat/src/ViewChat";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AddNewTeamMember from "../../blocks/teambuilder/src/AddNewTeamMember.web";
import Header from "../../components/src/Header"
import MyProfileHeader from "../../components/src/MyProfileHeader.tsx";
import ProjectPortfolioShells from "../../blocks/projectportfolio/src/ProjectPortfolioShells.web"
import ProjectPlan from "../../blocks/projectportfolio/src/ProjectPlan.web.tsx"

import CustomForm from "../../blocks/customform/src/CustomForm.web"

import CreateEditProjectPortfolio from "../../blocks/projectportfolio/src/CreateEditProjectPortfolio.web"

import ProjectPortfolio from "../../blocks/projectportfolio/src/ProjectPortfolio.web"
import ProjectScope from "../../blocks/projectportfolio/src/ProjectScope.web.tsx";
import ProjectType from "../../blocks/projectportfolio/src/ProjectType.web.tsx";
import TeamList from '../../blocks/teambuilder/src/TeamBuilder.web.tsx'
import ProjectCostPlanUpload from "../../blocks/projectportfolio/src/ProjectCostPlanUpload.web.tsx";
import CustomisableClientProfile from '../../blocks/customisableuserprofiles/src/CustomisableClientProfile.web.tsx'
import ClientEmailAccountRegistration from "../../blocks/email-account-registration/src/ClientEmailAccountRegistration.web.tsx";
import AddNewMember from "../../blocks/teambuilder/src/AddNewMember.web.tsx"
import SubscriptionList from "../../blocks/customisableusersubscriptions/src/SubscriptionList.web.tsx"


const routeMap = {

AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Cfcustomform12:{
 component:Cfcustomform12,
path:"/Cfcustomform12"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionList:{
  component:SubscriptionList,
 path:"/SubscriptionList"},
CustomisableBusinessProfiles:{
  component:CustomisableBusinessProfiles,
 path:"/CustomisableBusinessProfiles"},
 CustomisablePersonalProfiles:{
  component: CustomisablePersonalProfiles,
 path:"/CustomisablePersonalProfiles"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Videos:{
 component:Videos,
path:"/Videos"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
Cfelectricalplandrawing:{
 component:Cfelectricalplandrawing,
path:"/Cfelectricalplandrawing"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Cfdrawingrecognition:{
 component:Cfdrawingrecognition,
path:"/Cfdrawingrecognition"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Cfcustomform13:{
 component:Cfcustomform13,
path:"/Cfcustomform13"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Cfplumbingplandrawing:{
 component:Cfplumbingplandrawing,
path:"/Cfplumbingplandrawing"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/",
exact: true
},
CustomisableClientProfile:{
  component:CustomisableClientProfile,
 path:"/CustomisableClientProfile",
 exact: true
 },
 ClientEmailAccountRegistration:{
  component:ClientEmailAccountRegistration,
 path:"/EmailAccountRegistration",
 exact: true
 },
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Cfcustomform14:{
 component:Cfcustomform14,
path:"/Cfcustomform14"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Multilevelapproval2:{
 component:Multilevelapproval2,
path:"/Multilevelapproval2"},
Cfcustomform17:{
 component:Cfcustomform17,
path:"/Cfcustomform17"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Cfcustomform16:{
 component:Cfcustomform16,
path:"/Cfcustomform16"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Cfarearecognitiontool:{
 component:Cfarearecognitiontool,
path:"/Cfarearecognitiontool"},
Objectidentifier:{
 component:Objectidentifier,
path:"/Objectidentifier"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Maps:{
 component:Maps,
path:"/Maps"},
Chat:{
 component:Chat2,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AddNewTeamMember:{
  component:AddNewTeamMember,
path:"/AddNewTeamMember"},
Header:{
  component:Header,
  path:"/header"},
CustomisableUserProfiles:{
  component:CustomisableUserProfiles,
  path:"/personalDetails"},
ProjectPortfolioShells: {
  component: ProjectPortfolioShells,
  path: "/Shells",
},
ProjectPlan: {
  component: ProjectPlan,
  path: "/ProjectPlan",
},
ProjectScope: {
  component: ProjectScope,
  path: "/ProjectScope",
},
ProjectType: {
  component: ProjectType,
  path: "/ProjectType",
},
ProjectCostPlanUpload: {
  component: ProjectCostPlanUpload,
  path: "/ProjectCostPlanUpload/:navigationBarTitleText/:project_id?"
},
  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  // InfoPage: {
  //   component: InfoPage,
  //   path: '/InfoPage'
  // },
  CustomForm: {

    component: CustomForm,

    path: "/CustomForm"

  },

ProjectPortfolio: {

  component: ProjectPortfolio,

  path: "/ProjectPortfolio"

},

CreateEditProjectPortfolio: {

  component: CreateEditProjectPortfolio,

  path: "/CreateEditProjectPortfolio/:navigationBarTitleText/:project_id?"

},
TeamList: {

  component: TeamList,

  path: "/TeamList"

},
AddNewMember:{
  component:AddNewMember,
  path:"/AddNewMember/:title/:id"
}
  

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw', overflowX: "auto"}}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;