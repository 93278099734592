// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName,} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import moment from "moment";
import { toast } from "react-toastify";
import { debounce } from "lodash";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface RowData {
  id: string;
  name: string;
  members: number;
  dataTime: string;
  email: string;
  job_title: string;
  role_id: string;
}

interface MemberData {
  job_title: string;
  id: string | number;
  first_name: string;
  last_name:string;
  email: string;
  teams: string[];
  role: string;
  invited_status: boolean;
}

interface MemberType {
  id: string;
  user_name: string;
  email: string;
  role_id: string;
  job_title: string;
}

interface MembersList {
  job_title: string;
  id: string | number;
  first_name: string;
  last_name:string;
  email: string;
  teams: string[];
  role: string;
  invited_status: boolean;
}

interface TeamData {
  id: number | string;
  teamName: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  toggle: boolean;
  checked: boolean;
  rows: RowData[];
  membersList: MembersList[];
  dialogOpen: boolean;
  selectedRow: RowData | null;
  anchorEl: HTMLElement | null;
  loading: boolean;
  token: string;
  teamName: string;
  teamNameError: boolean;
  isRenameModalOpen: boolean;
  isDeleteModalOpen: boolean;
  selectedRoles: string[];
  selectedTeam: string[];
  selectedJobTitles: string[];
  selectedSort: string[];
  activated: string[];
  isRoleOpen: boolean;
  isTeamOpen: boolean;
  isApproveOpen: boolean;
  isCreateTeamModalOpen: boolean;
  messageToggle: boolean;
  tostifymessage: string;
  status: string;
  blankError: boolean;
  teamNameRenameError: boolean;
  teamRenameBlankError: boolean;
  isEditDetailModalOpen: boolean;
  memberFirstName: string | undefined;
  memberFirstNameError: string;
  memberLastName: string | undefined;
  memberLastNameError: string;
  memberEmail: string | undefined;
  memberEmailError: string;
  emailFormatError: string;
  jobTitle: string | undefined;
  jobTitleError: string;
  role: string | undefined;
  inviteMemberError: string;
  searchValue: string;
  ownerError: string;
  isInvitedMember: boolean;
  member: MemberType[];
  isJobTitle: boolean;
  isDeactiveMemberModalOpen: boolean;
  selectedMember: MemberData | null;
  teamNameTitle: any;
  moveMemberError: boolean;
  isMoveMemberModalOpen: boolean;
  teamData: TeamData[];
  inputName: string;
  isCreatingNewTeam: boolean;
  jobTitles: any;
  teams: any;
  isAddingAnotherMember: boolean;
  hoverTeamName: boolean;
  selectedMembers: any;
  selectAll: boolean;
  currentPage:number;
  rowsPerPage:number;
  dataRow:any;
  totalRecords:any;
  totalTeam:number;
  searchActive: boolean;
  noTeamsCreated: boolean;
  selectedTab: string;
  teamsSearchValue: string;
  membersSearchValue: string;
  totalMembers:number;
  isEditMembersDetailModalOpen:boolean;
  emailChanged:boolean;
  memberDataFirstName:string | undefined;
  memberDataLastName:string  | undefined;
  memberDataEmail:string | undefined;
  MemberJobTitle:string | undefined;
  hovered:boolean;
  teamHovered:boolean;
  deactivateHover:boolean;
 

}

interface SS {
  id: any;
}

export default class TeamBuilderController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTeamsListCallId = "";
  deleteTeamCallId = "";
  editTeamNameCallId = "";
  getMembersListCallId = "";
  postCreateTeamCallId: string = "";
  editMemberDetailCallId: string = "";
  getInvitedMemberListApiCallId: string = "";
  deleteInvitedMemberCallId: string = "";
  AddInvitedMemberCallId: string = "";
  deactiveMemberCallId: string = "";
  getTeamListCallId: string = "";
  postCreateTeamAndMemberCallId: string = "";
  postMoveMemberCallId: string = "";
  deactiveBulkMemberCallId: string = "";
  postMoveBulkMemberCallId: string = "";
  postBulkCreateTeamAndMemberCallId: string = "";
  getMembersJobTitleListCallId:string="";
  editMemberDetailDataCallId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      rows: [],
      dialogOpen: false,
      selectedRow: null,
      anchorEl: null,
      loading: false,
      token: "",
      teamName: "",
      teamNameError: false,
      isRenameModalOpen: false,
      isDeleteModalOpen: false,
      toggle: true,
      checked: false,
      membersList: [],
      selectedRoles: [],
      selectedTeam: [],
      selectedJobTitles: [],
      selectedSort: [],
      activated: [],
      isRoleOpen: false,
      isTeamOpen: false,
      isApproveOpen: false,
      isCreateTeamModalOpen: false,
      messageToggle: false,
      tostifymessage: "",
      status: "",
      blankError: false,
      teamNameRenameError: false,
      teamRenameBlankError: false,
      isEditDetailModalOpen: false,
      memberFirstName: "",
      memberFirstNameError: "",
      memberLastName: "",
      memberLastNameError: "",
      memberEmail: "",
      memberEmailError: "",
      emailFormatError: "",
      jobTitle: "",
      jobTitleError: "",
      role: "",
      inviteMemberError: "",
      searchValue: "",
      ownerError: "",
      isInvitedMember: false,
      member: [],
      isJobTitle: false,
      isDeactiveMemberModalOpen: false,
      selectedMember: null,
      teamNameTitle: [],
      moveMemberError: false,
      isMoveMemberModalOpen: false,
      teamData: [],
      inputName: "",
      isCreatingNewTeam: false,
      jobTitles: [],
      teams: [],
      isAddingAnotherMember: false,
      hoverTeamName: false,
      selectedMembers: new Set(),
      selectAll: false,
      currentPage:1,
      rowsPerPage:10,
      dataRow:[],
      totalRecords:[],
      totalTeam:0,
      searchActive: false, 
      noTeamsCreated:false,
     selectedTab:'Teams',
     teamsSearchValue: "",
     membersSearchValue: "",
     isEditMembersDetailModalOpen:false,
     totalMembers:0,
     emailChanged:false,
     memberDataFirstName:"",
     memberDataLastName:"",
     memberDataEmail:"",
     MemberJobTitle:"",
     hovered:false,
     teamHovered:false,
     deactivateHover:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  async componentDidMount() {
   
    const token = await getStorageData("token");
    this.setState({ token });
  
    this.setState(
      {
        selectedSort: ["a-z"],
      },
      this.getTeamsListApi
    );
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      this.setState({
        toggle: savedTab === "Teams",
        selectedTab: savedTab,
      });
    }
    
    this.getTeamsListApi();
    this.getMembersJobTitleListApi();
    
  } 

  

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.toggle !== prevState.toggle) {
      this.setState({ currentPage: 1 });
      if (this.state.toggle) {
        this.getTeamsListApi();
      } else {
        this.getMembersListApi();
      }
    }
  }
  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.errors) {
        this.handleApiError(apiCallId, responseJson);
        return;
      }

      switch (apiCallId) {
        case this.getTeamsListCallId:
          this.handleTeamsListResponse(responseJson);
          break;
        case this.editTeamNameCallId:
          this.handleEditTeamResponse(responseJson);
          break;
        case this.deleteTeamCallId:
          this.handleDeleteTeamResponse();
          break;
        case this.getMembersListCallId:
          this.handleMembersListResponse(responseJson);
          break;
        case this.postCreateTeamCallId:
          this.handleCreateTeamResponse(responseJson);
          break;
        case this.editMemberDetailCallId:
          this.handleMemberUpdateResponse(responseJson);
          break;
        case this.getInvitedMemberListApiCallId:
          this.handleInvitedMemberListResponse(responseJson);
          break;
        case this.deleteInvitedMemberCallId:
          this.handleDeletedResponse(responseJson);
          break;
        case this.AddInvitedMemberCallId:
          this.handleInvitedSendResponse(responseJson);
          break;
        case this.deactiveMemberCallId:
          this.handleDeactivateResponse(responseJson);
          break;
        case this.getTeamListCallId:
          this.handleTeamsDataResponse(responseJson);
          break;
        case this.postCreateTeamAndMemberCallId:
          this.handleCreateTeamAndAddMemberResponse(responseJson);
          break;
        case this.postMoveMemberCallId:
          this.handleMoveMemberResponse(responseJson);
          break;
        case this.deactiveBulkMemberCallId:
          this.handleBulkMemberDeactivateResponse(responseJson);
          break;
        case this.postMoveBulkMemberCallId:
          this.handleMoveBulkMemberResponse(responseJson);
          break;
        case this.postBulkCreateTeamAndMemberCallId:
          this.handleBulkCreateTeamAndMemberCallId(responseJson);
          break;
        case this.getMembersJobTitleListCallId:
          this.handleMembersJobTitleListResponse(responseJson)
          break;
        case this.editMemberDetailDataCallId:
          this.handleEditMembersDetailsDataResponse(responseJson)
          break;
        default:
          break;
      }
    }
  }

  private handleApiError(apiCallId: string, responseJson: any) {
    if (apiCallId === this.editTeamNameCallId) {
      this.handleError(responseJson);
    } else if (apiCallId === this.postCreateTeamCallId) {
      this.handleCreateTeamResponse(responseJson);
    } else if (apiCallId === this.deleteTeamCallId) {
      this.handleDeleteResponse(responseJson);
    } else if (apiCallId === this.editMemberDetailCallId) {
      this.handleInviteResponse(responseJson);
    }else if (apiCallId === this.postCreateTeamAndMemberCallId){
      this.handleCreateTeamAndMemberErrorResponse(responseJson)
    }else if(apiCallId === this.editMemberDetailDataCallId){
      this.handleEditMembersDetailsDataErrorResponse(responseJson)
    }else if (apiCallId === this.postBulkCreateTeamAndMemberCallId){
      this.handleBulkCreateTeamAndMemberResponse(responseJson)
    }
  }

  handleBulkCreateTeamAndMemberResponse = (responseJson: any) => {
    if (responseJson.errors && responseJson.errors.includes("Team name already exists")) {
        this.setState({
            teamNameError: true,
            blankError: false
        });
    }
    
};




  handleCreateTeamAndMemberErrorResponse = (responseJson:any) => {
 
    const errorMessage = responseJson.errors[0]; 
  
    if (errorMessage === "Team name already exists") {
      this.setState({
        teamNameError: true,
        teamNameRenameError: false,
        blankError: false,
        teamRenameBlankError: false,
      });
    } 
  };


  handleMembersJobTitleListResponse=(responseJson:any)=>{
    const jobTitles = [
      ...new Set(
        responseJson.members
          .map((member: { job_title: any }) => member.job_title)
          .filter(Boolean)
      ),
    ];
    const teams = [
      ...new Set(
        responseJson.members
          .flatMap((member: { teams: any }) => member.teams || []) 
          .filter(Boolean) 
      ),
    ];
    this.setState({jobTitles:jobTitles , teams:teams})
  }
  

  handleMoveBulkMemberResponse = (responseJson: any) => {
    if(responseJson.message){
      this.setState({
        isMoveMemberModalOpen:false,
        teamNameTitle:"",
        selectedMembers: new Set(),
        selectAll:false
      })
      toast.success(`The members have been successfully added to the teams.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
      this.getMembersListApi()
    }
  };

  handleBulkCreateTeamAndMemberCallId = (responseJson: any) => {
    if (responseJson.error === "New teams not found") {
      console.log("Setting blankError to true");
      this.setState({
          blankError: true,
          teamNameError: false
      });
  } 
    if(responseJson.message){
      this.setState({
        isCreateTeamModalOpen:false,
        teamNameTitle:"",
        selectedMembers: new Set(),
        teamName:"",
        selectAll:false
      })
      toast.success(`The members have been successfully added to the teams.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
      this.getMembersListApi()
    }

  };

  handleTeamsDataResponse = (responseJson: any) => {
    const teamData: TeamData[] = responseJson.data.map((item: any) => ({
      id: item?.id,
      teamName: item?.attributes.team_name,
    }));

    this.setState({ teamData });
  };

  handleDeletedResponse = (responseJson: any) => {
    this.getInvitedMembersListAPICall();
  };

  handleBulkMemberDeactivateResponse = (responseJson: any) => {
    if(responseJson.message){
      toast.success(`The accounts has been successfully deactivated.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
      this.setState({
        isDeactiveMemberModalOpen:false,
        selectedMembers: new Set(),
        selectAll:false
      })
     this.getMembersListApi()

    }
  };

  handleDeactivateResponse = async (responseJson: any) => {
    if (!responseJson.error) {
      toast.success(`The account has been successfully deactivated.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
  
      this.setState({
        isDeactiveMemberModalOpen: false,
      });
  
      const deactivatedJobTitle = responseJson.memberJobTitle; 
      const jobTitles = this.state.jobTitles;
  
      if (deactivatedJobTitle && !jobTitles.includes(deactivatedJobTitle)) {
        this.setState({
          jobTitles: [...jobTitles, deactivatedJobTitle], 
        });
      }
  
      this.getMembersListApi();
      this.getMembersJobTitleListApi();
      
    } else if (responseJson.error) {
      toast.error(`${responseJson.error}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
  
      this.setState({
        isDeactiveMemberModalOpen: false,
      });
  
      this.getMembersListApi();
    }
  };
  

  handleInvitedSendResponse = async (responseJson:any) => {
    this.setState({
      isInvitedMember: false,
    });
  
    toast.success(`${responseJson.message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      closeButton: false,
      theme: "light",
    });
  
    const invitedJobTitle = responseJson.invitedMemberJobTitle;
    if (invitedJobTitle) {
      const jobTitles = this.state.jobTitles;
      if (!jobTitles.includes(invitedJobTitle)) {
        this.setState({
          jobTitles: [...jobTitles, invitedJobTitle],
        });
      }
    }
  
    await this.getMembersJobTitleListApi();
    this.getMembersListApi();
  };
  
  handleInviteResponse = (responseJson: any) => {
    this.setState({
      emailFormatError: "",
      memberFirstNameError: '',
      memberLastNameError: "",
      memberEmailError: "",
      jobTitleError: "",
      inviteMemberError: "",
    });
  
    if (Array.isArray(responseJson.errors)) {
      responseJson.errors.forEach((error: string) => {
        if (error === "Email is invalid") {
          this.setState({ emailFormatError: "Email is invalid" });
        }
        if (error === "First name can't be blank") {
          this.setState({ memberFirstNameError: "First name can't be blank" });
        } else if (error === "Last name can't be blank") {
          this.setState({ memberLastNameError: "Last name can't be blank" });
        } else if (error === "Email can't be blank") {
          this.setState({ memberEmailError: "Email can't be blank" });
        } else if (error === "Job title can't be blank") {
          this.setState({ jobTitleError: "Job title can't be blank" });
        } else if (error === "Email address already exists.") {
          this.setState({ inviteMemberError: "Email address already exists." });
        }
      });
    }
  }

  handleDeleteResponse = (responseJson: any) => {
    if (responseJson.errors) {
      toast.success(`${responseJson.errors}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
    }
  };

  handleEditTeamResponse(responseJson: any) {
    if (responseJson.data) {
      this.handleRenameModal();
      this.handleClose();
      this.setState({
        messageToggle: true,
        tostifymessage: "The team has been successfully renamed.",
        status: "success",
      });
      this.getTeamsListApi();

      setTimeout(() => {
        this.setState({ messageToggle: false, tostifymessage: "", status: "" });
      }, 2000);
    }
  }

  private handleDeleteTeamResponse() {
    this.handleDeleteModal();
    toast.success(
      `The ${this.state.selectedRow?.name} has been successfully deleted.`,
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      }
    );
  
    this.handleClose();
  
    this.getTeamsListApi();
  
 
  }


  handleError = (responseJson: any) => {
    if (responseJson.errors == "Team name can't be blank") {
      this.setState({ teamRenameBlankError: true, teamNameRenameError: false });
    } else if (responseJson.errors == "Team name already exists") {
      this.setState({ teamNameRenameError: true, teamRenameBlankError: false });
    }
  };

  handleEditMembersDetailsDataErrorResponse = (responseJson: any) => {
    this.setState({
      emailFormatError: "",
      memberFirstNameError: "",
      memberLastNameError: "",
      memberEmailError: "",
      jobTitleError: "",
      inviteMemberError: "",
    });
  
    if (Array.isArray(responseJson.errors)) {
      responseJson.errors.forEach((error: string) => {
        switch (error) {
          case "Email is invalid":
            this.setState({ emailFormatError: "Email is invalid" });
            break;
          case "First name can't be blank":
            this.setState({ memberFirstNameError: "First name can't be blank" });
            break;
          case "Last name can't be blank":
            this.setState({ memberLastNameError: "Last name can't be blank" });
            break;
          case "Email can't be blank":
            this.setState({ memberEmailError: "Email can't be blank" });
            break;
          case "Job title can't be blank":
            this.setState({ jobTitleError: "Job title can't be blank" });
            break;
          case "Email address already exists.":
            this.setState({ inviteMemberError: "Email address already exists." });
            break;
          default:
        }
      });
    }  
  };
  

  handleCreateTeamModal = () =>
    this.setState((prev) => ({
      isCreateTeamModalOpen: !prev.isCreateTeamModalOpen,
      isCreatingNewTeam: false,
      teamName: "",
      teamNameError: false,
      blankError: false,
      selectedMembers: new Set(),
      selectAll:false
    }));

  handleCreateTeamName = (teamName: string) => {
    this.setState({ teamName, teamNameError: false });
  };

  handleCreateTeamCancelButton = () => {
    this.setState((prev) => ({
      isCreateTeamModalOpen: !prev.isCreateTeamModalOpen,
      teamName: "",
      teamNameError: false,
      blankError: false,
      selectedMembers: new Set(),
      selectAll:false
    }));
  };

  handleCreateTeamSubmit = async () => {
    const token = await getStorageData("token");

    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };

    const body = {
      data: {
        attributes: {
          team_name: this.state.teamName,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateTeamCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CreateTeamEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCreateTeamResponse = async (responseJson: any) => {
    setStorageData("successData", "true");
    if (!responseJson.errors) {
      this.props.navigation.navigate(
        "AddNewMember",
        { title: this.state.teamName, id: responseJson.data.id },
        { title: this.state.teamName, id: responseJson.data.id }
      );
      this.setState({
        teamName: "",
        isCreateTeamModalOpen: false,
      });
    } else {
      if (responseJson.errors == "Team name already exists") {
        this.setState({
          teamNameError: true,
          teamNameRenameError: false,
          blankError: false,
          teamRenameBlankError: false,
        });
      } else if (responseJson.errors == "Team name can't be blank") {
        this.setState({
          blankError: true,
        });
      }
    }
  };

  handleRenameModal = () => {
    this.setState((prev) => ({
      isRenameModalOpen: !prev.isRenameModalOpen,
      teamName: this.state.selectedRow!.name,
      anchorEl: null,
      teamNameError: false,
      teamRenameBlankError: false,
      teamNameRenameError: false,
      blankError: false,
    }));
  };

  handleDeleteModal = () => {
    this.setState((prev) => ({
      isDeleteModalOpen: !prev.isDeleteModalOpen,
      anchorEl: null,
    }));
  };

  handleTeamNameChange = (teamName: string) => {
    this.setState({ teamName, teamNameError: false, blankError: false });
  };

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgSuccess = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgSuccess.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgSuccess);
  }

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };
  handleClick = (event: React.MouseEvent<HTMLElement>, row: RowData) => {
    this.setState({ anchorEl: event.currentTarget, selectedRow: row });
  };

  handleClickMember = (
    event: React.MouseEvent<HTMLElement>,
    row: MemberData
  ) => {
    this.setState({ anchorEl: event.currentTarget, selectedMember: row });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedRow: null });
  };

  getTeamsListApi = (search = "", page = 1, limit = this.state.rowsPerPage) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
  
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTeamsListCallId = requestMsg.messageId;
  
    const sortFilterQuery = this.state.selectedSort
      .map((sort) => `&sort_by=${sort.toLowerCase()}`)
      .join("");
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamEndPoint}?team_name=${search}${sortFilterQuery}&page_no=${page}&limit=${limit}`
    );
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  };
  
  
  

  deleteTeamApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteTeamCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamEndPoint}/${this.state.selectedRow!.id}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  editTeamNameApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const body = {
      data: {
        attributes: {
          team_name: this.state.teamName.trim(),
        },
      },
    };

    this.editTeamNameCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamEndPoint}/${this.state.selectedRow!.id}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  getMembersListApi = (search = "",page = 1, limit = this.state.rowsPerPage) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getMembersListCallId = requestMsg.messageId;

    const roleFilterQuery = this.state.selectedRoles
      .map((role) => `&role_id[]=${role.toLowerCase()}`)
      .join("");

    const jobFilterQuery = this.state.selectedJobTitles
      .map((job) => `&job_title[]=${encodeURIComponent(job)}`)
      .join("");

    const sortFilterQuery = Array.isArray(this.state.selectedSort)
      ? this.state.selectedSort
          .map((team) => `&sort_by=${encodeURIComponent(team)}`)
          .join("")
      : "";

    const teamFilterQuery = this.state.selectedTeam.
    map((team:any) => `&search[]=${encodeURIComponent(team)}`)
      .join("");


      const activateFilterQuery = this.state.activated.length === 2
      ? ""
      : this.state.activated
          .map((activate) => {
            if (activate === "Activated") {
              return `&status=active`;
            } else if (activate === "Not Activated") {
              return `&status=inactive`;
            }
          })
          .join("");

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllTeamMembersEndPoint}?user_name=${search}${roleFilterQuery}${jobFilterQuery}${sortFilterQuery}${activateFilterQuery}${teamFilterQuery}&page_no=${page}&limit=${limit}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  };


  getMembersJobTitleListApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getMembersJobTitleListCallId = requestMsg.messageId;

    const jobFilterQuery = this.state.selectedJobTitles
      .map((job) => `&job_title=${encodeURIComponent(job)}`)
      .join("");

      const teamFilterQuery = this.state.selectedTeam.
      map((team:any) => `&search=${encodeURIComponent(team)}`)
        .join("");

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllTeamMembersEndPoint}?${jobFilterQuery}${teamFilterQuery}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  };

  handleToggle = async(tab: string) => {
    const isTeamsTab = tab === "Teams";
    localStorage.setItem("selectedTab", tab);
  
    this.setState({
      toggle: isTeamsTab,
      searchValue: "",
      searchActive: false,
      selectedRoles: [],
      selectedTeam: [],
      selectedJobTitles: [],
      activated: [],
      noTeamsCreated: false,
    }, async () => {
      if (isTeamsTab) {
        await this.getTeamsListApi();
        this.setState({ noTeamsCreated: this.state.rows.length === 0 ,toggle: true, selectedMembers: new Set() ,selectAll:false });
      } else {
        this.setState({ toggle: false });
        await this.getMembersListApi();
      }
    });
  };
  
  

  handleChange = (event: any) => {
    const { name, value } = event.target;
    const isClear = value.includes("clear");
    if (name === "job") {
      this.setState(
        {
          selectedJobTitles: isClear ? [] : value,
        },
        this.getMembersListApi
      );
    } else if (name === "role") {
      this.setState(
        {
          selectedRoles: isClear ? [] : value,
        },
        this.getMembersListApi
      );
    } else if (name === "activated") {
      this.setState(
        {
          activated:isClear ? [] :  value as string[],
        },
        this.getMembersListApi
      );
    } else if (name === "team") {
      this.setState(
        {
          selectedTeam: isClear ? [] : value, 
        },
        this.getMembersListApi 
      );
    }
  };

  getRoleStyles(role: string) {
    let backgroundColor;
    switch (role) {
      case "owner":
        backgroundColor = "#237182";
        break;
      case "employee":
        backgroundColor = "#C5E0E7";
        break;
      default:
        backgroundColor = "#E0EDF0";
    }
    const color = role === "manager" ? "#237182" : "#fff";
    return { backgroundColor, color };
  }

  handleEditTeam = (row: RowData) => {
    this.props.navigation.navigate(
      "AddNewMember",
      { title: row.name, id: row.id },
      { title: row.name, id: row.id }
    );
  };

  handleEditDetailModal = () => {
    this.setState({
      anchorEl: null,
      isEditDetailModalOpen: !this.state.isEditDetailModalOpen,
      isAddingAnotherMember: false,
      memberFirstName: "",
      memberLastName: "",
      memberEmail: "",
      jobTitle: "",
      role: "owner",
      memberEmailError: "",
      memberFirstNameError: "",
      memberLastNameError: "",
      emailFormatError: "",
      jobTitleError: "",
      ownerError: "",
      inviteMemberError: "",
    });
  };

  handleInputDataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
  
    let filteredValue = value.trim();
  
    if (name === "memberFirstName" || name === "memberLastName" || name === "jobTitle") {
      filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    }
  
    this.setState((prevState) => ({
      ...prevState,
      [name as keyof typeof prevState]: filteredValue,
      ...(name === "memberEmail" && { emailFormatError: "", memberEmailError: "", inviteMemberError: "" }),
      ...(name === "memberFirstName" && { memberFirstNameError: "" }),
      ...(name === "memberLastName" && { memberLastNameError: "" }),
      ...(name === "jobTitle" && { jobTitleError: "" }),
    }));
  };
  
  
  

  ternarHandler = (condition: boolean, option1: any, option2: any) =>
    condition ? option1 : option2;

  handleRoleClick = (value: string) => {
    this.setState({ role: value });
  };

  handleSubmit = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };

    const body = {
      membership: {
        first_name: this.state.memberFirstName?.trim(),
        last_name: this.state.memberLastName?.trim(),
        email: this.state.memberEmail?.trim().toLowerCase(),
        job_title: this.state.jobTitle?.trim(),
        role_id: this.state.role?.trim(),
        activated: false,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editMemberDetailCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editMemberDetailEndPoint}/invite_member`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMemberUpdateResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.getMembersListApi();
      this.setState({ isEditDetailModalOpen: false, isInvitedMember: true });
      this.getInvitedMembersListAPICall();
    }
  };

  clearSearch = () => {
    this.setState({ searchValue: "" });
    if (this.state.toggle) {
      this.getTeamsListApi("");
    } else {
      this.getMembersListApi("");
    }
  };



  handleInputChange = (e: any) => {
    const { toggle } = this.state;
    const value = e.target.value;
  
    this.setState({
      searchValue: value,
      searchActive: value.length > 0,
    });
  
    if (toggle) {
      this.setState({ teamsSearchValue: value });
    } else {
      this.setState({ membersSearchValue: value });
    }
  
    const debouncedApiCall = debounce(
      toggle ? this.getTeamsListApi : this.getMembersListApi,
      1000
    );
    debouncedApiCall(value);
  };
  
  

 

  handleSortTeamChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
  ) => {
    const { name, value } = event.target;
    if (name === "sort") {
      this.setState(
        {
          selectedSort: [value as string],
        },
        () => {
          if (this.state.toggle) {
            this.getTeamsListApi(); 
          } else {
            this.getMembersListApi(); 
          }
        }
      );
    }
  };

  handleInviteMember = () => {
    this.setState({
      anchorEl: null,
      isInvitedMember: !this.state.isInvitedMember,
      memberFirstName: "",
      memberLastName: "",
      memberEmail: "",
      jobTitle: "",
      role: "owner",
      memberEmailError: "",
      memberFirstNameError: "",
      memberLastNameError: "",
      emailFormatError: "",
      jobTitleError: "",
      ownerError: "",
    });
  };

  handleSkipMember = () => {
    this.setState({
      isEditDetailModalOpen: false,
      isInvitedMember: true,
    });
  };

  handleAddMoreInviteMember = () => {
    this.setState({
      isEditDetailModalOpen: true,
      isAddingAnotherMember: true,
      memberFirstName: "",
      memberLastName: "",
      memberEmail: "",
      jobTitle: "",
      role: "owner",
      memberEmailError: "",
      memberFirstNameError: "",
      memberLastNameError: "",
      emailFormatError: "",
      jobTitleError: "",
      ownerError: "",
      isInvitedMember: false,
    });
  };

  removeInvitedMemberApiCall = (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const body = {
      member_id: id,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteInvitedMemberCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteInvitedMember}/delete_invited_member`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  handleCondition = (Condition: any, True: any, False: any) => {
    return Condition ? True : False;
  };

  getInvitedMembersListAPICall = async () => {
    const token = await getStorageData("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInvitedMemberListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.invitedMemberEndPoint}/get_invited_members`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInvitedMemberListResponse = (responseJson: any) => {
    const inviteMember: MemberType[] = responseJson.members.map(
      (item: any) => ({
        id: item?.id,
        user_name: item?.user_name,
        email: item?.email,
        role_id: item?.role,
        job_title: item?.job_title,
      })
    );

    this.setState({ member: inviteMember });
  };

  handleAllInvitedMemberSubmit = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };
    let ids: string[] = [];
    this.state.member.forEach((item) => {
      ids.push(item.id);
    });

    const body = {
      member_ids: ids,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AddInvitedMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AddInvitedMemberEndPoint}/created_member_invites`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeactiveAccount = () => {
    this.setState((prev) => ({
      isDeactiveMemberModalOpen: !prev.isDeactiveMemberModalOpen,
      anchorEl: null,
    }));
  };

  handleDeactivateMemberApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deactiveMemberCallId = requestMsg.messageId;

    const body = {
      user_id: this.state.selectedMember?.id,
      confirm: "true",
    };

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.DeactivateEndPoint}/deactivate_member`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  handleMoveMemberSubmit = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };

    const newTeamIds = this.state.teamNameTitle.map(
      (option: { value: any }) => option.value
    );

    const body = {
      data: {
        attributes: {
          account_id: this.state.selectedMember?.id,
          new_team_ids: newTeamIds,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postMoveMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postMoveMemberEndPoint}/add_member_to_teams`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMoveMemberResponse = (responseJson: any) => {
    if (responseJson.message) {
      toast.success(`The members have been successfully added to the teams.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
      
      this.setState({
        isMoveMemberModalOpen: false,
      });
  
      this.getMembersListApi();
  
      this.getMembersJobTitleListApi();
    } else if (responseJson.error) {
      this.setState({
        moveMemberError: responseJson.error,
      });
    }
  };
  

  handleMoveMemberToOtherTeam = () => {
    this.setState((prev) => ({
      isMoveMemberModalOpen: !prev.isMoveMemberModalOpen,
      anchorEl: null,
      moveMemberError: false,
      teamNameTitle: "",
    }));
  };

  fetchTeamsApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "Application/json",
      token: token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getTeamListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetTeamListEndPoint}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };
  handleInpChange = (inputValue: string) => {
    this.setState({ inputName: inputValue });
  };

  handleCreateTeamAndAddMember = async () => {
    const token = await getStorageData("token");



    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };


    
    const body = {
      data: {
        attributes: {
          account_id: this.state.selectedMember?.id,
          new_team_name: this.state.teamName,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateTeamAndMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postMoveMemberEndPoint}/add_member_to_teams`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCreateTeamAndAddMemberResponse = async (responseJson: any) => {
    if (responseJson.error === "New teams not found") {
      this.setState({
        blankError: true,
        teamNameError: false,
        teamNameRenameError: false,
        teamRenameBlankError: false,
      });
    } else if (!responseJson.errors) {
      toast.success(`The members have been successfully added to the teams.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      });
  
      this.setState({
        teamName: "",
        isCreateTeamModalOpen: false,
        isCreatingNewTeam: false,
        isMoveMemberModalOpen: false,
        blankError: false,
        teamNameError: false,
      });
  
      this.getMembersListApi();
  
      this.getMembersJobTitleListApi();
    }
  };
  
  handleCreateNewTeamAndAddMember = () => {
    this.setState({
      isCreateTeamModalOpen: true,
      isCreatingNewTeam: true,
      isMoveMemberModalOpen: false,
    });
  };

  handleCreateBulkNewTeamAndAddMember = () => {
    this.setState({
      isCreateTeamModalOpen: true,
      isCreatingNewTeam: true,
      isMoveMemberModalOpen: false,
    });
  };

  clearSelection = () => {
    this.setState({ selectedMembers: new Set() , selectAll:false });
  };
  handleDeactivateSelected = () => {
    const { selectedMembers } = this.state;
    if (selectedMembers.size === 0) {
      return;
    }
    const arrayData = Array.from(selectedMembers);

    this.setState({ isDeactiveMemberModalOpen: true });
  };

  handleAddToTeamSelected = () => {
    const { selectedMembers } = this.state;
    if (selectedMembers.size === 0) {
      return;
    }
    const arrayData = Array.from(selectedMembers);
    this.setState({ isMoveMemberModalOpen: true });
  };

  handleSelectAllChange = () => {
    const { selectAll, membersList } = this.state;
    const newSelectedMembers = new Set();

    if (!selectAll) {
      membersList.forEach((member) => newSelectedMembers.add(member.id));
    }

    this.setState({
      selectedMembers: newSelectedMembers,
      selectAll: !selectAll,
    });
  };

  handleCheckboxChange = (memberId: any) => {
    const selectedMembers = new Set(this.state.selectedMembers);

    if (selectedMembers.has(memberId)) {
      selectedMembers.delete(memberId);
    } else {
      selectedMembers.add(memberId);
    }

    this.setState({
      selectedMembers,
      selectAll: selectedMembers.size === this.state.membersList.length,
    });
  };

  handleBulkDeactivate = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deactiveBulkMemberCallId = requestMsg.messageId;
    const arrayData = Array.from(this.state.selectedMembers);
    const body = {
      "member_ids": arrayData,
      "status": false
    };

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.DeactivateEndPoint}/bulk_active_deactive`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  handleMoveBulkMemberSubmit = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };

    const arrayData = Array.from(this.state.selectedMembers);
   
    const newTeamIds = this.state.teamNameTitle.map(
      (option: { value: any }) => option.value
    );
    const body = {
      data: {
        attributes: {
          account_id:arrayData,
          new_team_ids: newTeamIds,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postMoveBulkMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postMoveMemberEndPoint}/add_member_to_teams`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleBulkCreateTeamAndAddMember = async () => {
 
    const token = await getStorageData("token");

    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };
 

    const arrayData = Array.from(this.state.selectedMembers);



    const body = {
      data: {
        attributes: {
          account_id: arrayData,
          new_team_name: this.state.teamName,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postBulkCreateTeamAndMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postMoveMemberEndPoint}/add_member_to_teams`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTeamsListResponse(responseJson: any) {
    if (responseJson.data && Array.isArray(responseJson.data)) {
      const formattedData = responseJson.data.map((team: any) => {
        if (team && team.attributes) {
          return {
            id: team.id,
            name: team.attributes.team_name || "N/A",
            members: team.attributes.members_count || 0,
            dataTime: moment(team.attributes.created_at).format("DD MMMM YYYY"),
          };
        }
        return null;
      }).filter((item: any) => item !== null);
      
      const totalRecords = responseJson?.pagy?.total_count || 0; 
       this.setState({
        totalTeam:responseJson?.pagy?.total_count
       })
      this.setState({
        rows: formattedData,  
        totalRecords,   
        noTeamsCreated: responseJson.data.length === 0, 
      });
    } else {
      this.setState({ rows: [], totalRecords: 0 });
    }
  }


  handleMembersListResponse = (responseJson:any) => {
    const formattedData = responseJson.members.map((member: any, i: any) => ({
      id: `${i}`,
      ...member,
    }));
    const totalMembers = responseJson.pagy ? responseJson.pagy.table.count : formattedData.length;
    this.setState({
      membersList: formattedData,
      totalMembers,
    });
  };
  
fetchTeamsData = (page: number) => {
  const {  rowsPerPage } = this.state;
  this.getTeamsListApi("", page, rowsPerPage); 
};

handleNextPage = () => {
  const { currentPage, rowsPerPage, totalRecords } = this.state;

  const totalPages = 
  
  Math.ceil(totalRecords / rowsPerPage);

  if (currentPage < totalPages) {
    const nextPage = currentPage + 1;
    this.setState(
      { currentPage: nextPage }, 
      () => {
      this.fetchTeamsData(nextPage);  
    }
  );
  }
};

handlePreviousPage = () => {

  const { currentPage } = this.state;
  if (currentPage > 1) 
    {
    const previousPage = currentPage - 1;
    this.setState(
      { currentPage: previousPage },
       () => {
      this.fetchTeamsData(previousPage); 
    }
  );
  }
};

  setPage = (page:any) => {
    this.setState({ currentPage: page }, () => {
      this.fetchTeamsData(page); 
    });
  };

  setMembersPage = (page:any) => {
    this.setState({ currentPage: page }, () => {
      this.fetchMembersData(page); 
    });
  };

  fetchMembersData = (page:any) => {
    const { rowsPerPage } = this.state;
    this.getMembersListApi("",page, rowsPerPage)
  };

  handleNextMemberPage = () => {
    const { currentPage, rowsPerPage, totalMembers } = this.state;
    const totalPages = Math.ceil(totalMembers / rowsPerPage);
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      this.setState({ currentPage: nextPage }, () => {
        this.fetchMembersData(nextPage);
      });
    }
  };

  handlePreviousMemberPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      const previousPage = currentPage - 1;
      this.setState({ currentPage: previousPage }, () => {
        this.fetchMembersData(previousPage);
      });
    }
  };  

  handleMembersEditDetailModal = () => {

    this.setState({
        anchorEl: null,
        isEditMembersDetailModalOpen: !this.state.isEditMembersDetailModalOpen,
        memberDataFirstName: this.state.selectedMember?.first_name,
        memberDataLastName: this.state.selectedMember?.last_name,
        memberDataEmail: this.state.selectedMember?.email,
        MemberJobTitle: this.state.selectedMember?.job_title,
        role: this.state.selectedMember?.role,
        memberFirstNameError:"",
        memberLastNameError:"",
        memberEmailError:"",
        emailFormatError:"",
        inviteMemberError:"",
        jobTitleError:""

    });
}

handleMembersInputDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.target;
  
  this.setState(prevState => ({
    ...prevState,
    [name]: value
  }));
};


 handleMouseEnter = () => this.setState({hovered:true });
 handleMouseLeave = () => this.setState({hovered:false});

 handleMouseTeamEnter = () => this.setState({teamHovered:true });
 handleMouseTeamLeave = () => this.setState({teamHovered:false });

 handleMouseDeactivateEnter = () => this.setState({deactivateHover:true });
 handleMouseDeactivateLeave = () => this.setState({deactivateHover:false});



    handlMembersEditDetailsSubmit = async () => {
        const token = await getStorageData("token");
        const header = {"Content-Type": configJSON.ContentType,
          token: token,};
  
        const body = {
          membership: {  first_name: this.state.memberDataFirstName?.trim(),
            last_name: this.state.memberDataLastName?.trim(),
   email: this.state.memberDataEmail?.trim().toLowerCase(),
            job_title: this.state.MemberJobTitle?.trim(),
            role_id: this.state.role?.trim(),
          }, };
  
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.editMemberDetailDataCallId = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.editMemberDetailsEndPoint}/update_members?member_id=${this.state.selectedMember?.id}`);
  
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header) );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PATCH"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage
        );

        this.setState(
          {
          emailChanged:
            this.state.memberDataEmail?.trim() !==
            this.state.selectedMember?.email,
        }
      );

    };

    handleEditMembersDetailsDataResponse = (responseJson:any)=>{

      if (!responseJson.errors) {

        if (this.state.emailChanged)
           {
          toast.success(`The member's email has been updated. They need to verify their new email address.`,
           
            {
              position: "top-center",
              autoClose: 5000,
              closeOnClick: false,
              hideProgressBar: true,
              pauseOnHover: false,
              draggable: false,
              closeButton: false,
              theme: "light",
            });
        } else
         {
          toast.success(`Member details have been successfully edited.`, 
            {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            theme: "light",
          }
        );  }
  
        this.getMembersListApi();
        this.setState(
          {
          isEditMembersDetailModalOpen: false,
        });
      }
    }
  
}

// Customizable Area End
