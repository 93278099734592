import React from "react";

// Customizable Area Start
import {
  styled, Box, Typography, TextField, IconButton
} from "@material-ui/core";
import { Logo, backgroundImage, error, success } from "./assets";
import { VisibilityOffOutlined as VisibilityOffOutlinedIcon, VisibilityOutlined as VisibilityOutlinedIcon } from '@material-ui/icons';
import ToastMessage from './ToastMessage.web';

const ValidationText = styled(Box)({
    gap: '5px',
    display: "flex",
    alignItems: "center",
    fontFamily: "'Poppins', sans-serif",
    marginBottom: '5px'
})
const TypeTxtStyle = styled(Typography)({
    color: "#334155",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "5px",
    fontFamily: "'Poppins', sans-serif",
})
const ErrorTxtStyle = styled(Typography)({
    color: "#334155",
    fontSize: "14px",
    fontFamily:"'Poppins', sans-serif",
})
const MainContainer = styled(Box)({
  fontFamily: "'Poppins', sans-serif",
  fontLine:"24px",
  fontWeight:600,
  display: "flex",
  height: "100vh",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflowY:"hidden",
  "@media (max-width: 768px)": {
    height:'auto',
    overflowY:'scroll',
    flexDirection:'column'
  },
})
const LeftWrapper = styled(Box)({
  flex: 1,
  position: "relative",
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  "@media (max-width: 768px)": {
    padding: '20px'
  },
})
const RightWrapper = styled(Box)({
  flex: 1,
  padding: "20px",
  display: "flex",
  alignItems: "center",
  width: "100%",
  borderBottomLeftRadius: "20px",
  borderTopLeftRadius: "20px",
  backgroundColor: "white",
  "@media (max-width: 768px)": {
    width: "auto",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
})
const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  margin: "0 auto",
  "@media (max-width: 768px)": {
    width: "100%",
    margin: 0,
  },
})
const TitleTxt = styled('div')({
  fontWeight: 700,
  textAlign: "center",
  color: "#0F172A",
  fontSize: "30px",
  fontFamily:"'Poppins', sans-serif",
  marginBottom: '25px',
  letterSpacing: "-0.2px"
})
const InputContainer = styled("div")({
  marginBottom: "15px",
  width: "100%",
  display: 'grid',
  flexDirection:'column',
  gap: '12px',
  '& .MuiFormHelperText-contained': {
    marginLeft: 0,
    fontFamily: "'Poppins', sans-serif"
  }
})
const ResetButton = styled("button")({
  width: "100%",
  padding: "16px",
  border: "none",
  cursor: "pointer",
  color: "#FFFFFF",
  background: "#237182",
  borderRadius: "8px",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: 'none',
  fontFamily:"'Poppins', sans-serif",
})
const DisabledButton = styled("button")({
  width: "100%",
  padding: "16px",
  border: "none",
  cursor: "pointer",
  color: "#64748B",
  background: "#F1F5F9",
  borderRadius: "8px",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: 'none',
  fontFamily:"'Poppins', sans-serif",
})
const LogoWrapper = styled("div")({
  position: "absolute",
  width: "90%",
  height: '85vh',
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    position:'inherit'
  },
});
const LogoImg = styled("img")({
  width: "70%",
  height:'70%',
  objectFit:'contain',
  "@media (max-width: 1440px)": {
    width: "79.5%",
    height:'79.5%'
  },
});
const LogoTopText = styled("div")({
  color: "#fff",
  fontSize: "30px",
  fontWeight: 700,
  width: "100%",
  fontFamily:"'Poppins', sans-serif",
  letterSpacing: '-0.5px'
});
const TextForLogo = styled("div")({
  textAlign: "center",
  fontFamily:"'Poppins', sans-serif",
  fontWeight: 700,
  fontSize: "48px",
  color: "white",
  lineHeight: "1.2",
  letterSpacing: '-3',
  marginBlock:'10px',
  "@media (max-width: 768px)": {
    fontSize: "24px",
  },
});
const TextForLogo2 = styled("div")({
  textAlign: "center",
  fontFamily:"'Poppins', sans-serif",
  fontWeight: 400,
  fontSize: "18px",
  color: "white",
})
const TextWrapper = styled("div")({
  paddingLeft: "11px",
  paddingRight: "12px"
})
const inputStyles = {
  borderRadius: '8px', 
  height:'45px',
  fontSize:'16px',
  fontWeight: 400,
  fontFamily:"'Poppins', sans-serif",
  letterSpacing:'-0.05px',
  paddingRight: "20px"
}; 

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height:'45px',
    borderRadius:'8px',
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      border: "0.5px solid #CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #CBD5E1",
    },
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
  },
});


// Customizable Area End

import ForgotPasswordController, {
  Props, configJSON
} from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <MainContainer style={{
        backgroundImage: `url(${backgroundImage})`,
        
      }}>
        <LeftWrapper>
          <LogoWrapper>
            <LogoTopText>Logo Here</LogoTopText>
            <LogoImg src={Logo} alt="Logo" />
            <TextWrapper>
              <TextForLogo>Your new construction management tool.
              </TextForLogo>
               <TextForLogo2> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non faucibus nunc. Pellentesque</TextForLogo2>
            </TextWrapper>
          </LogoWrapper>
        </LeftWrapper>
        <RightWrapper >
          <FormContainer>
            <TitleTxt>{configJSON.resetTxt}</TitleTxt>
            <InputContainer>
            <Box sx={{ mb: 1 }}>
              <TypeTxtStyle>Password</TypeTxtStyle>
              <Box position={"relative"} justifyContent={'end'} display={"flex"}>
              <CustomTextField
                placeholder="Enter your password"
                size="small"
                variant="outlined"
                type={this.state.visiblePassword ? "text" : "password"}
                data-test-id="txtInputPassword"
                fullWidth
                value={this.state.passwordValue}
                name="passwordValue"
                onChange={this.handleInputChange}
                error={!!this.state.errors.password}
                helperText={this.state.errors.password} 
                InputProps={{
                  style: inputStyles,
                }}
                inputProps={{
                  style: inputStyles
                }}
              />
                <Box position={"absolute"} right={"14px"} top={"50%"} style={{ transform: 'translateY(-50%)' }}>
                     <IconButton
                        data-test-id="password-visible"
                        onClick={this.handleChangePasswordVisibility}
                        style={{ marginRight: "-14px", color: '#94A3B8', padding: '10px' }}
                      >
                       {this.state.visiblePassword ? <VisibilityOutlinedIcon /> :<VisibilityOffOutlinedIcon/>} 
                      </IconButton>
              </Box>
              </Box>
            </Box>
            <Box sx={{ mb: 1 }}>
              <TypeTxtStyle>Confirm Password</TypeTxtStyle>
              <Box position={'relative'} display={"flex"} justifyContent={'end'}>
              <CustomTextField 
                InputProps={{
                  style: inputStyles,
                }}
                inputProps={{
                  style: inputStyles
                }}
                placeholder="Confirm your password "
                size="small"
                variant="outlined"
                type={this.state.visibleConfirmPass ? 'text' :'password'}
                data-test-id="txtInputConfirmPassword"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={this.state.confirmPasswordValue}
                name="confirmPasswordValue"
                onChange={this.handleInputChange}
                error={!!this.state.errors.confirmPassword}
                helperText={this.state.errors.confirmPassword} 
                
              />
                <Box position={"absolute"} right={"14px"} >
                <IconButton
                  data-test-id="confirmPassword-visible"
                  onClick={this.handleChangeConfirmPass}
                  style={{ marginRight: "-14px", color: '#94A3B8', padding: '10px' }}
                  >
                {this.state.visibleConfirmPass ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon/>} 
                </IconButton>
              </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <TypeTxtStyle>{configJSON.textOne}</TypeTxtStyle>
              </Box>
              <ValidationText>
                {this.state.validations.hasUpperLower ? (
                  <img src={success} />
                ) : (
                  <img src={error} />
                )}
                <ErrorTxtStyle>{configJSON.textTwo}</ErrorTxtStyle>
              </ValidationText>
              <ValidationText>
                {this.state.validations.hasSpecialChar ? (
                  <img src={success} />
                ) : (
                  <img src={error} />
                )}
                <ErrorTxtStyle>{configJSON.textThree}</ErrorTxtStyle>
              </ValidationText>
              <ValidationText>
                {this.state.validations.hasNumber ? (
                  <img src={success} />
                ) : (
                  <img src={error} />
                )}
                <ErrorTxtStyle>{configJSON.textFour}</ErrorTxtStyle>
              </ValidationText>
              <ValidationText>
                {this.state.validations.minLength ? (
                  <img src={success} />
                ) : (
                  <img src={error} />
                )}
                <ErrorTxtStyle>{configJSON.textFive}</ErrorTxtStyle>
              </ValidationText>
            </Box>
            </InputContainer>
            {this.enableSubmitBtn() ?
            <ResetButton data-test-id="resetBtn" onClick={()=>this.resetPassword()}>{configJSON.resetTxt}</ResetButton>
            :
            <DisabledButton data-test-id="disabledBtn">{configJSON.resetTxt}</DisabledButton>}
          </FormContainer>
          
          <ToastMessage
            open={this.state.showNotification}
            message={this.state.notificationMessage}
            type={this.state.notificationType}
            onClose={this.handleClose}
          />
        </RightWrapper>
      </MainContainer>
      // Customizable Area End
    );
  }
}
