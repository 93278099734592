import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React, { createRef } from 'react';
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  enableVerifyBtn: boolean;
  timer: number;
  otp: string[];
  showNotification: boolean;
  notificationMessage: string;
  notificationType: string;
  invalidOTP: boolean;
  visiblePassword: boolean;
  passwordValue: string;
  confirmPasswordValue: string;
  validations: {
    hasUpperLower: boolean,
    hasSpecialChar: boolean,
    hasNumber: boolean,
    minLength: boolean,
    passwordsMatch: boolean,
  },
  errors: {
    password: string,
    confirmPassword: string,
  },
  visibleConfirmPass: boolean

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  verifyOTPCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  sendValidationCodeAPI: any;
  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  intervalId: NodeJS.Timeout | null = null;
  inputs: React.RefObject<HTMLInputElement>[];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.inputs = Array.from({ length: 6 }, () => createRef());

    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: localStorage.getItem("emailId"),
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      enableVerifyBtn: false,
      timer: 60,
      otp: new Array(6).fill(""),
      showNotification: false,
      notificationMessage:'',
      notificationType: '',
      invalidOTP: false,
      visiblePassword: false,
      passwordValue: "",
      confirmPasswordValue:"",
      validations: {
        hasUpperLower: false,
        hasSpecialChar: false,
        hasNumber: false,
        minLength: false,
        passwordsMatch: false
      },
      errors: {
        password: '',
        confirmPassword: ''
      },
      visibleConfirmPass:false
    };
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData('token')
    const emailId = await getStorageData('emailId')
    super.componentDidMount();
    if(window.location.pathname == "/ForgotPasswordOTP" && token){
      this.setState({
        showNotification: true,
        notificationMessage:'Verification code sent successfully!',
        notificationType: 'success'
      })
      this.handleOpenToast()
      this.startTimer();
    }else if(window.location.pathname == "/ForgotPassword" && emailId){
      return;
    } else if(window.location.pathname == "/NewPassword" && token){
      this.setState({
        showNotification: true,
        notificationMessage:'Code verified successfully',
        notificationType: 'success'
      })
      this.handleOpenToast()
    } 
    //reset password screen
    else { 
      this.goToLogin()
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (this.sendValidationCodeAPI && this.sendValidationCodeAPI === dataMessage) {
        this.handleSendValidationCodeResponse(successMessage);
      } else if (this.verifyOTPCallId && this.verifyOTPCallId === dataMessage) {
        this.handleVerifyOTPResponse(successMessage);
      } else if (this.requestGoToConfirmationCallId && this.requestGoToConfirmationCallId === dataMessage) {
        this.handleGoToConfirmationResponse(successMessage);
      }
    }
  }
  
  handleSendValidationCodeResponse(responseJson: any) {
    if (responseJson === undefined || responseJson.errors) {
      this.setState({
        showNotification: true,
        notificationMessage: responseJson?.errors?.[0]?.otp || 'An error occurred',
        notificationType: responseJson ? 'error' : 'warning'
      });
    } else {
      setStorageData('token', responseJson.meta.token);
      if(window.location.pathname == "/ForgotPasswordOTP"){
        this.setState({
          showNotification: true,
          notificationMessage: 'Verification code sent successfully!',
          notificationType: 'success',
          timer: 60,
          otp: new Array(6).fill(""),
          invalidOTP: false
        }, ()=>{
          this.startTimer();
        });
      } else {
        this.navigateToOTPScreen();
      }
    }
  }
  
  async handleVerifyOTPResponse(responseJson: any) {
    if (!responseJson || responseJson.errors) {
      this.setState({
        invalidOTP: true
      });
    } else {
      await setStorageData('token', responseJson.meta.token);
      this.navigateResetPasswordScreen();
    }
  }
  
  async handleGoToConfirmationResponse(responseJson: any) {
    if (!responseJson || responseJson.errors) {
      this.setState({
        showNotification: true,
        notificationMessage: responseJson?.errors?.password ?? responseJson?.errors?.[0]?.password,
        notificationType: 'error',
      });
    } else {
      localStorage.clear()
      await setStorageData("resetOTP", true)
      this.goToLogin();
    }
  }  

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseEndpoint+'/otp_confirmations'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.state.token,
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    let token = await getStorageData('token');
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseEndpoint+configJSON.resetPasswordApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: token,
      new_password: values.password,
      confirm_password: values.confirmPassword
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area Start
  handleOpenToast = () => {
    this.setState({ showNotification: true});
  };

  handleClose = () => {
    this.setState({ showNotification: false});
  };

  hideEmail(emailValue: string): string {
    const parts = emailValue?.split('@');
    if (parts?.length === 2 && parts[0].length > 1) {
      const hiddenLength = Math.floor(parts[0].length / 2);
      const hiddenPart = '*'.repeat(hiddenLength);
      const visiblePart = parts[0].substring(hiddenLength);
      return hiddenPart + visiblePart + '@' + parts[1];
    }
    return emailValue;
  }  
  
  goBackToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  startTimer = () => {
    this.setState({ timer: 60 });
    this.intervalId = setInterval(() => {
      this.setState(prevState => {
        if (prevState.timer <= 1) {
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
          return { timer: 0 };
        }
        return { timer: prevState.timer - 1 };
      });
    }, 1000);
  };

  sendOTP = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const attrs = {
      email: this.state.emailValue
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendValidationCodeAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseEndpoint+'/otps'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleChange = (element: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const value = element.target.value;
    if (/^\d$/.test(value) || value === "") {
      const otp = [...this.state.otp];
      otp[index] = value;
      this.setState({ otp, invalidOTP: false }, () => {
        const allFieldsHaveValue = this.state.otp.every(value => value.trim() !== '');
        if (allFieldsHaveValue) {
          this.setState({enableVerifyBtn: true})
        } else {
          this.setState({enableVerifyBtn: false})
        }
      });
      if (value && index < 5) {
        this.inputs[index + 1].current?.focus();
      }
    }
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === "Backspace" && !this.state.otp[index] && index > 0) {
      this.inputs[index - 1].current?.focus();
    }
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    this.setState({ [name]: value } as unknown as Pick<S, keyof S>, () => {
      switch (name) {
        case 'passwordValue':
          this.validatePassword(value);
          break;
        case 'confirmPasswordValue':
          this.validateConfirmPassword(value);
          break;
        default:
          break;
      }
    });
  };

  validatePassword = (password: string) => {
    const hasUpperLower = configJSON.hasUpperLower.test(password);
    const hasSpecialChar = configJSON.hasSpecialChar.test(password);
    const hasNumber = configJSON.hasNumber.test(password);
    const minLength = password.length >= 8;
    const passwordsMatch = this.state.confirmPasswordValue === '' ? true : password === this.state.confirmPasswordValue;
    let confirmPasswordError = '';
    if (this.state.confirmPasswordValue !== '') {
      confirmPasswordError = passwordsMatch ? '' : 'Passwords do not match';
    }
    this.setState({
      validations: {
        ...this.state.validations,
        hasUpperLower,
        hasSpecialChar,
        hasNumber,
        minLength,
        passwordsMatch,
      },
      errors: {
        ...this.state.errors,
        password: '',
        confirmPassword: confirmPasswordError,
      },
    });
  };
  

  validateConfirmPassword = (confirmPassword: string) => {
    const passwordsMatch = confirmPassword === this.state.passwordValue;
    const error = passwordsMatch ? '' : 'Passwords do not match';

    this.setState({
      validations: { ...this.state.validations, passwordsMatch },
      errors: { ...this.state.errors, confirmPassword: error },
    });
  };

  async verifyOTP() {
    let token = await getStorageData('token');
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOTPCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseEndpoint+'/otp_confirmations'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token,
      otp_code: this.state.otp.join(''),
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToOTPScreen = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordOTPMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordOTPMessage), "email");
    this.send(msg);
  }

  navigateResetPasswordScreen = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationNewPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationNewPasswordMessage), "email");
    this.send(msg);
  }
  handleChangePasswordVisibility=()=>{
    this.setState({
     visiblePassword:!this.state.visiblePassword
    })
   }
   handleChangeConfirmPass=()=>{
     this.setState({
      visibleConfirmPass:!this.state.visibleConfirmPass
     })
   }

   enableSubmitBtn = () => {
    const { passwordValue, confirmPasswordValue, validations, errors } = this.state;
    if (!passwordValue || !confirmPasswordValue) {
      return false;
    }
    const { hasUpperLower, hasSpecialChar, hasNumber, minLength, passwordsMatch } = validations;
    const allValidationsMet = hasUpperLower && hasSpecialChar && hasNumber && minLength && passwordsMatch;
    const noErrors = Object.values(errors).every(error => !error);
    return allValidationsMet && noErrors;
  };

  resetPassword = () =>{
    this.goToConfirmationAfterPasswordChange({password:this.state.passwordValue, confirmPassword: this.state.confirmPasswordValue})
  }
  // Customizable Area End
}
