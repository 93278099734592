// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Modal,
  InputLabel,
  Checkbox,
  ListItemText,
  IconButton,
  Collapse,
  Select as data,
  Tooltip,
  CardMedia,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import Close from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { capitalLetterMethod } from "../../../components/src/utils";
import {
  rename,
  unCheckIcon,
  checkedIcon,
  success,
  accountDel,
  deactivate,
  image,
} from "./assets";
import { ExpandLess, ExpandMore, ControlPoint } from "@material-ui/icons";
import TeamBuilderController, { Props } from "./TeamBuilderController";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import MessageBox from "../../../components/src/messageBox";
import Select, { components } from "react-select";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #CBD5E1",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #CBD5E1",
        },
      },
    },
  },
});

const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});

const RenameTextField = styled(TextField)({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.5)",
  },
});

export default class TeamBuilder extends TeamBuilderController {
  constructor(props: Props) {
    super(props);
  }
  renderMembersList = () => {
    const { membersList, selectedMembers } = this.state;

    if (!membersList || membersList.length === 0) {
      return (
        <TableRow>
          <TableCell
            colSpan={7}
            style={{ textAlign: "center", fontFamily: "Poppins" }}
          >
            No members found
          </TableCell>
        </TableRow>
      );
    }

    return membersList.map((row, index) => (
      <TableRow key={index}>
        <TableCell style={webStyle.SubDivC}>
          <Checkbox
            checked={selectedMembers.has(row.id)}
            onChange={() => this.handleCheckboxChange(row?.id)}
            data-test-id="checkbox-change-data-id"
            icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }} />}
            checkedIcon={
              <img src={checkedIcon} style={{ width: 16, height: 16 }} />
            }
            style={{ color: "#64748B" }}
          />
        </TableCell>
        <TableCell
          style={{ ...webStyle.SubDivC }}
        >{` ${row.first_name} ${row.last_name}`}</TableCell>
        <TableCell style={{ ...webStyle.SubDivC }}>{row.email}</TableCell>
        {this.renderTeamSection(row)}
        <TableCell style={{ ...webStyle.SubDivC }}>{row.job_title}</TableCell>
        <TableCell
          style={{
            ...webStyle.SubDivC,
            position: "relative",
          }}
        >
          <Typography
            style={{
              height: "20px",
              width: "fit-content",
              borderRadius: "35px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBlock: "2px",
              paddingInline: "8px",
              fontWeight: 700,
              fontSize: "12px",
              fontFamily: "Poppins",
              textTransform: "uppercase",
              top: "50%",
              position: "absolute",
              left: "50%",
              transform: " translate(-50%, -50%)",
              ...this.getRoleStyles(row.role),
            }}
          >
            {row.role}
          </Typography>
        </TableCell>
        <TableCell style={{ ...webStyle.SubDivC }}>
          {row.invited_status === false ? "Not Activated" : "Activated"}
        </TableCell>
        <TableCell
          style={{
            ...webStyle.SubDivC,
          }}
        >
          <Typography
            data-test-id="handleClickMember_id"
            className="hoverEffect"
            onClick={(event) => this.handleClickMember(event, row)}
          >
            <MoreVertIcon style={{ cursor: "pointer" }} />
          </Typography>
          {this.renderPopover()}
        </TableCell>
      </TableRow>
    ));
  };

  renderPopover = () => {
    return (
      <Popover
        style={webStyle.popup}
        open={Boolean(this.state.anchorEl)}
        anchorEl={this.state.anchorEl}
        onClose={this.handleClose}
        data-test-id="handleClose-id"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            padding: "10px",
            width: "213px",
            boxShadow: "0px 4px 8px 0px #00000008",
            marginLeft: "-3.5rem",
            borderRadius: "8px",
          },
        }}
      >
        <MenuItem
          style={{
            ...webStyle.popMenu,
            marginBottom: "10px",
            borderRadius: "8px",
            backgroundColor: this.state.hovered ? "#E0EDF0" : "",
          }}
          data-test-id="moveTOMember-id"
          onClick={() => this.handleMembersEditDetailModal()}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <span style={webStyle.iconM}>
            <EditIcon style={{ fontSize: "16px" }} />
          </span>{" "}
          Edit
        </MenuItem>
        <MenuItem
          style={{
            ...webStyle.popMenu,
            marginBottom: "10px",
            borderRadius: "8px",
            backgroundColor: this.state.teamHovered ? "#E0EDF0" : "",
          }}
          data-test-id="moveTOMember-data-id"
          onClick={() => this.handleMoveMemberToOtherTeam()}
          onMouseEnter={this.handleMouseTeamEnter}
          onMouseLeave={this.handleMouseTeamLeave}
        >
          <span style={webStyle.iconM}>
            <img src={deactivate} alt="rename" />
          </span>{" "}
          Add to Team
        </MenuItem>
        <MenuItem
          style={{
            ...webStyle.deleteIcon,
            color: "red",
            marginBottom: "10px",
            borderRadius: "8px",
            backgroundColor: this.state.deactivateHover ? "#E0EDF0" : "",
          }}
          data-test-id="delete"
          onClick={() => {
            this.handleDeactiveAccount();
          }}
          onMouseEnter={this.handleMouseDeactivateEnter}
          onMouseLeave={this.handleMouseDeactivateLeave}
        >
          <span style={webStyle.iconM}>
            <img src={accountDel} alt="accountDel" />
          </span>
          Deactivate
        </MenuItem>
      </Popover>
    );
  };

  renderTeamSection = (row: any) => {
    return (
      <TableCell style={{ ...webStyle.SubDivC }}>
        {Array.isArray(row?.teams) && row?.teams.length > 0 ? (
          <Tooltip
            title={
              row?.teams.length > 1
                ? (row?.teams as string[]).slice(1).join(", ")
                : ""
            }
            placement="top"
          >
            <span>
              {row?.teams[0]}
              {row?.teams.length > 1 && ","}
              {row.teams?.length > 1 ? (
                <span
                  style={{
                    borderRadius: "50%",
                    padding: "6px 8px",
                    marginLeft: "10px",
                    fontSize: "12px",
                    fontWeight: 600,
                    fontFamily: "poppins",
                    lineHeight: "16px",
                    backgroundColor: "#237182",
                    color: "#FFFFFF",
                  }}
                >
                  {row?.teams.length > 1 && `+${row?.teams.length - 1}`}
                </span>
              ) : (
                <span></span>
              )}
            </span>
          </Tooltip>
        ) : (
          <span>N/A</span>
        )}
      </TableCell>
    );
  };

  renderCreateTeamModal = () => {
    return (
      <Modal
        open={this.state.isCreateTeamModalOpen}
        onClose={this.handleCreateTeamModal}
        style={{ display: "flex" }}
        data-test-id="modal"
      >
        <Box style={webStyle.renameModal}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Create New Team</Typography>
            <Close
              data-test-id="close-data-id"
              onClick={() => this.handleCreateTeamModal()}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
            Enter the name for your team to help organize members effectively.
          </Typography>
          <Box mt="1.5rem">
            <InputLabel style={webStyle.renameInputLabel}>Team name</InputLabel>
            <RenameTextField
              style={{ paddingTop: "6px" }}
              error={this.state.teamNameError || this.state.blankError}
              value={this.state.teamName}
              variant="outlined"
              name="teamName"
              data-test-id="teamName"
              onChange={(e) => this.handleCreateTeamName(e.target.value)}
              fullWidth
              placeholder="Enter team name"
              required
              inputProps={{
                maxLength: 50,
                style: { fontFamily: "'Poppins', sans-serif" },
              }}
              InputProps={{ style: { borderRadius: "8px" } }}
            />
            {this.state.teamNameError && (
              <Typography style={webStyle.errorText}>
                This team name is already in use. Please choose a different
                name.
              </Typography>
            )}
            {this.state.blankError && (
              <Typography style={webStyle.errorText}>
                The team name cannot be blank.
              </Typography>
            )}
          </Box>
          <Box style={webStyle.modalButtons}>
            <RenameButton
              data-test-id="cancelBtn-id"
              style={webStyle.cancelButton}
              onClick={() => this.handleCreateTeamCancelButton()}
            >
              Cancel
            </RenameButton>
            <RenameButton
              disabled={!this.state.teamName}
              data-test-id="create-id"
              onClick={() =>
                this.state.selectedMembers?.size > 0
                  ? this.handleBulkCreateTeamAndAddMember()
                  : this.state.isCreatingNewTeam
                  ? this.handleCreateTeamAndAddMember()
                  : this.handleCreateTeamSubmit()
              }
            >
              {this.state.selectedMembers?.size > 0
                ? "Create & Add Members"
                : this.state.isCreatingNewTeam
                ? "Create & Add Member"
                : "Create"}
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderDeleteTeamModal = () => {
    return (
      <Modal
        open={this.state.isDeleteModalOpen}
        data-test-id="handleDeleteModal"
        onClose={() => this.handleDeleteModal()}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.renameModal}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>
              Are you sure you want to delete team?
            </Typography>
            <Close
              onClick={() => this.handleDeleteModal()}
              style={{ cursor: "pointer" }}
              data-test-id="close-btn"
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
            Deleting {this.state.selectedRow?.name} will permanently remove all
            data from our system.
          </Typography>
          <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              onClick={() => this.handleDeleteModal()}
              data-test-id="cancel-btn"
            >
              Cancel
            </RenameButton>
            <RenameButton
              onClick={this.deleteTeamApi}
              data-test-id="delete-btn"
            >
              Delete
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderRenameTeamModal = () => {
    return (
      <Modal
        open={this.state.isRenameModalOpen}
        onClose={() => this.handleRenameModal()}
        style={{ display: "flex" }}
        data-test-id="rename-modal-id"
      >
        <Box style={webStyle.renameModal as React.CSSProperties}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Rename Team</Typography>
            <Close
              onClick={() => this.handleRenameModal()}
              style={{ cursor: "pointer" }}
              data-test-id="close-modal-id"
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
            Choose a new name for your team to help organize members
            effectively.
          </Typography>
          <Box mt="1.5rem">
            <InputLabel style={webStyle.renameInputLabel}>Team Name</InputLabel>
            <RenameTextField
              error={
                this.state.teamNameRenameError ||
                this.state.teamRenameBlankError
              }
              value={this.state.teamName}
              variant="outlined"
              name="teamName"
              data-test-id="teamName"
              onChange={(e) => this.handleTeamNameChange(e.target.value)}
              fullWidth
              placeholder="Enter team name"
              required
              inputProps={{
                maxLength: 50,
                style: {
                  fontFamily: "'Poppins', sans-serif",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "8px",
                },
              }}
            />
            {this.state.teamNameRenameError && (
              <Typography style={webStyle.errorText}>
                This team name is already in use. Please choose a different
                name.
              </Typography>
            )}
            {this.state.teamRenameBlankError && (
              <Typography style={webStyle.errorText}>
                The team name cannot be blank.
              </Typography>
            )}
          </Box>
          <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              onClick={() => this.handleRenameModal()}
              data-test-id="cancel-modal-id"
            >
              Cancel
            </RenameButton>
            <RenameButton
              disabled={!this.state.teamName}
              onClick={this.editTeamNameApi}
              data-test-id="rename-btn"
            >
              Rename
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderInviteMember = () => {
    return (
      <Modal
        data-test-id="edit-invite-id"
        onClose={() => this.handleEditDetailModal()}
        open={this.state.isEditDetailModalOpen}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.editModal as React.CSSProperties}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Invite Member</Typography>
            <Close
              data-test-id="invite-close-id"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleEditDetailModal()}
            />
          </Box>

          <Box>
            <FirstAndLastNameInputGroup>
              <InputGroup>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#334155",
                      fontSize: "16px",
                    }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    placeholder="Enter first name"
                    size="medium"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={this.state.memberFirstName}
                    name="memberFirstName"
                    data-test-id="inputchange-invite-id"
                    onChange={(e) => {
                      this.handleInputDataChange(e);
                    }}
                    error={!!this.state.memberFirstNameError}
                    InputProps={{
                      style: inputStyles,
                      inputProps: {
                        maxLength: 50,
                        style: placeholderStyles,
                      },
                    }}
                  />
                  {this.state.memberFirstNameError && (
                    <Typography style={webStyle.errorText}>
                      {this.state.memberFirstNameError}
                    </Typography>
                  )}
                </Box>
              </InputGroup>
              <InputGroup>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#334155",
                      fontSize: "16px",
                    }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    placeholder="Enter last name"
                    size="medium"
                    variant="outlined"
                    data-test-id="txtInputLastName"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={this.state.memberLastName}
                    error={!!this.state.memberLastNameError}
                    name="memberLastName"
                    InputProps={{
                      style: inputStyles,
                      inputProps: {
                        maxLength: 50,
                        style: placeholderStyles,
                      },
                    }}
                    onChange={(e) => {
                      this.handleInputDataChange(e);
                    }}
                  />
                  {this.state.memberLastNameError && (
                    <Typography style={webStyle.errorText}>
                      {this.state.memberLastNameError}
                    </Typography>
                  )}
                </Box>
              </InputGroup>
            </FirstAndLastNameInputGroup>
            <InputGroup>
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "#334155",
                    fontSize: "16px",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  placeholder="Enter email"
                  size="medium"
                  variant="outlined"
                  data-test-id="txtInputEmail"
                  fullWidth
                  onChange={(e) => {
                    this.handleInputDataChange(e);
                  }}
                  error={
                    !!this.state.emailFormatError ||
                    !!this.state.memberEmailError ||
                    !!this.state.inviteMemberError
                  }
                  InputLabelProps={{ shrink: true }}
                  value={this.state.memberEmail}
                  name="memberEmail"
                  InputProps={{
                    style: inputStyles,
                    inputProps: {
                      style: placeholderStyles,
                    },
                  }}
                />

                {this.renderErrorMessage()}
              </Box>
            </InputGroup>
            <InputGroup>
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "#334155",
                    fontSize: "16px",
                  }}
                >
                  Job Title
                </Typography>
                <TextField
                  placeholder="Enter job title"
                  size="medium"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  data-test-id="txtInputJobTitle"
                  fullWidth
                  value={this.state.jobTitle}
                  name="jobTitle"
                  onChange={(e) => {
                    this.handleInputDataChange(e);
                  }}
                  error={!!this.state.jobTitleError}
                  InputProps={{
                    style: inputStyles,
                    inputProps: {
                      maxLength: 50,
                      style: placeholderStyles,
                    },
                  }}
                />
                {this.state.jobTitleError && (
                  <Typography style={webStyle.errorText}>
                    {this.state.jobTitleError}
                  </Typography>
                )}
              </Box>
            </InputGroup>
            <InputGroup>
              <Typography
                style={{
                  fontWeight: 600,
                  color: "#334155",
                  fontSize: "16px",
                }}
              >
                Role
              </Typography>
              <RoleDiv>
                <Button
                  style={this.ternarHandler(
                    this.state.role === "owner",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton
                  )}
                  data-test-id="owner-role"
                  onClick={() => {
                    this.handleRoleClick("owner");
                  }}
                >
                  Owner </Button>
                <Button
                  style={
                    this.ternarHandler(
                    this.state.role === "manager",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton
                  )
                }
                  data-test-id="manager-role"
                  onClick={() => {
                    this.handleRoleClick("manager");}
                } >
                  Manager
                </Button>
                <Button
                  style={this.ternarHandler(
                    this.state.role === "employee",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton)
                }
                  data-test-id="employee-role"
                  onClick={() => {
                    this.handleRoleClick("employee");  }
                }
                >
                  Employee
                </Button>
              </RoleDiv>
            </InputGroup>
          </Box>

          <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              data-test-id="isAnotherMember-invite-id"
              onClick={() => {
                this.state.isAddingAnotherMember
                  ? this.handleSkipMember()
                  : this.handleEditDetailModal();
              }}
            >
              {this.state.isAddingAnotherMember ? "Skip" : "Cancel"}
            </RenameButton>

            <RenameButton onClick={this.handleSubmit} data-test-id="invite-btn">
              Add to Invite
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderInvitedMemberList = () => {
    const { member } = this.state;

    return (
      <Modal
        data-test-id="invite-closed-id"
        onClose={() => this.handleInviteMember()}
        open={this.state.isInvitedMember}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.editModal as React.CSSProperties}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Member Details</Typography>
            <Close
              data-test-id="invite-closing-id"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleInviteMember()}
            />
          </Box>

          <MemberCardContainer>
            <Box>
              <div
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  marginBottom: "1rem",
                }}
              >
                {member.length === 0 ? (
                  <Box
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      width: "100%",
                      color: "#475569",
                      textAlign: "center",
                    }}
                  >
                    No Members Present
                  </Box>
                ) : (
                  member.map((item) => {
                    return (
                      <MemberCard key={item?.id}>
                        <MemberCardLeftData>
                          <CardNameLabel>{item.user_name}</CardNameLabel>
                          <CardEmailLabel>{item?.email}</CardEmailLabel>
                          <CardEmailLabel>{item.job_title}</CardEmailLabel>
                        </MemberCardLeftData>
                        <MemberCardRightData>
                          <RightSideLabel>
                            {capitalLetterMethod(item.role_id)}
                          </RightSideLabel>
                          <RightSideRemoveLabel
                            data-test-id="remove_invited_member_id"
                            onClick={() =>
                              this.removeInvitedMemberApiCall(item.id)
                            }
                          >
                            Remove
                          </RightSideRemoveLabel>
                        </MemberCardRightData>
                      </MemberCard>
                    );
                  })
                )}
              </div>
            </Box>

            <PlusGroupContainer>
              <PlusGroup
                onClick={(_) => this.handleAddMoreInviteMember()}
                data-test-id="data-add-more-id"
              >
                <ControlPoint
                  style={{ color: "#237182", fontFamily: "poppins" }}
                />
                <PlusText style={{ fontFamily: "poppins" }}>
                  Invite another member
                </PlusText>
              </PlusGroup>

              <Box style={webStyle.modalInviteButtons}>
                <RenameButton
                  style={webStyle.cancelButton}
                  data-test-id="cancel-invited-id"
                  onClick={() => this.handleInviteMember()}
                >
                  Cancel
                </RenameButton>
                <RenameButton
                  onClick={this.handleAllInvitedMemberSubmit}
                  data-test-id="invite-send-btn"
                >
                  Invite
                </RenameButton>
              </Box>
            </PlusGroupContainer>
          </MemberCardContainer>
        </Box>
      </Modal>
    );
  };

  renderDeactivateModal = () => {
    const selectedCount = this.state.selectedMembers.size;

    return (
      <Modal
        open={this.state.isDeactiveMemberModalOpen}
        data-test-id="handleDeactive-Modal"
        onClose={() => this.handleDeactiveAccount()}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.renameModalDelete}>
          <Box style={webStyle.modalHeaderDelete}>
            <Typography style={webStyle.renameText}>
              {selectedCount !== 0
                ? `Are you sure you want to deactivate these accounts?`
                : ` Are you sure you want to deactivate ${this.state.selectedMember?.first_name} ${this.state.selectedMember?.last_name} accounts?`}
            </Typography>
            <Close
              onClick={() => this.handleDeactiveAccount()}
              style={{ cursor: "pointer" }}
              data-test-id="close-btns"
            />
          </Box>
          {selectedCount > 0 ? (
            <Typography style={{ color: "#1E293B", width: "90%" }}>
              {`Deactivating the accounts for these will restrict their access to the system.`}
            </Typography>
          ) : (
            <Typography style={{ color: "#1E293B", width: "90%" }}>
              {`Deactivating the accounts for ${this.state.selectedMember?.first_name} ${this.state.selectedMember?.last_name} will restrict their access to the system.`}
            </Typography>
          )}
          <Box style={webStyle.modalButtons}>
            <RenameButton
              style={{
                ...webStyle.cancelButton,
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
              }}
              onClick={() => this.handleDeactiveAccount()}
              data-test-id="cancel-btns"
            >
              Cancel
            </RenameButton>
            {selectedCount > 0 ? (
              <RenameButton
                onClick={this.handleBulkDeactivate}
                data-test-id="deactivate-data-id"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Deactivate
              </RenameButton>
            ) : (
              <RenameButton
                onClick={this.handleDeactivateMemberApi}
                data-test-id="deactivate-id"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Deactivate
              </RenameButton>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };

  renderMoveMemberToOtherTeam = () => {
    const {
      selectedMembers,
      isMoveMemberModalOpen,
      teamNameTitle,
      teamData,
      moveMemberError,
    } = this.state;
    const selectedCount = selectedMembers.size;
    const renderErrorMessage = () =>
      moveMemberError && (
        <Box
          color="red"
          mt="0.5rem"
          style={{ fontFamily: "Poppins" }}
          data-test-id="error-message"
        >
          {moveMemberError}
        </Box>
      );

    const handleSelectChange = (selectedOptions: any) => {
      this.setState({
        teamNameTitle: selectedOptions,
      });
    };

    return (
      <Modal
        data-test-id="move-datas-id"
        onClose={this.handleMoveMemberToOtherTeam}
        open={isMoveMemberModalOpen}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.renameModalDelete as React.CSSProperties}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>
              Add Member to Team
            </Typography>
            <Close
              data-test-id="moves-id"
              onClick={this.handleMoveMemberToOtherTeam}
              style={{ cursor: "pointer" }}
            />
          </Box>

          <Typography style={{ color: "#1E293B" }}>
            Select one or more teams to add the member to.
          </Typography>

          <Box mt="1.5rem" position="relative">
            <Box
              style={{
                fontFamily: "Poppins",
                marginBottom: "4px",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "22px",
              }}
            >
              Search Teams
            </Box>

            <Select
              value={teamNameTitle}
              name="team"
              options={teamData.map((team) => ({
                value: team.id,
                label: team.teamName,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding:
                    teamNameTitle && teamNameTitle.length > 0
                      ? "4px 8px"
                      : "11px 8px",
                  border: moveMemberError ? "2px solid red" : "1px solid #ccc",
                  borderRadius: "8px",
                  paddingLeft: "1rem",
                  boxShadow: "none",
                  "&:hover": {
                    border: moveMemberError
                      ? "2px solid red"
                      : "1px solid #ccc",
                  },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  padding: "6px 22px",
                }),
                multiValue: (provided) => ({
                  ...provided,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "26px",
                  background: "#E0EDF0",
                  padding: "6px 12px",
                  fontSize: "14px",
                  color: "#325962",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  marginRight: "5px",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontFamily: "Poppins",
                  paddingLeft: "6px",
                }),
                input: (provided) => ({
                  ...provided,
                  fontFamily: "Poppins",
                  color: "#325962",
                }),
                option: (provided) => ({
                  ...provided,
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#0F172A",
                }),
              }}
              components={{
                ClearIndicator: (props) => (
                  <components.ClearIndicator {...props}>
                    <div style={{ cursor: "pointer", marginLeft: "8px" }}>
                      ✕
                    </div>
                  </components.ClearIndicator>
                ),
                DropdownIndicator: () => null,
              }}
              isClearable
              isMulti
              placeholder="Search for a team..."
              onChange={handleSelectChange}
              onFocus={this.fetchTeamsApi}
              onInputChange={(inputValue) => {
                if (inputValue.length <= 50) {
                  this.handleInpChange(inputValue);
                }
              }}
              data-test-id="seleted-id"
            />

            <SearchIcon
              style={{
                position: "absolute",
                left: "2%",
                top: "70%",
                transform: "translateY(-50%)",
                color: "grey",
              }}
            />
          </Box>

          {renderErrorMessage()}

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              marginTop: "1rem",
            }}
          >
            <Box style={{ fontFamily: "Poppins" }}>or</Box>
            <Box
              style={webStyle.moveText}
              data-test-id="new-createNewTeam__id"
              onClick={() =>
                selectedCount > 0
                  ? this.handleCreateBulkNewTeamAndAddMember()
                  : this.handleCreateNewTeamAndAddMember()
              }
            >
              Create New Team
            </Box>
          </Box>

          {this.renderModalButtons()}
        </Box>
      </Modal>
    );
  };

  renderModalButtons = () => {
    const selectedCount = this.state.selectedMembers.size;
    return (
      <Box style={webStyle.modalButtons}>
        <RenameButton
          style={webStyle.cancelButton}
          data-test-id="move_member_another_team-id"
          onClick={this.handleMoveMemberToOtherTeam}
        >
          Cancel
        </RenameButton>

        <RenameButton
          style={{
            fontSize: "16px",
            backgroundColor: this.state.teamNameTitle ? "#237182" : "#F1F5F9",
            color: this.state.teamNameTitle ? "#FFFFFF" : "#64748B",
          }}
          onClick={
            selectedCount > 0
              ? this.handleMoveBulkMemberSubmit
              : this.handleMoveMemberSubmit
          }
          data-test-id={
            this.state.selectedMembers.size > 0
              ? "move-bulk-member-btn"
              : "move-invite-btn"
          }
          disabled={!this.state.teamNameTitle}
        >
          Add to Team
        </RenameButton>
      </Box>
    );
  };

  renderCreateTeamModal2 = () => {
    return (
      <>
        <Box data-test-id="txtInput" style={webStyle.flex}>
          <Box component="div" style={webStyle.OptionBox}>
            <Box component="div" style={webStyle.singleOptionBox}>
              <Box
                data-test-id="button1"
                onClick={() => this.handleToggle("Teams")}
                component="div"
                style={{
                  ...webStyle.PersonalInformationOption,
                  backgroundColor: this.state.toggle ? "#E0EDF0" : "",
                  color: this.state.toggle ? "#325962" : "#64748B",
                }}
              >
                Teams
              </Box>
              <Box
                data-test-id="button2"
                onClick={() => this.handleToggle("Members")}
                component="div"
                style={{
                  ...webStyle.BusinessInformationOption,
                  backgroundColor: this.state.toggle ? "" : "#E0EDF0",
                  color: this.state.toggle ? "#64748B" : "#325962",
                }}
              >
                Members
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {(this.state.toggle && this.state.rows.length > 0) ||
            !this.state.toggle ? (
              <Typography style={webStyle.teamSize}>
                {this.state.toggle
                  ? `${this.state.totalTeam} Teams`
                  : `${this.state.totalMembers} Members`}
              </Typography>
            ) : null}

            {this.state.toggle ? (
              this.state.rows.length > 0 && (
                <Button
                  data-test-id="btnAddExample"
                  onClick={this.handleCreateTeamModal}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#237182",
                    color: "white",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "10px 16px",
                    textTransform: "capitalize",
                  }}
                >
                  <span style={webStyle.createIcon}>
                    <AddCircleOutlineOutlinedIcon />
                  </span>
                  Create Team
                </Button>
              )
            ) : (
              <Button
                data-test-id="invite-main-id"
                onClick={this.handleEditDetailModal}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#237182",
                  color: "white",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: "16px",
                  borderRadius: "8px",
                  padding: "10px 16px",
                  textTransform: "capitalize",
                }}
              >
                <span style={webStyle.createIcon}>
                  <AddCircleOutlineOutlinedIcon />
                </span>
                Invite Member
              </Button>
            )}
          </Box>
        </Box>
        <Box>
          {this.state.toggle
            ? this.renderTeamsContent()
            : this.renderTableContent()}
        </Box>
      </>
    );
  };

  renderTeamsContent() {
    if (
      this.state.rows.length === 0 &&
      !this.state.searchActive &&
      this.state.noTeamsCreated
    ) {
      return (
        <Box sx={webStyle.cardStyle}>
          <CardMedia component="img" style={webStyle.image} image={image} />
          <Box sx={webStyle.CardContent}>
            <Box
              style={{
                textAlign: "center",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              No Teams Created Yet
            </Box>
            <Box style={{ textAlign: "center", fontFamily: "Poppins" }}>
              Please create a new team to get started.
            </Box>
            <Button
              data-test-id="btnAddExample"
              onClick={this.handleCreateTeamModal}
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#237182",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "16px",
                borderRadius: "8px",
                padding: "10px 16px",
                textTransform: "capitalize",
              }}
            >
              <span style={webStyle.createIcon}>
                <AddCircleOutlineOutlinedIcon />
              </span>
              Create Team
            </Button>
          </Box>
        </Box>
      );
    } else {
      return (
        <>
          {this.renderTableUperDiv()}
          {this.renderBulkFilterOfMember()}
          {this.renderTable()}

          {this.state.toggle ? (
            <Box> {this.renderPagination()}</Box>
          ) : (
            <Box>{this.renderPaginationMembers()}</Box>
          )}
        </>
      );
    }
  }

  renderTableContent() {
    return (
      <>
        {this.renderTableUperDiv()}
        {this.renderBulkFilterOfMember()}
        {this.renderTable()}
        {this.state.toggle ? (
          <Box> {this.renderPagination()}</Box>
        ) : (
          <Box>{this.renderPaginationMembers()}</Box>
        )}
      </>
    );
  }

  renderIconComponent = () => {
    const iconStyle =
      this.state.selectedTeam.length > 0 ? { display: "none" } : { display: "block" };
  
    return this.state.isTeamOpen ? (
      <ExpandLess
        data-test-id="expandLess"
        onClick={() => this.setState({ isTeamOpen: false })}
        style={iconStyle}
      />
    ) : (
      <ExpandMore
        data-test-id="expandMore"
        onClick={() => this.setState({ isTeamOpen: true })}
        style={iconStyle}
      />
    );
  };
  
  renderSelectedTeamsCount = () => {
    return (
      this.state.selectedTeam.length > 0 && (
        <>
          <KeyboardArrowDownIcon style={{ marginLeft: "10px", display: "block" }} />
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#237182",
              color: "white",
              width: "4px",
              height: "4px",
              borderRadius: "50%",
              fontSize: "12px",
              fontWeight: 600,
              marginLeft: "22px",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {this.state.selectedTeam.length}
          </span>
        </>
      )
    );
  };
  
  renderTeamCheckbox = () => {
    return (
      <CustomCheckbox
        data-test-id="team-checkbox"
        open={this.state.isTeamOpen}
        onOpen={() => this.setState({ isTeamOpen: true })}
        onClose={() => this.setState({ isTeamOpen: false })}
        IconComponent={this.renderIconComponent}
        style={{ width: "166px", paddingLeft: "0.5rem" }}
        multiple
        value={this.state.selectedTeam}
        name="team"
        onChange={this.handleChange}
        variant="outlined"
        placeholder="Team"
        displayEmpty
        renderValue={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>Team</span>
            {this.renderSelectedTeamsCount()}
          </div>
        )}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              width: 164,
              maxHeight: this.state.teams.length > 3 ? "400px" : "auto",
              overflowY: this.state.teams.length > 3 ? "auto" : "visible",
              textAlign: "center",
              borderRadius: "8px",
              fontSize: "14px",
              fontFamily: "poppins",
              lineHeight: "22px",
            },
          },
        }}
      >
        {this.state.teams.length > 0 ? (
          this.state.teams.map((team:any) => (
            <CustomListItem key={team} value={team}>
              <Checkbox
                icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }} />}
                checkedIcon={<img src={checkedIcon} style={{ width: 16, height: 16 }} />}
                checked={this.state.selectedTeam.includes(team)}
              />
              <ListItemText
                primary={
                  <span
                    style={{
                      display: "inline-block",
                      maxWidth: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                    }}
                    title={team.length > 15 ? team : ""}
                  >
                    {team.length > 15 ? `${team.slice(0, 15)}...` : team}
                  </span>
                }
              />
            </CustomListItem>
          ))
        ) : (
          <Box>{this.renderNoTeamCheckboxData()}</Box>
        )}
  
        {this.state.teams.length > 0 && (
          <CustomListItem value="clear">
            <ListItemText
              primaryTypographyProps={{ style: webStyle.clearAll }}
              data-test-id="select-team-id"
              onClick={() => this.setState({ selectedTeam: [] })}
            >
              Clear All
            </ListItemText>
          </CustomListItem>
        )}
      </CustomCheckbox>
    );
  };
  

  renderNoTeamCheckboxData  = () =>{
    return(
      <CustomListItem>
      <ListItemText
        primary={
          <span
            style={{
              display: "inline-block",
              maxWidth: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title="No Team Available"
          >
            {"No Team Available".slice(0, 15)}...
          </span>
        }
        style={{ paddingLeft: "1rem" }}
        primaryTypographyProps={{ style: { fontSize: "14px" } }}
      />
    </CustomListItem>
    )
  }

  renderTableUperDiv = () => {
    return (
      <Box style={webStyle.flex}>
        <Box style={webStyle.searchBox}>
          <TextField
            variant="outlined"
            placeholder="Search here..."
            data-test-id="search-input"
            value={this.state.searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    style={{ height: "20px", color: "#94A3B8", width: "20px" }}
                  />
                </InputAdornment>
              ),
              endAdornment: this.state.searchValue && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.clearSearch}
                    data-test-id="clear_search_id"
                    disableRipple
                    style={{ padding: 0, cursor: "pointer" }}
                  >
                    <ClearIcon
                      style={{
                        color: "#94A3B8",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              style: { width: "440px", height: "32px", borderRadius: "8px" },
            }}
            onChange={this.handleInputChange}
            inputProps={{ maxLength: 50 }}
            style={{
              marginRight: "12px",
              borderRadius: "8px",
              backgroundColor: "white",
              height: "32px",
            }}
          />

          {!this.state.toggle && (
            <Box style={
              { display: "flex", gap: "8px" }
              }>
              <CustomCheckbox
                data-test-id="role-checkbox"
                open={this.state.isRoleOpen}
                onOpen={() => this.setState({ isRoleOpen: true })}
                onClose={() => this.setState({ isRoleOpen: false })}
                IconComponent={() => {
                  const iconStyle =
                    this.state.selectedRoles.length > 0 ? 
                      { display:  this.state.selectedRoles.length > 0 ? "none" : "block",  }
                      : { display: "block" 

                      };
                  return this.state.isRoleOpen ?
                   (
                    <ExpandLess
                      data-test-id="expandLess"
                      onClick={() => this.setState(
                        { isRoleOpen: false }
                      )
                    }
                      style={iconStyle}
                    />
                  ) : (
                    <ExpandMore
                      data-test-id="expandMore"
                      onClick={() => this.setState(
                        { isRoleOpen: true }
                      )
                    }
                      style={iconStyle}
                    /> );
                }
              }
                value={this.state.selectedRoles}
                multiple
                name="role"
                variant="outlined"
                placeholder="Role"
                onChange={this.handleChange}
                displayEmpty
                renderValue={() => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Role</span>
                    {
                    this.state.selectedRoles.length > 0 && (
                      <>
                        <KeyboardArrowDownIcon  style={{ marginLeft: "10px", display: "block" }}
                        />
                        <span   style={
                            {
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#237182",
                            color: "white",
                            width: "4px",
                            height: "4px",
                            borderRadius: "50%",
                            fontSize: "12px",
                            fontWeight: 600,
                            padding: "10px",
                            marginLeft: "44px",
                            textAlign: "center",
                          }}
                        >
                          {
                          this.state.selectedRoles.length
                          }
                        </span>
                      </>
                    )
                    }</div>
                )
              }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      width: 164,
                      fontWeight: 400,
                      maxHeight: "400px",
                      overflowY: "auto",
                      fontSize: "14px",
                      borderRadius: "8px",
                      fontFamily: "poppins",
                      lineHeight: "22px",
                      textAlign: "center",   },
                  }, }
              } >
                {["Owner", "Manager", "Employee"].map((role) => 
                (
                  <CustomListItem key={role}
                   value={role}>
                    <Checkbox
                      icon={
                        <img  src={unCheckIcon}  style={{ width: 16, height: 16 }}
                        />  }
                      checkedIcon={
                        <img src={checkedIcon}  style={{ width: 16, height: 16 }}
                        />}
                      checked={this.state.selectedRoles.indexOf(role) > -1}
                      style={
                        { color: "#64748B" }
                    }  />
                    <ListItemText
                      primary={role}
                      primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                  </CustomListItem>
                ))}
                <CustomListItem value="clear">
                  <ListItemText
                    primaryTypographyProps={{
                      style: webStyle.clearAll,
                    }}
                  >
                    Clear All
                  </ListItemText>
                </CustomListItem>
              </CustomCheckbox>

              {this.renderTeamCheckbox()}
             {this.renderJobTitleCheckbox()}
              <CustomCheckbox
                data-test-id="approve-checkbox"
                open={this.state.isApproveOpen}
                onOpen={() => this.setState({ isApproveOpen: true })}
                onClose={() => this.setState({ isApproveOpen: false })}
                IconComponent={() => {
                  const iconStyle =
                    this.state.activated.length > 0
                      ? {
                          display:  this.state.activated.length > 0 ? 
                          "none" :
                           "block",
                        }
                      : { display: 
                        "block"
                       };
                  return this.state.isApproveOpen ?
                   (
                    <ExpandLess
                      data-test-id="expandLess"
                      onClick={() => this.setState({ isApproveOpen: false })}
                      style={iconStyle}/>
                  ) 
                  : 
                  (
                    <ExpandMore
                      data-test-id="expandMore"
                      onClick={
                        () => this.setState({ isApproveOpen: true })
                    }
                      style={iconStyle}
                    />
                  );
                }}
                style={
                  {
                  width: "197px",
                  display: "flex",
                  alignItems: "center",
                }
              }
                multiple
                value={this.state.activated}
                onChange={this.handleChange}
                name="activated"
                variant="outlined"
                placeholder="Activated"
                displayEmpty
                renderValue={() => (
                  <div style={
                    { display: "flex", alignItems: "center" }
                    }>
                    <span>Activated</span>
                    {
                    this.state.activated.length > 0 && (
                      <>
                        <KeyboardArrowDownIcon
                          style={
                            { marginLeft: "10px", display: "block" }
                          } />
                        <span
                          style={
                            {
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#237182",
                            color: "white",
                            width: "4px",
                            textAlign: "center",
                            height: "4px",
                            borderRadius: "50%",
                            fontSize: "12px",
                            fontWeight: 600,
                            marginLeft: "32px",
                            padding: "10px",
                          }
                        }
                        >
                          {this.state.activated.length}
                        </span>
                      </>
                    )}
                  </div>
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      width: 197,
                      maxHeight: "400px",
                      overflowY: "auto",
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      lineHeight: "22px",
                      textAlign: "center",
                      fontWeight: 400,
                    },
                  },
                }}
              >
                {["Activated", "Not Activated"].map((activate) => (
                  <CustomListItem key={activate} value={activate}>
                    <Checkbox
                      icon={
                        <img
                          src={unCheckIcon}
                          style={{ width: 16, height: 16 }}
                        />
                      }
                      checkedIcon={
                        <img
                          src={checkedIcon}
                          style={{ width: 16, height: 16 }}
                        />
                      }
                      checked={this.state.activated.indexOf(activate) > -1}
                    />
                    <ListItemText
                      primary={activate}
                      primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                  </CustomListItem>
                ))}
                <CustomListItem value="clear">
                  <ListItemText
                    primaryTypographyProps={{
                      style: webStyle.clearAll,
                    }}
                  >
                    Clear All
                  </ListItemText>
                </CustomListItem>
              </CustomCheckbox>
            </Box>
          )}
        </Box>

        <Box
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center", gap: "0" }}>
            <Box style={webStyle.sort}>
              {" "}
              <Typography variant="body1" style={{ fontFamily: "Poppins" }}>
                Sort By:
              </Typography>
            </Box>
            <Box>
              <Box style={{ position: "relative", display: "inline-block" }}>
                <select
                  style={{
                    border: "none",
                    color: "#237182",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    backgroundColor: "#f0f5f7",
                    marginBottom: "2px",
                    marginRight: "5px",
                    paddingRight: "30px",
                    appearance: "none",
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                  }}
                  name="sort"
                  value={this.state.selectedSort[0]}
                  onChange={this.handleSortTeamChange}
                  data-test-id="sort-id"
                >
                  <option value="a-z">A-Z</option>
                  <option value="z-a">Z-A</option>
                </select>

                <span
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                    fontSize: "20px",
                    color: "#237182",
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2292 7.50155L9.99583 10.7349L6.7625 7.50155C6.60681 7.34551 6.39543 7.25781 6.175 7.25781C5.95457 7.25781 5.74319 7.34551 5.5875 7.50155C5.2625 7.82655 5.2625 8.35155 5.5875 8.67655L9.4125 12.5016C9.7375 12.8266 10.2625 12.8266 10.5875 12.5016L14.4125 8.67655C14.7375 8.35155 14.7375 7.82655 14.4125 7.50155C14.0875 7.18488 13.5542 7.17655 13.2292 7.50155Z"
                      fill="#237182"
                    />
                  </svg>
                </span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  renderJobTitleCheckbox = () =>{
    return (
      <CustomCheckbox
      data-test-id="job-title-checkbox"
      open={this.state.isJobTitle}
      onOpen={() => this.setState({ isJobTitle: true })}
      onClose={() => this.setState({ isJobTitle: false })}
      IconComponent={() => {
        const iconStyle =
          this.state.selectedJobTitles.length > 0
            ? {
                display:
                  this.state.selectedJobTitles.length > 0
                    ? "none"
                    : "block",
              }
            : { display: "block" };
        return this.state.isJobTitle ? (
          <ExpandLess
            data-test-id="expandLess"
            onClick={() => this.setState({ isJobTitle: false })}
            style={iconStyle}
          />
        ) : (
          <ExpandMore
            data-test-id="expandMore"
            onClick={() => this.setState({ isJobTitle: true })}
            style={iconStyle}
          />
        );
      }}
      style={{
        width: "126px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      multiple
      value={this.state.selectedJobTitles}
      renderValue={() => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Job Title</span>
          {this.state.selectedJobTitles.length > 0 && (
            <>
              <KeyboardArrowDownIcon
                style={{ marginLeft: "10px", display: "block" }}
              />
              <span
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#237182",
                  color: "white",
                  width: "4px",
                  height: "4px",
                  textAlign: "center",
                  borderRadius: "50%",
                  fontSize: "12px",
                  padding: "10px",
                  fontWeight: 600,
                  marginLeft: "10px",
                }
              }
              >
                {
                this.state.selectedJobTitles.length
                }
              </span>   </>
          )
          }
        </div>
      )
    }
      name="job"
      onChange={this.handleChange}
      variant="outlined"
      placeholder="Job Title"
      displayEmpty
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        PaperProps: {
          style: {
            width: 164,
            borderRadius: "8px",
            fontSize: "14px",
            fontFamily: "poppins",
            lineHeight: "22px",
            textAlign: "center",
            fontWeight: 400,
            maxHeight: "400px",
            overflowY: "auto", },
        },
      }
    }  >
      {this.state.jobTitles.length === 0 ? 
      (
        <CustomListItem value="no-titles">
          <ListItemText
            primary={ <span
                style={
                  {
                  display: "inline-block",
                  maxWidth: "calc(100% - 24px)",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "210px",
                  padding: "0rem 1rem",
                }
              }
                title="No job titles available"
              >
                {
                "No job titles available".slice(0, 25)
                }...
              </span>
            }
            />
        </CustomListItem>
      ) 
      : (
        this.state.jobTitles.map((job: string) =>
           (
          <CustomListItem key={job} 
          value={job}>
            <Checkbox icon={
                <img src={unCheckIcon}
                  style={{ width: 16, height: 16 }}
                />
              }
             checkedIcon={
                <img src={checkedIcon}
                  style={{ width: 16, height: 16 }}
                />
              }
              checked={
                this.state.selectedJobTitles.includes(job)
              }/>
            <ListItemText
              primary={
                <span
                  style={
                    {
                    display: "inline-block",
                    width: "calc(100% - 24px)",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }
                }
                  title={job} >
                  {job}
                </span>
              }
              primaryTypographyProps={{ style: { fontSize: "14px" } }}
            />
          </CustomListItem>
        ))
      )}

      {/* Clear all option */}
      {this.state.jobTitles.length > 0 && (
        <CustomListItem value="clear">
          <ListItemText
            primaryTypographyProps={{
              style: webStyle.clearAll,
            }}
            onClick={() => this.setState({ selectedJobTitles: [] })}
          >
            Clear All
          </ListItemText>
        </CustomListItem>
      )}
    </CustomCheckbox>

    )
  }

  renderTableBody = () => {
    const { rows } = this.state;

    if (rows.length === 0) {
      return (
        <TableRow>
          <TableCell
            colSpan={12}
            style={{ textAlign: "center", fontFamily: "Poppins" }}
          >
            No Teams found
          </TableCell>
        </TableRow>
      );
    }

    return rows.map((row) => (
      <TableRow key={row.id}>
        <TableCell
          style={{ cursor: "pointer" }}
          onClick={() => this.handleEditTeam(row)}
          data-test-id="edit-row-data-id"
        >
          <Typography style={webStyle.SubBoxy}>{row.name}</Typography>
        </TableCell>
        <TableCell style={webStyle.TableHeaderCenter}>
          <Typography
            style={{ ...webStyle.SubBoxy, cursor: "pointer" }}
            data-test-id="member-row-data-id"
            onClick={() => this.handleEditTeam(row)}
          >
            {row.members}
          </Typography>
        </TableCell>
        <TableCell style={webStyle.TableHeaderCenter}>
          <Typography
            style={{ ...webStyle.SubBoxy, cursor: "pointer" }}
            data-test-id="dateTime-row-data-id"
            onClick={() => this.handleEditTeam(row)}
          >
            {row.dataTime}
          </Typography>
        </TableCell>
        <TableCell style={{ display: "flex", justifyContent: "end" }}>
          <Typography
            data-test-id="icon"
            className="hoverEffect"
            onClick={(event) => this.handleClick(event, row)}
          >
            <MoreVertIcon style={{ cursor: "pointer", color: "#334155" }} />
          </Typography>
          <Popover
            style={webStyle.popup}
            data-test-id="popover__test"
            open={Boolean(this.state.anchorEl)}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: {
                padding: "10px",
                width: "213px",
                boxShadow: "0px 4px 8px 0px #00000008",
                marginLeft: "-3.5rem",
                borderRadius: "8px",
              },
            }}
          >
            <MenuItem
              style={{
                ...webStyle.popMenu,
                marginBottom: "10px",
              }}
              data-test-id="rename"
              onClick={() => this.handleRenameModal()}
            >
              <span style={webStyle.iconM}>
                <img src={rename} alt="rename" />
              </span>{" "}
              Rename Team
            </MenuItem>
            <MenuItem
              style={webStyle.deleteIcon}
              data-test-id="delete"
              onClick={this.handleDeleteModal}
            >
              <span style={webStyle.iconM}>
                <DeleteIcon />
              </span>{" "}
              Delete
            </MenuItem>
          </Popover>
        </TableCell>
      </TableRow>
    ));
  };

  renderTable = () => {
    return (
      <Box style={webStyle.mt10}>
        <TableContainer style={webStyle.SubTableBox}>
          {this.state.toggle ? (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={webStyle.TableTitleTexts}>
                      Team Name
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.TableTitleTexts,
                        ...webStyle.TableHeaderCenter,
                      }}
                    >
                      Members
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.TableTitleTexts,
                        ...webStyle.TableHeaderCenter,
                      }}
                    >
                      Date Created
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>{this.renderTableBody()}</TableBody>
              </Table>
            </>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                       data-test-id="selecting-data-id"
                      checked={this.state.selectAll}
                      onChange={this.handleSelectAllChange}
                      icon={
                        <img
                          src={unCheckIcon} style={{ width: 16, height: 16 }} />
                      }
                      checkedIcon={  <img
                          src={checkedIcon}
                          style={{ width: 16, height: 16 }}
                        />}
                      style={
                        { color: "#64748B" }
                      }
                    />
                  </TableCell>
                  <TableCell style={webStyle.TableTitleText}>Name</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Email</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Teams</TableCell>
                  <TableCell style={webStyle.TableTitleText}>
                    Job Title
                  </TableCell>
                  <TableCell
                    style={{ ...webStyle.TableTitleText, textAlign: "center" }}
                  >
                    Role
                  </TableCell>
                  <TableCell style={webStyle.TableTitleText}>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.renderMembersList()}</TableBody>
            </Table>
          )}
        </TableContainer>
      </Box>
    );
  };

  generatePageNumbers = (
    currentPage: number,
    totalPages: number,
    maxPageButtons: number
  ) => {
    if (totalPages <= maxPageButtons) 
      {
      return Array.from(
        { length: totalPages }, (_, i) => i + 1)
      ;
    }

    if (currentPage <= 2) 
      {
      return [1, 2, "...", totalPages]; }

    if (currentPage >= totalPages - 1)
       {
      return [1, "...", totalPages - 1, totalPages]; }

    return [
      1,  "...", currentPage - 1, currentPage,  currentPage + 1,  "...",  totalPages,
    ];
  };

  renderPagination = () => {
    const { rowsPerPage, currentPage, totalRecords } = this.state;
    const totalPages = Math.ceil(totalRecords / rowsPerPage);
    const maxPageButtons = 8;

    if (totalRecords <= 10) 
      return null;

    const pageNumbers = this.generatePageNumbers(  currentPage,  totalPages, maxPageButtons
    );

    const handleClick = (page: any) => 
      {
      if (page !== "...") 
        this.setPage(page);};

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return (
      <Box sx={webStyle.footer}>
        <Box sx={webStyle.footer2}>
          <Box style={webStyle.footer3}>
            <ChevronLeftIcon
              style=
              {
    {
                ...webStyle.footerIcon,
                width: "24px",
                height: "24px",
                color: isFirstPage ? "#ccc" : "inherit",
                cursor: isFirstPage ? "default" : "pointer",
              }
            }
              data-test-id="pagination-click-id"
              onClick={
                !isFirstPage ?
                 this.handlePreviousPage : 
                 undefined}
            />
            {
            pageNumbers.map((page, index) =>
               (
              <Box key={index}
               sx={{ marginLeft: "8px", marginRight: "8px" }}>
                <Typography
                  style={
                    {
                    ...webStyle.footerFont,
                    cursor: page === "..." ?
                     "default" : 
                     "pointer",
                    fontWeight: page === currentPage ? 
                    "bold" : "normal",
                  }}
                  onClick={
                    () => handleClick(page)
                  }
                >
                  {page}
                </Typography>
              </Box>
            ))}
            <ChevronRightIcon
              style={{
                ...webStyle.footerIcon,
                width: "24px",
                height: "24px",
                color: isLastPage ? "#ccc" : "inherit",
                cursor: isLastPage ? "default" : "pointer",
              }}
              onClick={!isLastPage ? this.handleNextPage : undefined}
            />
          </Box>
          <Typography style={webStyle.footer3Style}>
            {`${(currentPage - 1) * rowsPerPage + 1} -
             ${Math.min(
               currentPage * rowsPerPage,
               currentPage * 10
             )} of ${totalRecords} results`}{" "}
          </Typography>
        </Box>
      </Box>
    );
  };

  generateMembersPageNumbers = (
    currentPage: number,
    totalPages: number,
    maxPageButtons: number
  ) => {
    if (totalPages <= maxPageButtons)
      return Array.from({ length: totalPages }, (_, i) => i + 1);

    if (currentPage <= 2) return [1, 2, "...", totalPages];
    if (currentPage >= totalPages - 1)
      return [1, "...", totalPages - 1, totalPages];

    return [
      1,
      "...",
      currentPage - 1,
      currentPage,
      currentPage + 1,
      "...",
      totalPages,
    ];
  };

  renderPaginationMembers = () => {
    const { rowsPerPage, currentPage, totalMembers } = this.state;
    const totalPages = Math.ceil(totalMembers / rowsPerPage);
    const maxPageButtons = 8;

    if (totalMembers <= rowsPerPage) return null;

    const pageNumbers = this.generateMembersPageNumbers(
      currentPage,
      totalPages,
      maxPageButtons
    );

    const handleClickData = (page: any) => {
      if (page !== "...") {
        this.setMembersPage(page);
      }
    };

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return (
      <Box sx={webStyle.footer}>
        <Box sx={webStyle.footer2}>
          <Box style={webStyle.footer3}>
            <ChevronLeftIcon
              style={{
                ...webStyle.footerIcon,
                width: "24px",
                height: "24px",
                color: isFirstPage ? "#ccc" : "inherit",
                cursor: isFirstPage ? "default" : "pointer",
              }}
              onClick={!isFirstPage ? this.handlePreviousMemberPage : undefined}
            />
            {pageNumbers.map((page, index) => (
              <Box
                key={index}
                style={{ marginLeft: "8px", marginRight: "8px" }}
              >
                <Typography
                  style={{
                    ...webStyle.footerFont,
                    cursor: page === "..." ? "default" : "pointer",
                    fontWeight: page === currentPage ? "bold" : "normal",
                  }}
                  data-test-id="handle-member-pagination-id"
                  onClick={() => handleClickData(page)}
                >
                  {page}
                </Typography>
              </Box>
            ))}
            <ChevronRightIcon
              style={{
                ...webStyle.footerIcon,
                width: "24px",
                height: "24px",
                color: isFirstPage ? "#ccc" : "inherit",
                cursor: isFirstPage ? "default" : "pointer",
              }}
              onClick={!isLastPage ? this.handleNextMemberPage : undefined}
            />
          </Box>

          <Typography component="span" style={webStyle.footer3Style}>
            {`${(currentPage - 1) * rowsPerPage + 1} - ${Math.min(
              currentPage * rowsPerPage,
              currentPage * 10
            )} of ${totalMembers} members`}
          </Typography>
        </Box>
      </Box>
    );
  };

  renderErrorMessage() {
    const { memberEmailError, emailFormatError, inviteMemberError } =
      this.state;
    if (memberEmailError) {
      return (
        <Typography style={webStyle.errorText}>{memberEmailError}</Typography>
      );
    }

    if (emailFormatError) {
      return (
        <Typography style={webStyle.errorText}>{emailFormatError}</Typography>
      );
    }

    if (inviteMemberError) {
      return (
        <Typography style={webStyle.errorText}>{inviteMemberError}</Typography>
      );
    }

    return null;
  }

  renderBulkFilterOfMember = () => {
    const { selectedMembers } = this.state;
    const selectedCount = selectedMembers.size;

    return (
      selectedCount > 0 && (
        <Box style={webStyle.outerBox}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "24px",
            }}
          >
            <Typography
              variant="body2"
              style={{
                fontFamily: "poppins",
                fontWeight: 400,
                fontSize: "14px",
                color: "#475569",
              }}
            >
              {`${selectedCount} selected`}
            </Typography>
            <Box display="flex" alignItems="center" style={{ gap: "24px" }}>
              <Button
                variant="text"
                startIcon={<AddCircleOutlineRoundedIcon />}
                onClick={this.handleAddToTeamSelected}
                data-test-id="bulk-select-id"
                style={{
                  fontFamily: "poppins",
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#237182",
                  textTransform: "none",
                  padding: "0px",
                }}
              >
                Add to Team
              </Button>
              <Button
                variant="text"
                onClick={this.handleDeactivateSelected}
                data-test-id="deactivate-select-id"
                style={{
                  fontFamily: "poppins",
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#237182",
                  textTransform: "none",
                  padding: "0px",
                }}
              >
                Deactivate
              </Button>
            </Box>
          </Box>
          <IconButton
            size="small"
            onClick={this.clearSelection}
            data-test-id="selection-id"
          >
            x
          </IconButton>
        </Box>
      )
    );
  };

  renderEditMembersDetailsModal = () => {
    return (
      <Modal
        data-test-id="edit-data-id"
        onClose={() => this.handleMembersEditDetailModal()}
        open={this.state.isEditMembersDetailModalOpen}
        style={{ display: "flex" }}
      >
        <Box style={webStyle.editModal as React.CSSProperties}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Member Details</Typography>
            <Close
              data-test-id="modeling"
              onClick={() => this.handleMembersEditDetailModal()}
              style={{ cursor: "pointer" }}
            />
          </Box>

          <Box>
            <FirstAndLastNameInputGroup>
              <InputGroup>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#334155",
                      fontSize: "16px",
                    }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    placeholder="Enter first name"
                    size="medium"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={this.state.memberDataFirstName}
                    name="memberDataFirstName"
                    data-test-id="inputchange"
                    onChange={this.handleMembersInputDataChange}
                    error={!!this.state.memberFirstNameError}
                    InputProps={{
                      style: inputStyles,
                      inputProps: { maxLength: 50, style: placeholderStyles },
                    }}
                  />
                  {this.state.memberFirstNameError && (
                    <Typography style={webStyle.errorText}>
                      The First Name cannot be blank.
                    </Typography>
                  )}
                </Box>
              </InputGroup>
              <InputGroup>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#334155",
                      fontSize: "16px",
                    }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    placeholder="Enter last name"
                    size="medium"
                    variant="outlined"
                    data-test-id="txtInputLastName"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={this.state.memberDataLastName}
                    name="memberDataLastName"
                    onChange={this.handleMembersInputDataChange}
                    error={!!this.state.memberLastNameError}
                    InputProps={{
                      style: inputStyles,
                      inputProps: { maxLength: 50, style: placeholderStyles },
                    }}
                  />
                  {this.state.memberLastNameError && (
                    <Typography style={webStyle.errorText}>
                      The Last Name cannot be blank.
                    </Typography>
                  )}
                </Box>
              </InputGroup>
            </FirstAndLastNameInputGroup>
            <InputGroup>
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "#334155",
                    fontSize: "16px",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  placeholder="Enter email"
                  size="medium"
                  variant="outlined"
                  data-test-id="txtInputEmail"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={this.state.memberDataEmail}
                  name="memberDataEmail"
                  onChange={this.handleMembersInputDataChange}
                  error={
                    !!this.state.emailFormatError ||
                    !!this.state.memberEmailError ||
                    !!this.state.inviteMemberError
                  }
                  InputProps={{
                    style: inputStyles,
                    inputProps: { maxLength: 50, style: placeholderStyles },
                  }}
                />
                {this.renderErrorMessage()}
              </Box>
            </InputGroup>
            <InputGroup>
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "#334155",
                    fontSize: "16px",
                  }}
                >
                  Job Title
                </Typography>
                <TextField
                  placeholder="Enter job title"
                  size="medium"
                  variant="outlined"
                  data-test-id="txtInputJobTitle"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={this.state.MemberJobTitle}
                  name="MemberJobTitle"
                  onChange={this.handleMembersInputDataChange}
                  error={!!this.state.jobTitleError}
                  InputProps={{
                    style: inputStyles,
                    inputProps: { maxLength: 50, style: placeholderStyles },
                  }}
                />
                {this.state.jobTitleError && (
                  <Typography style={webStyle.errorText}>
                    The Job Title cannot be blank.
                  </Typography>
                )}
              </Box>
            </InputGroup>
            <InputGroup>
              <Typography
                style={{ color: "#334155", fontSize: "16px", fontWeight: 600 }}
              >
                Role
              </Typography>
              <RoleDiv>
                <Button
                  style={this.ternarHandler(
                    this.state.role === "owner",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton
                  )}
                  data-test-id="owner-role"
                  onClick={() => this.handleRoleClick("owner")}
                >
                  Owner
                </Button>
                <Button
                  style={this.ternarHandler(
                    this.state.role === "manager",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton
                  )}
                  data-test-id="manager-role"
                  onClick={() => this.handleRoleClick("manager")}
                >
                  Manager
                </Button>
                <Button
                  style={this.ternarHandler(
                    this.state.role === "employee",
                    webStyles.RoleSelectedButton,
                    webStyles.RoleButton
                  )}
                  data-test-id="employee-role"
                  onClick={() => this.handleRoleClick("employee")}
                >
                  Employee
                </Button>
              </RoleDiv>
            </InputGroup>
          </Box>

          <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              data-test-id="edit-id"
              onClick={() => this.handleMembersEditDetailModal()}
            >
              Cancel
            </RenameButton>
            <RenameButton
              onClick={this.handlMembersEditDetailsSubmit}
              data-test-id="edit-btn"
            >
              Save
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box style={{ background: "#f0f6f7", height: "100%" }}>
          <MyProfileHeader
            navigation={this.props.navigation}
            tabName="Users&Teams"
          >
            {this.state.messageToggle && (
              <MessageBox
                message={this.state.tostifymessage}
                status={this.state.status}
              />
            )}

            <Loader loading={this.state.loading} />
            {this.renderCreateTeamModal2()}
            {this.renderCreateTeamModal()}

            {this.renderRenameTeamModal()}

            {this.renderDeleteTeamModal()}
            {this.renderInviteMember()}
            {this.renderInvitedMemberList()}
            {this.renderDeactivateModal()}
            {this.renderMoveMemberToOtherTeam()}
            {this.renderEditMembersDetailsModal()}
            <CustomToast icon={<img src={success} />} />
          </MyProfileHeader>
        </Box>
      </ThemeProvider>
    );
  }
}

const AddMemberButton = styled(Button)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  backgroundColor: "#237182",
  borderRadius: "8px",
  padding: "16px",
  border: "none",
  width: "100%",
  textTransform: "capitalize",
  fontFamily: "Poppins",
  "&:disabled": {
    backgroundColor: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    backgroundColor: "#237182",
  },
});

const PlusGroupContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "16px",
});

const PlusGroup = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
});

const inputStyles = {
  borderRadius: "8px",
};
const placeholderStyles = {
  fontFamily: "Poppins, sans-serif",
};

const PlusText = styled("div")({
  color: "#237182",
  fontWeight: 700,
  fontSize: "18px",
});

const MemberCard = styled("div")({
  width: "100%",
  backgroundColor: "#FFFFFF",
  borderLeft: "4px solid #237182",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  marginBlock: "1rem",
  boxShadow: "0px 2px 8px 0px #00000014",
});
const MemberCardContainer = styled("div")({
  width: "100%",
  marginTop: "28px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@media(max-width:1245px)": {},
});

const MembersCollapse = styled(Collapse)({
  overflowX: "hidden" as "hidden",
  overflowY: "auto" as "auto",
  maxHeight: "50vh",
  "@media(max-width:1245px)": {
    maxHeight: "40vh",
  },
});

const AddMemberContainer = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "14px 0px",
  cursor: "pointer",
});
const MemberCardLeftData = styled("div")({
  width: "80%",
  padding: "16px 0px 16px 24px",
});
const MemberCardRightData = styled("div")({
  width: "20%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
});
const CardNameLabel = styled("div")({
  fontSize: "16px",
  fontWeight: 600,
  color: "#0F172A",
  paddingBottom: "6px",
  fontFamily: "Poppins",
});
const CardEmailLabel = styled("div")({
  fontSize: "14px",
  fontWeight: 400,
  color: "#475569",
  marginTop: "6px",
  fontFamily: "Poppins",
});
const RightSideLabel = styled("div")({
  color: "#325962",
  backgroundColor: "#E0EDF0",
  fontSize: "12px",
  fontWeight: 400,
  padding: "4px 8px",
  borderRadius: "16px",
  fontFamily: "poppins",
});
const RightSideRemoveLabel = styled("div")({
  color: "#DC2626",
  fontSize: "12px",
  fontWeight: 600,
  marginRight: "4px",
  cursor: "pointer",
  fontFamily: "poppins",
});

const CustomListItem = styled(MenuItem)({
  "&.Mui-selected": {
    backgroundColor: "transparent",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#E0EDF0",
  },
  "&:hover": {
    backgroundColor: "#E0EDF0",
  },
  "&.MuiMenuItem-root": {
    paddingLeft: "0px",
    paddingRight: "8px",
  },
});

const CustomCheckbox = styled(data)({
  borderRadius: "8px",
  height: "32px",
  minWidth: "166px",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  "& .MuiSvgIcon-root": {
    marginRight: "7px",
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-input": {
    padding: "7.5px 14px",
  },
});

const UpdatedBox = styled(Box)({
  "& .hoverEffect": {
    padding: "3px",
    "&:hover": {
      backgroundColor: "#D3E4E9",
      borderRadius: "50%",
    },
  },
});

const CustomToast = styled(ToastContainer)({
  width: "40vw",
  marginTop: "80px",
  "& .Toastify__toast": {
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
  },
  "& .Toastify__toast-body": {
    fontFamily: "Poppins",
    color: "#0F172A",
    padding: 0,
  },
});

const FirstAndLastNameInputGroup = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
  marginTop: "32px",
});

const InputGroup = styled("div")({
  marginBottom: "1rem",
  width: "100%",
});

const RoleDiv = styled("div")({
  backgroundColor: "#F8FAFC",
  borderRadius: "50px",
  width: "100%",
  display: "flex",
  padding: "4px",
  justifyContent: "space-between",
});

const webStyles = {
  finishTextStyle: {
    width: "240px",
  },
  RoleButton: {
    color: "#64748B",
    fontSize: "16px",
    borderRadius: "50px",
    justifyContent: "center",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    fontWeight: 600,
    padding: "10px 12px 10px 12px",
    display: "flex",
    alignItems: "center",
    width: "27%",
  },

  RoleSelectedButton: {
    color: "#325962",
    backgroundColor: "#E0EDF0",
    fontSize: "16px",
    fontWeight: 600,
    border: "1px solid #D3E4E9",
    borderRadius: "50px",
    padding: "10px 12px 10px 12px",
    display: "flex",
    alignItems: "center",
    width: "33%",
    justifyContent: "center",
    textTransform: "capitalize",
    fontFamily: "Poppins",
  },
  modalTitle: {
    fontWeight: 700,
    lineHeight: "2rem",
    fontSize: "1.5rem",
  },
  closeIcon: {
    color: "#0F172A",
    cursor: "pointer",
  },
  expandIcon: {
    color: "#237182",
  },
  paymentItemTitle: {
    lineHeight: "1.75rem",
    marginBottom: "1.5rem",
    fontSize: "1.25rem",
    fontWeight: 700,
  },

  selectedPlan: {
    border: "2px solid #237182",
  },
  planType: {
    fontSize: "0.875rem",
  },
  discountLabel: {
    display: "inline-block",
    position: "absolute" as "absolute",

    backgroundColor: "#b3d4db",
    clipPath: "polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%)",
    color: "white",
    fontSize: "0.875rem",
    fontWeight: 700,
    fontFamily: "Poppins, sans-serif",
    textAlign: "center" as "center",
    padding: "8px",

    right: "12px",
    top: 0,
  },

  priceNumber: {
    fontSize: "1.875rem",
    fontWeight: 700,
    lineHeight: "2.5rem",
  },
};

const webStyle = {
  outerBox: {
    borderRadius: "5px",
    padding: "0px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1.625rem 40px 0px 40px",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  OptionBox: {
    display: "flex",
    justifyContent: "start",
  },
  singleOptionBox: {
    display: "flex",
    backgroundColor: "#F8FAFC",
    borderRadius: "50px",
    padding: "4px",
  },
  personInfoDetailBox: {
    display: "flex",
    gap: "24px",
  },
  PersonalInformationOption: {
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    padding: "10px 12px 10px 12px",
    cursor: "pointer",
    fontSize: "16px",
  },

  BusinessInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    cursor: "pointer",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "27px",
    margin: "0px",
    fontSize: "16px",
    ...(window.innerWidth < 600 &&
      ({
        textAlign: "center",
      } as React.CSSProperties)),
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 40px 0px 40px",
    marginTop: "32px",
    backgroundColor: "#f0f5f7",
  },
  moveText: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#237182",
    lineHeight: "26px",
    fontFamily: "Poppins",
    cursor: "pointer",
  },

  InputSearchMove: {
    position: "relative",
    display: "flex",
    justfiyContent: "space-between",
    alignItems: "left",
    flexDirection: "column",
    gap: "4px",
  },
  renameModalDelete: {
    margin: "auto",
    width: "584px",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  modalHeaderDelete: {
    display: "flex",
    color: "#0F172A",
    marginBottom: "1rem",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  createTeamBtn: {
    backgroundColor: "#237182",
    color: "white",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    textTransform: "capitalize",
  },
  searchBox: {
    display: "flex",
  },

  sort: {
    marginRight: "3px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#334155",
  },
  image: {
    width: "350px",
    height: "350px",
  },
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5rem",
  },
  mt10: {
    marginTop: "20px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  teamSize: {
    marginRight: "15px",
    color: "#475569",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  SubTableBox: {
    borderRadius: "16px ",
    padding: "0px",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "0px",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "#FFFFFF",
  },
  TableTitleTexts: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#475569",
    fontFamily: "Poppins",
    lineHeight: "22px",
    width: "33%",
  },
  TableTitleText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#475569",
    fontFamily: "Poppins",
    lineHeight: "22px",
    width: "18%",
  },
  SubDivC: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px",
  },
  TableHeaderCenter: {
    textAlign: "center" as "center",
  },
  TableContentBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  SubBoxy: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    color: "#1E293B",
  },
  SubDivA: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "start",
  },

  SubUserImg: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
  },
  deleteIcon: {
    color: "#DC2626",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  SubDivI: {
    fontFamily: "Poppins",
    padding: "2px 8px",
    fontWeight: 400,
    fontSize: "14px",
    borderRadius: "40px",
  },

  iconM: {
    marginRight: "10px",
    fontSize: "10px",
  },
  popMenu: {
    marginBottom: "10px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#0F172A",
  },
  popup: {
    padding: "20px !important",
    marginLeft: "-5px !important",
  },
  createIcon: {
    marginRight: "10px",
  },
  footer: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: "20px 40px",
    boxShadow: 1,
  },
  footer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerIcon: {
    width: "20px",
    height: "20px",
  },
  footerFont: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 700,
    color: "#237182",
  },
  footer3: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    marginLeft: "35px",
    color: "#94A3B8",
  },
  footer3Style: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    color: "#64748B",
  },

  renameModal: {
    margin: "auto",
    width: "40vw",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameText: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  renameInputLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
    color: "#334155",
    lineHeight: "1.375rem",
  },
  errorText: {
    fontSize: "0.75rem",
    color: "#DC2626",
    lineHeight: "1rem",
    fontFamily: "Poppins",
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "2rem",
  },
  modalInviteButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
  },
  CardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1rem",
  },
  cancelButton: {
    background: "#E0EDF0",
    color: "#325962",
    fontSize: "16px",
  },
  clearAll: {
    textAlign: "center" as "center",
    color: "#237182",
    fontWeight: 600,
  },
  editModal: {
    margin: "auto",
    width: "600px",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
};

// Customizable Area End
