import React from "react";

// Customizable Area Start
import {
  Box,
  Input,
  Typography,
  Button,
  Menu,
  MenuItem,
  Modal,
  Select as MUISelect,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,


} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";

import ChatController2, { Props2 } from "./ChatController2";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { Close, Search,MoreVert } from "@material-ui/icons";
import {leave_icon,add_member,cancle, delete_icon,reply_icon,pinpoint_icon,profile, chatOptions,attachment_icon,chatGroupNotFoundImg, chatGroupNotFoundTxt, chatNotFoundImg,
    lockIcon ,SearchIcon1,pin,channalname_info_icon } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import Select, { components } from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";






const MainContainer =  styled(Box)({
   width:'100%',
   height:'95%',
   display:'flex',
});

// Customizable Area End


export default class Chat2 extends ChatController2 {
  constructor(props: Props2) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleProjectList = ()=>(
    this.state.projectList?.length > 0 ? 
             <Box>
              {
                this.state.projectList.map((project:any,index:number)=>(
                     <Box key={project.attributes.id}>
                      <Box
                    style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '3px', marginTop: '12px' }}
                 >
                    {project.attributes.project_name !== null && <><Box style={webStyles.projectMainBox}>
                      <Box
                         data-test-id={`project-name-${index}`}
                        onClick={() => this.handleProjectId(project.attributes.id)}
                        style={project.attributes.id === this.state.projectId ? webStyles.projectBoxTextActive : webStyles.projectBoxText}>
                        {project.attributes.project_name.split(" ")[0][0]}
                        {project.attributes.project_name.split(" ").length > 1 && project.attributes.project_name.split(" ")[1][0]}
                      </Box>
                      {project.attributes.project_name}
                    </Box>
                      <div style={{ paddingTop: '8px',cursor:'pointer' }}>
                      <MoreVert style={{width:'28px',height:'28px',color:'#475569'}} />
                      </div></>}
                  </Box>
                  <Box>
                      {project.attributes.id === this.state.projectId &&
                        <Box width={'100%'}>
                          <Box 
                          data-test-id={`project-categories-${index}`}
                          onMouseEnter={()=>this.setState({external:true}) }
                          onMouseLeave={()=>this.setState({external:false})}
                           style={this.state.external ? webStyles.projectInternal :webStyles.projectExternal}>
                            External
                             <Box style={webStyles.ext}>
                              EXT.
                             </Box>
                             </Box>
                          <Box 
                          data-test-id={`project-categories2-${index}`}
                           onMouseEnter={()=>this.setState({internal:true}) }
                           onMouseLeave={()=>this.setState({internal:false}) }
                          style={this.state.internal ? webStyles.projectInternal :webStyles.projectExternal}>Internal  <img src={lockIcon} alt="" width={'18px'} height={'18px'} /></Box>
                        </Box>}
                    </Box>
                    </Box>
                     
                ))
              }
             </Box>
                :
              <Box>
              <img src={chatNotFoundImg} alt="" width={'312px'} height={'312px'} />
                <Box style={webStyles.chatGroupNotFound}>
                <p style={webStyles.noMessage}>
                No Project Chats yet
                </p>
                <p
                style={webStyles.noMessageSub}
                >
                You haven't created or joined any projects. Start by creating a new project or joining an existing one.
                </p>

               </Box>
             </Box>
  )
  chatHeader = ()=>{
    return(
      <ChatHeader>
      <Box>
        <Box style={{display:"flex", alignItems: "center"}}>
        <Typography className="channel_name">Channel Name</Typography>
              <img src={channalname_info_icon} alt="" width={'22px'} height={'22px'} onClick={this.openRightSection}/>
        </Box>
        <Typography className="total_member">10 members</Typography>
      </Box>
      <Box className="header_icon_wrapper">
      <Box style={{marginRight:"20px"}}> <img src={SearchIcon1} alt="" width={'34px'} height={'34px'} /></Box>
      <img src={pin} alt="" width={'34px'} height={'34px'} />
      </Box>
     </ChatHeader>
    )
  }
  mainChat = ()=>{
    return(
      <MainchatMsg >
        {
       this.state.dummyArray.map((project:any,index:number)=>(
         <Box style={{padding:"20px 0px",maxWidth:"600px"}}>
           <Box  style={{display:"flex", alignItems: "center" ,justifyContent: "space-between"}}>
             <Box style={{display:"flex", alignItems: "center"}}>
              <img src={profile} alt="" width={'32px'} height={'32px'} style={{borderRadius: "20px", marginRight:"10px" }}   />
               <Typography className="rece-user">Tom Wilson</Typography>
                <Typography className="rece-msg_time">2:30 PM</Typography>
             </Box>
           <img src={chatOptions} alt="" width={'28px'} height={'28px'} onClick={this.handleSpeciChange}/>
           <SpecificationDropDown 
                anchorEl={this.state.anchorElTSpeci}
                keepMounted
                open={this.state.anchorElTSpeci}
                elevation={0}
                onClose={this.handleClose3}
                autoFocus={false}
                   >
           <MenuItem onClick={this.handleClose3} className=" menuItem">
           <Box fontStyle={{display:"flex",alignItems: "center",width:"100%",padding:"4px 10px"}}>
           <img src={reply_icon} alt="" width={'18px'} height={'18px'} /> Reply
           </Box>
           </MenuItem>
                     <MenuItem onClick={this.handleClose3} className=" menuItem">
           <Box fontStyle={{display:"flex",alignItems: "center",width:"100%",padding:"4px 10px"}}>
           <img src={pinpoint_icon} alt="" width={'18px'} height={'18px'} /> Pin to Channel
            </Box>
           </MenuItem>
            <MenuItem onClick={this.handleClose3} className="deleteitem">
           <Box fontStyle={{display:"flex",alignItems: "center",width:"100%",padding:"4px 10px"}}>
              <img src={delete_icon} alt="" width={'18px'} height={'18px'} />Delete Message
           </Box>
          </MenuItem>
               </SpecificationDropDown>
          </Box>
           <Typography className="receive_msg">Morning, team. Quick updates on Cambridge Riverside. Emma, design updates?</Typography>
        </Box>
                ))
        }
      </MainchatMsg>

    )

  }
  InputSendMSG = ()=>{
    return(
      <Box style={{ padding:"11px 20px", display:"flex", position:"relative", background:"white"}}>
      <Input
           endAdornment={<Search style={webStyles.searchIcon} />}
           style={webStyles.sendmsgInput}
           placeholder="Type a message here..."
           disableUnderline
           data-test-id="Type a message here..."
         />
          <Box style={{
             position: "absolute",
             right: "106px",
             background: "white",
             top: "15px",
          }}> 

              <img src={attachment_icon} alt="" width={'34px'} height={'34px'} 
               />
          </Box>
         

             <RenameButton
              data-test-id="cancelBtn-id"
              style={webStyles.sendButton}
            >
              Send
            </RenameButton>
      </Box>

    )

  }
  renderSelectInput() {
    return (
      <>
        <Box mt="1.5rem" position="relative"  style={webStyles.InputSearch as any} >
          <style>
            {`
              .basic-multi-select {
                width: 100%;
              }
            `}
          </style>
          <Select
            value={this.state.selectedUsers}
            isMulti
            name="members"
            options={this.filterUsers(
              this.state.users,
              this.state.selectedUsers
            )}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={{
              control: (provided: any, state: any) => {
                return {
                  ...provided,
                  display: "flex",
                  flexWrap: "wrap",
                  padding:
                    this.state.selectedUsers.length > 0 ? "0.3rem" : "0.9rem",
                  borderRadius: "8px",
                  paddingLeft: "1rem",
                  boxShadow: "none",
                  
                };
              },
              multiValue: (provided: any) => ({
                ...provided,
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "26px",
                background: "#E0EDF0",
                padding: "6px 12px",
                fontSize: "14px",
                color: "#325962",
                fontFamily: "Poppins",
                fontWeight: 400,
              }),
              input: (provided) => ({
                ...provided,
                margin: 0,
                padding: "0 6px",
                width: "auto",
                flexGrow: 1,
              }),
              placeholder: (provided) => ({
                ...provided,
                fontFamily: "Poppins",
                paddingLeft: "6px",
              }),
            }}
            components={{
              DropdownIndicator: () => null,
              ClearIndicator: () => null,
            }}
            placeholder="Search and add members"
            menuIsOpen={false}
            onChange={(selectedOptions) => {
              this.setState({
                selectedUsers: selectedOptions || [],
              });
            }}
            onInputChange={(inputValue: string | any[]) => {
              if (inputValue.length <= 50) {
                this.handleInputChange(inputValue)
              }
            }}
            inputValue={this.state.inputName}
            data-test-id="select-id"
          />
         

          <SearchIcon
            style={{
              position: "absolute",
              left: "1%",
              top: "54%",
              transform: "translateY(-50%)",
              color: "grey",
            }}
          /> 
          <RenameButton
            data-test-id="create-id"
            style={{ marginLeft: "16px" ,
              padding: "1rem",
              borderRadius: "8px",
              fontFamily: "Poppins",
              textTransform: "none",
              background: "#237182",
              color: "#FFF",
              fontWeight: 600,
              fontSize: "16px",
            }
          }
            onClick={this.handleAddMemberData}
          >
            Add
          </RenameButton> 
        </Box>
       
      </>
    );
  }


  renderMemberList() {
    const { users, selectedUsers, inputName } = this.state;
    const filteredUsers = this.filterAvailableUsers(
      users,
      selectedUsers,
      inputName
    );
    return (
      <Box style={{ maxHeight: "350px", overflowY: "auto", marginTop: "1rem" }}>
        {filteredUsers.length === 0 ? (
          <Typography
            style={{ color: "#1E293B", fontSize: "16px", textAlign: "center" }}
          >
            No members present
          </Typography>
        ) : (
          <List>
            {filteredUsers?.map((user:any) => (
              <ListItem
                key={user.id}
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemAvatar>
                  <Avatar style={{ background: "#D3E4E9", color: "#ABCDD5" }}>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: 700,
                        paddingTop: "4px",
                      }}
                    >
                      {user?.first_name.slice(0, 2).toUpperCase()}
                    </span>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      style={webStyles.ListItemName}
                    >
                      {user?.first_name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" style={webStyles.ListItemEmail}>
                      {user?.email}
                    </Typography>
                  }
                />
                <IconButton
                  data-test-id="memberAdd-id"
                  onClick={this.handleMemberAdd.bind(this, user)}
                  style={{ color: "#000", opacity: 1 }}
                >
                  <AddIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    );
  }

  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
       
        <ThemeProvider theme={theme}>
          <MyProfileHeader navigation={this.props.navigation} tabName="Chat">
          <MainContainer>
          <Box style={webStyles.projectChatBar}> 
             <Box style={{ display:'flex',flexDirection:'column',gap:'16px'}}>
             <p style={webStyles.projectText}>Project Chats</p>
             <Input
                  startAdornment={<Search style={webStyles.searchIcon} />}
                  style={webStyles.searchInput}
                  placeholder="Search Project Chat"
                  onChange={(e) => this.handleSearchInputChange(e.target.value)}
                  value={this.state.searchInputText}
                  disableUnderline
                  data-test-id="search"
                />
             </Box>
             {this.handleProjectList()}
          </Box>
         {
             this.state.projectList?.length > 0 ? 
             <Box style={webStyles.middleScreen}>
              <Box style={{display:"flex",width:"100%"}}>
              <Box style={{width:"100%"}}>
              {   this.chatHeader() }
              { this.mainChat() }
              { this.InputSendMSG() }
               </Box>
               {this.state.isOpenRightSection?
              <RightSection >
                <Box className="right_header"> 
                <img src={cancle} alt="" width={'28px'} height={'28px'} onClick={  this.cancle}/>
                  
                  Channel Info</Box>
                <Box className="ADD_channale_member">
                  <Typography className="channal_name">Internal</Typography>
                     <Typography className="channal_member_no">10 members 
                    <img src={add_member} alt="" width={'28px'} height={'28px'} onClick={this.OpenADDmemberModel}/>
                 </Typography>
                  <Typography className="channal_leave"> 
                   <img src={leave_icon} alt="" width={'28px'} height={'28px'} />
                    Leave Chat</Typography>
                </Box>
                <Tab >

      <Box
        onClick={this.handleClick.bind(this, 'member')}
        className={this.state.active === 'member' ? 'active' : 'deactive'}
      >
        Members
      </Box>
      <Box
        onClick={this.handleClick.bind(this, 'media')}
        className={this.state.active === 'media' ? 'active' : 'deactive'}
      >
        Media
      </Box>
      <Box
        onClick={this.handleClick.bind(this, 'files')}
        className={this.state.active === 'files' ? 'active' : 'deactive'}
      >
        Files
      </Box>

    </Tab>
              </RightSection>:""}
              </Box>
            </Box>
            :
                     <Box style={webStyles.projectChatSideScreen}>
                       <Box>
                      <p>
                      <img src={chatGroupNotFoundImg} alt="" width={'328px'} height={'309px'} />
                      </p>
                       <img src={chatGroupNotFoundTxt} alt="" width={'312px'} height={'76px'} />
                       </Box>
                     </Box>  
         }
          </MainContainer>
          </MyProfileHeader>
          <Modal open={this.state.isAddMemberModalOpen} style={{ display: "flex" }}>
        <Box style={webStyles.renameModal}>
          <Box style={webStyles.modalHeader}>
            <Typography style={webStyles.renameText}>
            Search and Add Member
            </Typography>
            <Close
              data-test-id="close-data-id"
              onClick={this.handleAddMemberModal}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
            Add team members by entering their email addresses below.
          </Typography>
          {this.renderSelectInput()}
          {this.renderMemberList()}
          
        </Box>
      </Modal>
         
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  ListItemName: {
    fontSize: "16px",
    fontFamily: "poppins",
    lineHeight: "24px",
    fontWeight: "400",
  },
  ListItemEmail: {
    fontSize: "14px",
    fontFamily: "poppins",
    lineHeight: "22px",
    fontWeight: "400",
  },
  InputSearch: {
    position: "relative",
    display: "flex",
    justfiyContent: "space-between",
    alignItems: "center",
  },
  renameText: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameModal: {
    margin: "auto",
    height: "fit-content",
    padding: "2.5rem 2rem",
    width: "40vw",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  projectChatBar:{
    width:'22%',
    height:'100%',
    padding:'16px 12px',
    backgroundColor:'white',
    borderRight: "1px solid #E2E8F0",
  },
  projectChatSideScreen:{
    width:'100%',
    height:'100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  searchInput: {
    border: "1px solid #C4CBD4",
    background: "#FCFDFE",
    width:'100%',
    fontWeight:400,
    fontSize:'16px',
    padding:'3px 8px',
    borderRadius:'8px',
    color:'#94A3B8'
  },
  searchIcon: {
    color: "#DADADA",
    width: "24px",
    height: "24px",
    marginRight: "0.5rem",
  },
  projectText:{
    fontSize:'24px',
    fontWeight:700,
    fontFamily:'Poppins',
    padding:0,
    margin:0,
    color:'#0F172A'
  },
  projectBoxTextActive:{
    width:'44px',
    height:'44px'
    ,borderRadius:'8px',
    backgroundColor:'#237182',
   display:'flex',
   justifyContent:'center',
   alignItems:'center',
  color:'#FFFFFF',
  fontSize:'18px',
  fontWeight:700,
 fontFamily:'Poppins',
 cursor:'pointer',
 textTransform:"uppercase" as "uppercase"
},
projectBoxText:{
  width:'44px',
  height:'44px'
  ,borderRadius:'8px',
  backgroundColor:'#D3E4E9',
 display:'flex',
 justifyContent:'center',
 alignItems:'center',
color:'#ABCDD5',
fontSize:'18px',
fontWeight:700,
fontFamily:'Poppins',
cursor:'pointer',
textTransform:"uppercase" as "uppercase"
}
,
projectMainBox:{
  width:'100%',
  display:'flex',
  gap:'16px',
  alignItems:'center',
  fontSize:'14px',
  fontWeight:600,
  fontFamily:"Poppins",
  textTransform:'capitalize' as 'capitalize',
  color:'#0F172A'
},
projectExternal:{
 borderRadius:'8px',
 padding: '6px 8px 6px 54px',
 fontWeight:600,
 fontSize:'14px',
 fontFamily:'Poppins',
 color:'#0F172A',
 display:'flex',
 alignItems:'center',
 gap:'5px',cursor:'pointer'

},
projectInternal:{
  borderRadius:'8px',
  padding: '6px 8px 6px 54px',
  fontWeight:600,
  fontSize:'14px',
  fontFamily:'Poppins',
  color:'#0F172A',
  backgroundColor:'#F0F5F7',
   display:'flex',
 alignItems:'center',
 gap:'5px',cursor:'pointer'
},
middleScreen:{
  width:'100%',
},
sendButton: {
  background: "#E0EDF0",
  color: "#325962",
  fontSize: "16px",
  height:"44px",
  marginLeft:"10px",
},
sendmsgInput: {
  border: "1px solid #C4CBD4",
  background: "#FCFDFE",
  width:'100%',
  fontWeight:400,
  fontSize:'16px',
  borderRadius:'8px',
  color:'#94A3B8',
  padding:"4px 0px 0px 5px"
  
},
ext:{backgroundColor:'#237182',color:'#FFFFFF',
  borderTopLeftRadius: '16px', 
  borderTopRightRadius: '16px', 
  borderBottomLeftRadius: '16px',  
  borderBottomRightRadius: '16px',
  padding:'0px 8px 1px 8px',
 fontSize:'10px',
 fontWeight:700,
 fontFamily:'Poppins'},
 
 chatGroupNotFound:{
   width:'312px',
   height:'76px',
   display:'flex',
   flexDirection:'column' as 'column',
   gap:'8px'
 },
 noMessage:{
   textAlign: 'center' as 'left' | 'right' | 'center' | 'justify',
   fontFamily:'Poppins',
   fontWeight:600,
   fontSize:'16px',
   lineHeight:'24px',
   margin:0,
   padding:0,
   color:'#1E293B'
 },
 noMessageSub:{
 color:'#475569',
 fontFamily:'Poppins',
 fontSize:'14px',
 fontWeight:400,
 lineHeight:'22px',
 margin:0,
 padding:0,
  textAlign: 'center' as 'left' | 'right' | 'center' | 'justify'
 
 }
};
const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});
const ChatHeader = styled(Box)({
  height:"42px",
  display:'flex',
  justifyContent:'space-between',
  padding:"20px",
  background:"#FFFFFF",
  "& .channel_name":{
fontFamily: "Poppins",
fontSize: "20px",
fontWeight: 700,
lineHeight: "28px",
  },
  "& .total_member":{
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
      },

      "& .header_icon_wrapper":{
        display:'flex',
      }

})
const MainchatMsg = styled(Box)({
height:"49vh",
padding:"0 20px",
overflow:"scroll",
"& .receive_msg":{
  paddingLeft:"44px",
  maxWidth: "600px",
  fontFamily: "Poppins",
fontSize: "16px",
fontWeight: 400,
lineHeight: "24px",
  
},
"& .rece-user":{
fontFamily: "Poppins",
fontSize: "16px",
fontWeight: 600,
lineHeight: "24px",
},
"& .rece-msg_time":{
  fontFamily: "Poppins",
fontSize: "14px",
fontWeight: 400,
lineHeight: "22px",
color:"#94A3B8",
paddingLeft:"6px"
}


});
const SpecificationDropDown = styled(Menu)({
  "& .MuiMenu-paper":{
     width:'192px',
     borderRadius:"10px",
    },
    "& .MuiMenu-list":{
    display : "flex",
    flexDirection:"column",
    alignItems: "start",
    padding:"10px 10px"
  },
  "& .css-1nx3shm-MuiButtonBase-root-MuiMenuItem-root:hover":{
    background:"#E2E8F0"
  },
  "& .MuiMenuItem-root":{
    width:"100%"
  },
  "& .deleteitem":{
    color:"red",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  "& .menuItem":{
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    borderRadius:"5px"
  }

});
const RightSection = styled(Box)({
  width:"318px",
  padding:"18px 0",
  background:"#FFFFFF",

  "& .right_header":{
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    display: "flex",
    alignItems: "flex-start",
    gap:"8px"
  },
  "& .ADD_channale_member":{
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
   
  },
  "& .channal_name":{
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    marginTop:"10px",

  },
  "& .channal_member_no":{
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    margin:"10px 0",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .channal_leave":{
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color:"#DC2626",
    display: "flex",
    alignItems: "center",
    gap: "9px",
  }
})
const Tab = styled(Box)({
  padding: "6px",
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  margin: "20px",
  background: "#F3F7FB",
  borderRadius: "50px",
  color:"#E2E8F0",

 
    "& .active":{
    background: "#D3E4E9",
      color:"#325962",
      borderRadius:"18px",
    padding: "6px 12px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    border: "1px solid rgb(183, 198, 202)",
  
    },
    "& .deactive":{
      color:"#64748B",
      borderRadius:"18px",
    padding: "6px 12px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    border: "1px solid rgba(183, 198, 202, 0)",

    }
  

})




// Customizable Area End
