import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { Button, Grid,Box, Typography } from "@mui/material";
import { document,expandDownArrow } from "./assets";
import MyProfileHeader from "../../../components/src/MyProfileHeader";

// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MyProfileHeader showSidebar={true} >
        <Grid container sx={styles.gridCont}>
          <Grid item md={4} xs={12} sx={styles.gridItem} gap={5}>
          <Box sx={styles.flexBox}>
            <Typography style={styles.titleText}>{configJSON.roomCostBreakdown}</Typography>
            <img src={document} alt="document" style={styles.iconImage} />
          </Box>
          <Box sx={styles.relativeBox}>
            <Box
              sx={styles.totalCostCont}
            >
              <Typography style={styles.costText}>{configJSON.totalCost}</Typography>
              <Typography component="span" style={styles.costAmountText}>
                {configJSON.currency}967,675.67
              </Typography>
            </Box>
            <Box sx={styles.pieRoomCont}>
              <ResponsiveContainer height={190} width={160}>
                <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }} style={styles.pieChartContainer}>
                  <Pie
                    data={configJSON.data}
                    cx={80}
                    cy={100}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={1}
                    cornerRadius={4}
                    dataKey="value"
                  >
                    {configJSON.data.map((entry: string, index: number) => (
                      <Cell key={`cell-${index}`} fill={configJSON.COLORS[index % configJSON.COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <Box sx={styles.dataBox}>
                {configJSON.data.slice(0,5).map((item: {name: string, value: number}, index: number) => (
                  <Box key={item.name} sx={styles.dataItemBox}>
                    <Box style={styles.roomColorCont}>
                     <Box sx={{...styles.colorCont,  bgcolor: configJSON.COLORS[index],}}></Box>
                     <Typography style={styles.itemNameText}>{item.name}</Typography>
                    </Box>
                    <Typography style={styles.itemValueText}>
                      {configJSON.currency}
                      {item.value}
                    </Typography>
                  </Box>
                ))}
                <Button sx={styles.viewMoreBtn}
                 endIcon={<img src={expandDownArrow} alt='expand arrow' width={7.45} height={4.39}/>}>
                  {configJSON.viewMoreBtn}

                </Button>
              </Box>
            </Box>
          </Box>
          </Grid>
        </Grid>
      </MyProfileHeader>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

const styles = {
  gridCont: {marginLeft: '6.5rem', padding: '30px 50px 0px 50px' },
  pieRoomCont:{ display: 'flex', justifyContent: 'space-between', width: '100%' },
  colorCont: {borderRadius: 50, width: 12, height: 12},
  roomColorCont: {display: 'flex', gap: 5, alignItems: 'center'},
  viewMoreBtn: {width: '87px',padding: 0, whiteSpace: 'nowrap', textTransform: 'none',backgroundColor: 'none',fontSize: '12px', color: '#237182', fontWeight: 600, fontFamily: 'Poppins','&:hover': {backgroundColor: 'none'}},
  totalCostCont: {
    position: 'absolute',
    left: '30px',
    top: '85px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px'
  },
  gridItem: {
    padding: '16px',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxSizing: 'border-box',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleText: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#64748B',
  },
  iconImage: {
    width: '20px',
    height: '20px',
  },
  relativeBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 1
  },
  costText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#1E293B',
  },
  costAmountText: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    color: '#237182',
  },
  pieChartContainer: {
    width: '399px',
    height: '300px',
  },
  dataBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: 1
  },
  dataItemBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '25px',
  },
  itemNameText: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#1E293B',
  },
  itemValueText: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    lineHeight: '16px',
    color: '#334155',
  },
};
// Customizable Area End
