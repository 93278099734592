// Customizable Area Start
import React from "react";

import {
  Typography,
  Box,
  Input,
  Select,
  Grid,
  MenuItem,
  styled,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  Collapse,
  FormControl,
  InputLabel,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Add,
  ArrowBackIos,
  Check,
  ControlPointDuplicateSharp,
  DeleteOutline,
  ExpandMore,
  PersonAddOutlined,
  Remove,
  Search,
} from "@material-ui/icons";
import CreateEditProjectPortfolioController, {
  Props,
} from "./CreateEditProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { blackDownArrowIcon, calendarIcon, dollarIcon, downArrowIcon, frameIcon, rightArrowIcon, roundPlusIcon, threeDotIcon, upArrowIcon } from "./assets";
import Calendar from 'react-calendar';
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 800,
    },
  },
});

const SortInput = styled(Select)({
  color: "#237182",
  fontWeight: 600,
  fontFamily: "Poppins",
  marginLeft: "0.5rem",
  "& .MuiSelect-select.MuiSelect-select": {
    padding: "2px",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "#F0F5F7",
  },
  "& .MuiSelect-icon": {
    color: "#237182",
  },
});

const CustomStepper = styled(Stepper)({
  backgroundColor: "#F0F5F7",
  fontFamily: "Poppins, sans-serif",
  position: "absolute",
  left: 0,
  right: 0,
  width: "35vw",
  margin: "0 auto",
  padding: "0",
  "& .MuiStepConnector-line": {
    borderColor: "#94A3B8",
    borderTopWidth: "2px",
  },
  "& .MuiStepConnector-active .MuiStepConnector-line": {
    borderColor: "#0F172A",
  },
});

const ActiveStepLabel = styled(StepLabel)({
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    color: "black",
    fontSize:'14px',
    fontWeight:600,
    fontFamily:'Poppins',
  },
  "& .css-4ff9q7.Mui-active":{
    color:'black',
    fontSize:'14px',fontWeight:600,
    fontFamily:'Poppins',
  },
  "& .css-2fdkz6.Mui-active":{
    marginLeft:'5px',
    fontSize:'14px',
      fontWeight:600,
      fontFamily:'Poppins'
  },
  "& .css-d1rwnr .css-4ff9q7.Mui-active":{
    fontSize:'24px',
    fontWeight:600,
    fontFamily:'Poppins'
  }
  ,

  "& .MuiStepIcon-root.MuiStepIcon-completed": {
    color: "#34D399",
  },
  "& .MuiStepIcon-root": {
    color: "#94A3B8",
    fontWeight: 600,
  },
  "& .MuiStepIcon-text": {
    fontSize:'14px', fontWeight:600, fontFamily:'Poppins'
  },
  "& .MuiStepLabel-iconContainer": {
    paddingRight: 0,
  },
  "& .MuiTypography-root": {
    paddingLeft: "0.5rem",
    fontSize: "0.875rem",
    color: "#0F172A",
    fontWeight: 600,
  },
 "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
    color: 'black',
  },
  "& .css-1hv8oq8-MuiStepLabel-label.Mui-active":{
       marginLeft:'5px',
       fontSize:'14px',
      fontWeight:600,
      fontFamily:'Poppins'
  },
});

const BackButtonBox = styled(Box)({
  display: "flex",
  gap: "0.5rem",
  alignItems: "center",
  cursor: "pointer",
  fontWeight: 600,
  fontSize:'14px',
  fontFamily:'Poppins',
  "& p": {
    color: "#334155",
    fontWeight: 600,
    fontSize:'14px',
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1rem",
    color: "#334155",
  },
  "& .css-ahj2mt-MuiTypography-root":{
    fontFamily:'Poppins',
  }
});

const Popup = styled(Menu)({

});

const TableField = styled(Table)({
   "& .css-177gid-MuiTableCell-root":{
    padding:'0px'
   }
});

const CalendarStyle = styled(Box)({
    position:'absolute' as 'absolute',
    width:'300px',
    zIndex:1000,
    top:'46px',
    left:'43px',
    borderRadius:'8px',
    padding:'16px',
    backgroundColor:'white',
    "& .react-calendar": {
      border: "none",
      fontFamily: "Urbanist",
    },
    "& .react-calendar__navigation button.react-calendar__navigation__label": {
        width:'24px',
        height:'24px',
        backgroundColor:'transparent',
        border:'none',
        color:'black',
        fontSize: '14px',
        fontFamily: "Urbanist",
        fontWeight: 600,
        padding:'12px'
    },
    "& .react-calendar__navigation": {
      display:'flex',
      gap:'10px'
   },
    "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button":{
      display: "none",
    },
    "& .react-calendar__navigation button": {
      fontSize: '30px',
      color: "#94A3B8",
      backgroundColor:'transparent' ,
       border:'none',
    },
    "& .react-calendar__tile.react-calendar__tile--now": {
      background: 'none',
      color: '#0F172A',
    },
    "& .react-calendar__month-view__weekdays__weekday": {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#64748B',
      fontWeight: 400,
      textDecoration: 'none',
      backgroundColor:'transparent'
    },
    '& .react-calendar__month-view__weekdays':{
        padding:'8px'
    },
    "& .react-calendar__month-view__weekdays__weekday abbr": {
      textDecoration: 'none',
    },
    "& .react-calendar__tile": {
      fontSize: '14px',
      fontWeight: 400,
      color: '#0F172A',
      width: '50px',
      height: '50px',
      backgroundColor:'transparent',
      border:'none'
    } ,
    "& .react-calendar__tile.react-calendar__tile--range": {
      background: '#E0EDF0',
      color: '#325962',
    },
    "& .react-calendar__tile.react-calendar__tile--rangeStart": {
      background: '#ABCDD5',
      color: '#FFFFFF',
    },
    "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
      background: '#ABCDD5',
      color: '#FFFFFF',
    },
    "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
      fontSize: '14px',
      fontWeight: 700,
      borderRadius:'100%'
    },
    "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
      background: '#F1F5F9',
      color: '#044352',
      pointerEvents: "none",
  },
    "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
      color: '#94A3B8'
    }, 
});

const NextButton = styled(Button)({
  background: "#237182",
  padding: "10px 16px",
  borderRadius: "8px",
  color: "#FFF",
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "1rem",
  textTransform: "none",
  "&:disabled": {
    background: "none",
    color: "#64748B",
  },
  "&:hover": {
    backgroundColor: "#237182",
  },
});
export default class ProjectPortfolioShells extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  this.getShellsData = this.getShellsData.bind(this);
  this.getRoomsData = this.getRoomsData.bind(this)
  }

  componentDidMount(): any {
    this.setState({ activeStep: 1 });
    this.getShellsData();
    this.getRoomsData();
  }

  CustomStepIcon = (props: any) => {
    const { active, completed } = props;
    const getBackgroundColor = () => {
      if (completed) {
        return "#34D399";
      } else if (active) {
        return "#0F172A";
      } else {
        return "#94A3B8";
      }
    };

    const getColor = () => {
      return active || completed ? "#fff" : "#94A3B8";
    };

    const styles = {
      color: getColor(),
      backgroundColor: getBackgroundColor(),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "2rem",
      height: "2rem",
      fontFamily: "Poppins, sans-serif",
    };

    return (
      <div style={styles}>
        {completed ? (
          <Check style={{ color: "#fff", fontSize: 20 }} />
        ) : (
          <StepIcon {...props} />
        )}
      </div>
    );
  };

  render() {
    const toggle = this.state.toggle
    const { anchorEl , anchorElT } = this.state;
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorElT);
    return (
      <ThemeProvider theme={theme}>
        <MyProfileHeader navigation={this.props.navigation} tabName="Projects">
          <Box bgcolor="#F0F5F7" px="2.5rem" py="2rem">
            <Box style={webStyle.pageTitle}>
            {this.handleCondition(
              this.state.activeStep < 4 ,   <BackButtonBox onClick={this.goBack}>
              <ArrowBackIos />
              <Typography sx={webStyle.backText}>Back</Typography>
            </BackButtonBox>,
            <Box>
              <Box style={{display:'flex',gap:'16px'}}>
              <button  data-test-id="popup-btn" onClick={(event) => this.handlePopup(event, 'button1')} style={webStyle.tabButtons}>Stages <img src={this.handleCondition(this.state.buttonId === 'button1' && this.state.activeButton , upArrowIcon , downArrowIcon)} width='24px' height='24px' alt="stages"  /></button>
              <button data-test-id="popup-btn1" onClick={(event) => this.handlePopup(event, 'button2')} style={webStyle.tabButtons}>Rooms <img src={this.handleCondition(this.state.buttonId === 'button2' && this.state.activeButton ,upArrowIcon , downArrowIcon)} width='24px' height='24px' alt="rooms" /></button>
              <button data-test-id="popup-btn2" onClick={(event) => this.handlePopup(event, 'button3')} style={webStyle.tabButtons}>
                <img src={dollarIcon} width='24px' height='24px'  alt="rates" />
               Rates
                <img src={this.handleCondition(this.state.buttonId === 'button3' && this.state.activeButton , upArrowIcon , downArrowIcon)} width='24px' height='24px' alt="Rates" />
                </button>
              </Box>
              <Popup
      anchorEl={this.state.anchorEl}
      keepMounted
      open={open}
      elevation={0}
      onClose={this.handleClose}
      style={{width:'322px',padding:this.handleCondition(this.state.buttonId !== 'button3','12px 16px 12px 24px','0px 0px'),borderRadius:'8px'}}
    >
      {this.state.stages.map((item:string,index:number)=>{
        return(
          <MenuItem   data-test-id={`menuItems1${index}`} value={this.state.checkValue}  onChange={(event:any)=>this.setState({checkValue:event.target.value})} key={item+1} 
          
         style={{width:'284px',display:'flex',gap:'8px',alignItems:'center',fontWeight:400,fontSize:'14px',
             backgroundColor:this.handleCondition(this.state.buttonId === 'button3'&& index ===1 , "#E0EDF0",'')
         }}
         onClick={()=> this.handleCondition(this.state.buttonId === 'button3' , this.handleClose(),'')}
          >
         {this.handleCondition(this.state.buttonId !== 'button3' ,  <input type="checkbox" value={item}   
          style={{width:'20',height:'20px',borderRadius:'6px',border:'1px solid #64748B'}} />, '' )}
          {item}
          </MenuItem>
        )
      })}
    {this.handleCondition(this.state.buttonId !== 'button3',
      <Box style={{display:'flex',gap:'16px',padding:'12px 16px 12px 24px'}}>
      <Button onClick={this.handleClose} variant="contained" style={webStyle.clearAll}>
        Clear All</Button>
         <Button onClick={this.handleClose} variant="contained" style={webStyle.apply}>
              Apply
             </Button>
      </Box>,'')}
    </Popup>
              </Box>
            )}
              <CustomStepper nonLinear activeStep={this.state.activeStep}>
                {this.getSteps().map((placeholder, index) => (
                  <Step
                    key={placeholder}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <ActiveStepLabel
                      StepIconComponent={(props) => (
                        <this.CustomStepIcon
                          {...props}
                          active={this.state.activeStep === index}
                          completed={this.state.activeStep > index}
                          style={{fontSize:'1.5rem',fontWeight:600,fontFamily:'Poppins'}}
                        />
                      )}
                    >
                      <Typography style={webStyle.stepperText}>
                         {this.state.activeStep === index && placeholder}
                      </Typography>
                    </ActiveStepLabel>
                  </Step>
                ))}
              </CustomStepper>
              <Box style={webStyle.buttonsBlock}>
                <Box style={webStyle.addMemberBtn}>
                  <Typography style={webStyle.addMemberText}>
                   View & Add Member
                  </Typography>
                </Box>
                <Button style={webStyle.saveDraftBtn}>Save Draft</Button>
                <NextButton
                  disabled={
                    !this.state.shellsSubstructures.some(
                      (item) => item.amount > 0
                    )
                  }
                  data-test-id="next-btn"
                  onClick={() => this.setState({activeStep:this.state.activeStep+1})}
                >
                  Next
                </NextButton>
              </Box>
            </Box>

            <Box style={webStyle.topBox}>
              {this.handleCondition(
                this.state.activeStep === 3 ,<Box component="div" style={ToggleButtonStyle.OptionsBox}>
                <Box component="div" style={{ ...ToggleButtonStyle.WDH, color:this.handleCondition( toggle , "#325962","#64748B"), backgroundColor: this.handleCondition(toggle,"#E0EDF0","" )} as React.CSSProperties } data-test-id="Width-Depth-Height" onClick={() => this.handleChangePage(true)}>W/D/H</Box>
                <Box component="div" style={{ ...ToggleButtonStyle.AreaParameter, color: this.handleCondition(!toggle ,"#325962","#64748B"), backgroundColor:this.handleCondition( !toggle , "#E0EDF0","") }  as React.CSSProperties } data-test-id="AreaParameter" onClick={() => this.handleChangePage(false)}>Area / Parameter</Box>
              </Box>
              ,
              <InputContainer>
                  <Input
                  startAdornment={<Search style={webStyle.searchIcon} />}
                  style={webStyle.searchInput}
                  placeholder="Search here..."
                  onChange={(e) => this.handleSearchInputChange(e.target.value)}
                  value={this.state.searchInputText}
                  disableUnderline
                  data-test-id="search-input"
                  />
              </InputContainer>
              )}
              {
                this.handleCondition(
                  this.state.activeStep < 3  ,
                      <Box style={webStyle.topBox}>
                      <Typography style={webStyle.sortText}>Sort by:</Typography>
                       <SortInput
                       disableUnderline
                       IconComponent={ExpandMore}
                       defaultValue={1}
                       sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                          >
                     <MenuItem value={1}>Alphabetical</MenuItem>
                    </SortInput>
                     </Box> ,
                    this.handleCondition(
                      !this.state.toggle && this.state.activeStep === 3 ,
                   <Box style={{display:'flex',gap:'15px',fontSize:'16px',fontWeight:400,color:'#334155'}}>
                      <Box
                      style={{position:'relative'}}
                        >Area: 
                     <input type="text" placeholder="Area"  style={webStyle.areaInput}/>
                     <p style={{position:'absolute',right:'5px',top:'9px',margin:0}}>m²</p>
                     </Box>
                  <Box style={{position:'relative'}}>Parimeter:  <input type="text" placeholder="Parimeter" style={webStyle.areaInput} />
                   <p style={{position:'absolute',right:'7px',top:'9px',margin:0}}>m</p>
                    </Box>
                    </Box>,
                    ''
                    )
                )
                        }
                     </Box>
                  <Grid container spacing={2} xs={12} style={webStyle.itemsGrid}>
                   {this.handleCondition(
                    this.state.activeStep >= 3 ,  <TableContainer style={{backgroundColor:'white'}}  >
                    <TableField sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                  <TableRow 
                   style={{fontSize:'16px',fontWeight:600}}>
                 <TableCell style={{display:'flex',gap:'7px'}}>
                   Room/Area 
               <img src={roundPlusIcon} width={'20px'} height={'20px'} alt="add member" /> 
             <span style={{color:'#237182',display:'flex'}}>Add Rooms <img src={downArrowIcon} alt="" /></span></TableCell>
           {this.handleCondition(
            this.state.activeStep < 4 ,
           this.handleCondition(
            this.state.toggle ,(   <>
              <TableCell align="center" style={{width:'150px'}} >Width</TableCell>
             <TableCell align="center" style={{width:'150px'}}>Depth</TableCell>
             <TableCell align="right" style={{width:'150px'}}>Height</TableCell>
         </>)

               ,

              ( <>
               <TableCell align="center"style={{width:'150px'}}> Area (m²)</TableCell>
                <TableCell align="right" style={{width:'150px'}}>Perimeter (m)</TableCell>
                   </>)
           )
                           ,
                            (<>
                            <TableCell align="center" style={{width:'150px'}}>Start Date</TableCell>
                            <TableCell align="center" style={{width:'150px'}}>End Date</TableCell>
                           <TableCell align="right" style={{width:'150px'}}>£ 3,589,177.82</TableCell></>
                                       )
           )
                                        }
                              </TableRow>
                              </TableHead>
                                <TableBody>
         {this.handleCondition(
          this.state.activeStep < 4 ,
         this.handleCondition(
          this.state.toggle , (<>
            { this.state.rooms.map((row:any,index:number) => (
             <TableRow
            key={index}
            style={{fontSize:'16px',fontWeight:400}} >
           <TableCell align="left">{row.Room}</TableCell>
           <TableCell align="center">{row.Width}</TableCell>
          <TableCell align="center">{row.Depth}</TableCell>
         <TableCell align="right">
            <Box sx={{display:'flex',width:'100%',justifyContent:'end',alignItems:'center',cursor:'pointer',
             margin:0,alignContent:'center'}}>
               {row.Height} 
                <span 
              data-test-id={`more-Btn1${index}`}
         onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.handlePopup(event, 'button4')}><img src={threeDotIcon} style={{}} width={'28px'} height={'28px'} alt="" /></span>
             </Box> 
             </TableCell>
                 <Popup
                 anchorEl={this.state.anchorEl}
                  id={`fade-menu-${index}`}
                   keepMounted
                   open={open}
                   elevation={0}
                   onClose={this.handleClose}
                    anchorOrigin={{
                 vertical: 'bottom',
                   horizontal: 'right',
                        }}
transformOrigin={{
vertical: 'top',
horizontal: 'right',
}}
>
<MenuItem onClick={this.handleClose}>
<ControlPointDuplicateSharp style={{ height: '18px', marginRight: '4px' }} />
Duplicate
</MenuItem>
<MenuItem onClick={this.handleClose} style={{color:'red'}}>
<DeleteOutline style={{ height: '20px', marginRight: '4px' }} />
Delete
</MenuItem>
</Popup>
</TableRow>))}
</>),
(<>
{ this.state.area.map((row:any,index:number) => (
<TableRow
key={index}
style={{fontSize:'16px',fontWeight:400}} >
<TableCell align="left">{row.Room}</TableCell>
<TableCell align="center">{row.Area}</TableCell>
<TableCell align="right">
<Box sx={{display:'flex',width:'100%',justifyContent:'end',alignItems:'center',cursor:'pointer',margin:0,alignContent:'center'}}>
{row.Perimeter} 
<span 
data-test-id={`more-Btn${index}`}
onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.handlePopup(event, 'button5')}><img src={threeDotIcon} style={{}} width={'28px'} height={'28px'} alt="" /></span>
</Box> 
</TableCell>
<Popup
anchorEl={this.state.anchorEl}
id={`fade-menu-${index}`}
keepMounted
open={open}
elevation={0}
onClose={this.handleClose}
anchorOrigin={{
vertical: 'bottom',
horizontal: 'right',
}}
transformOrigin={{
vertical: 'top',
horizontal: 'right',
}}
>
<MenuItem onClick={this.handleClose}><ControlPointDuplicateSharp style={{ height: '18px', marginRight: '4px' }} />Duplicate</MenuItem>
<MenuItem onClick={this.handleClose} style={{color:'red'}}><DeleteOutline style={{ height: '20px', marginRight: '4px' }} />Delete</MenuItem>
</Popup>
</TableRow>))}
</>)
         ),
         (<>
         { this.state.taskList.map((data:any,index:number) => (
                 <>
                          <TableRow >
                          <TableCell align="left">
                <Box sx={{display:'flex',width:'100%',justifyContent:'start',alignItems:'center',cursor:'pointer',margin:0,alignContent:'center'}}>
                 {data.Room} 
                 <span 
                 data-test-id="right-Btn1"
                 onClick={()=> this.setState({tableElT:!this.state.tableElT,tableIndex:index})}
                 >
                  <img src={this.handleCondition(
                    this.state.tableElT && this.state.tableIndex === index ,
                    blackDownArrowIcon ,
                    rightArrowIcon
                  )} width={'28px'} height={'28px'} alt=""/>
                  </span>
                 </Box> 
                </TableCell>
           <TableCell align="center" style={{position:'relative'}}>
            <p style={webStyle.date} >
            <img  data-test-id="start-date" src={calendarIcon} height={'16.6pxpx'} width={'15px'} alt="start" onClick={()=>this.setState({startDateOpen:!this.state.startDateOpen,endDateOpen:false})}/>
            <span style={{paddingTop:'8px'}}>{data.StartDate}</span>
                 {this.handleCondition(this.state.startDateOpen && !this.state.endDateOpen , <CalendarStyle>
                 <p style={{fontWeight:600,fontSize:'16px',margin:'0px',textAlign:'center'}}>Select Start & End date</p>
                   <Calendar selectRange onChange={this.handleDateChange} value={this.state.tempDate}  />
                    </CalendarStyle>,'')}
            </p>
           </TableCell>
           <TableCell align="center"  style={{position:'relative'}}>
           <p style={webStyle.date}>
            <img data-test-id="end-date" src={calendarIcon} height={'16.6px'} width={'15px'} alt="end" onClick={()=>this.setState({endDateOpen:!this.state.endDateOpen,startDateOpen:false})} />
            <span style={{paddingTop:'8px'}}>{data.EndDate}</span>
            {this.handleCondition(this.state.endDateOpen && !this.state.startDateOpen , <CalendarStyle>
              <p style={{fontWeight:600,fontSize:'16px',margin:'0px',textAlign:'center'}}>Select Start & End date</p>
             <Calendar selectRange value={this.state.tempDate}  onChange={this.handleDateChange}   />
             </CalendarStyle>,'')}
            </p>
          </TableCell>
           <TableCell align="center" style={{padding:'0px'}}>
               <Box sx={{display:'flex',width:'100%',justifyContent:'end',alignItems:'center',cursor:'pointer',margin:0,alignContent:'center'}}>
                 <span style={{paddingTop:'7px'}}>
                 {data.TotalEarn} 
                 </span>
                 <span 
                 data-test-id="more-Btn"
                 onClick={this.handleTaskListPopup}><img src={threeDotIcon} width={'28px'} height={'28px'} alt="" /></span>
                 </Box> 
               </TableCell>
         </TableRow>
         <TableRow>
           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
             <Collapse in={this.state.tableElT && this.state.tableIndex === index} timeout="auto" unmountOnExit>
               <Box margin={1} width={'100%'}  >
                {data.row.history.map((historyRow:any,histryIdx:number)=>{
                  return(
                    <>
               <Box key={historyRow.date+1} style={{display:'flex',justifyContent:'space-between',width:'100%',padding:'8px 8px 8px 0px'}}>
               <Box sx={{display:'flex',justifyContent:'start',alignItems:'center',cursor:'pointer',margin:0}}>
               {historyRow.date}
                 <span 
                 data-test-id={`right-Btn2${histryIdx}`}
                 onClick={()=> this.setState({subTaskActive:!this.state.subTaskActive,
                                              subTaskId:histryIdx
                 })
                 }
                 >
                  <img src={this.handleCondition(this.state.subTaskActive && this.state.subTaskId === histryIdx, blackDownArrowIcon ,rightArrowIcon)} 
                       width={'28px'} 
                       height={'28px'} 
                       alt=""/>
                  </span>
                 </Box> 
                <Box sx={{display:'flex',justifyContent:'end',alignItems:'center',cursor:'pointer',margin:0,alignContent:'center'}}>
                 {historyRow.amount} 
                 <span 
                 data-test-id="more-Btn"
                 onClick={this.handleTaskListPopup}>
                  <img src={threeDotIcon} width={'28px'} height={'28px'} alt="" /></span>
                 </Box> 
               </Box>
               <Collapse in={this.state.subTaskActive && this.state.subTaskId === histryIdx} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow style={{backgroundColor:'#D3E4E9',color:'#334155',fontWeight:600,fontSize:'12px'}}>
                      <TableCell style={{fontSize:'12px',fontWeight:600}}>Task</TableCell>
                      <TableCell style={{fontSize:'12px',fontWeight:600}}>Specifications</TableCell>
                      <TableCell align="center" style={{fontSize:'12px',fontWeight:600}}>Qty</TableCell>
                      <TableCell align="center" style={webStyle.THead}>Total price ($)</TableCell>
                      <TableCell align="center" style={webStyle.THead}>Measurement unit</TableCell>
                      <TableCell align="center" style={webStyle.THead}>Labour cost / unit</TableCell>
                      <TableCell align="center" style={webStyle.THead}>Materials cost / unit</TableCell>
                      <TableCell align="center" style={webStyle.THead}>Unit cost</TableCell>
                      <TableCell align="center" style={webStyle.THead}>Total Cost</TableCell>
                      <TableCell align="center" style={webStyle.THead}>Client Supplied</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historyRow.subTask.map((subData:any,id:number) => (
                      <TableRow key={id+1} style={{backgroundColor:'#F0F5F7',fontWeight:400,fontSize:'14px'}}>
                        <TableCell component="th" scope="row" width={'306px'}>
                        <FormControl variant="outlined" fullWidth >
          <InputLabel shrink={false} id="demo-simple-select-outlined-label">{subData.Task}</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={''}
            data-test-id="task-name"
            disabled
            style={{backgroundColor:'white'}}
          >
            <MenuItem value="">
              <em>{subData.Task}</em>
            </MenuItem>
          </Select>
        </FormControl>
                        </TableCell>
                        <TableCell width={'306px'} >
                        <FormControl variant="outlined" fullWidth >
          <InputLabel shrink={false} id="demo-simple-select-outlined-label">{subData.Specifications}</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={""}
            data-test-id="task-specifications"
            disabled
            style={{backgroundColor:'white'}}
          >
            <MenuItem value="">
              <em> {subData.Specifications}</em>
            </MenuItem>
          </Select>
        </FormControl>
                         </TableCell>
                        <TableCell align="center">
                         
                         <Box style={webStyle.Qty}>
                         {subData.Qty}
                          </Box> 
                          </TableCell>
                        <TableCell align="center">
                          {subData.Totalprice}
                        </TableCell>
                        <TableCell align="center">
                          {subData.Measurementunit}
                        </TableCell>
                        <TableCell align="center">
                          {subData.Labourcostunit}
                        </TableCell>
                        <TableCell align="center">
                          {subData.Materialscostunit}
                        </TableCell>
                        <TableCell align="center">
                          {subData.Unitcost}
                        </TableCell>
                        <TableCell align="center">
                          {subData.TotalCost}
                        </TableCell>
                        <TableCell align="center">
                        <Box sx={{display:'flex',width:'100%',justifyContent:'end',alignItems:'center',cursor:'pointer',margin:0,alignContent:'center'}}>
                        <input type="checkbox" 
                          style={{width:'20px',height:'20px',borderRadius:'8px',border:'1px solid #64748B',
                             backgroundColor:'#F0F5F7'
                          }}
                           />
                 <span 
                 data-test-id="more-Btn"
                 onClick={this.handleTaskListPopup}><img src={threeDotIcon}  width={'28px'} height={'28px'} alt="" /></span>
                 </Box> 
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box style={{display:'flex',gap:'7px',paddingLeft:'16px',paddingTop:'6px'}}>
                <img src={roundPlusIcon} width={'20px'} height={'20px'} alt="add member" /> 
              <span style={{color:'#237182',display:'flex'}}>Add Task <img src={downArrowIcon} alt="" /></span></Box>
              </Box>
            </Collapse>
                    </>
                  )
                })}
                <Box style={{display:'flex',gap:'7px',paddingTop:'6px'}}>
                <img src={roundPlusIcon} width={'20px'} height={'20px'} alt="add member" /> 
              <span style={{color:'#237182',display:'flex'}}>Add TaskList <img src={downArrowIcon} alt="" /></span></Box>
               </Box>
             </Collapse>
           </TableCell>
         </TableRow>
                 </>
            ))}
         </>)
         )}
         </TableBody>
         <Popup
        anchorEl={this.state.anchorElT}
        keepMounted
        open={open2}
        elevation={0}
        onClose={this.handleClose2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
      }}
      transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
      }}
      >
        <MenuItem onClick={this.handleClose2}>
        <ControlPointDuplicateSharp style={{ height: '18px', marginRight: '4px' }} />
        Duplicate
        </MenuItem>
        <MenuItem onClick={this.handleClose2} style={{color:'red'}}>
        <DeleteOutline style={{ height: '20px', marginRight: '4px' }} />
        Delete
        </MenuItem>
      </Popup>
       </TableField>
     </TableContainer> ,
               this.state.filteredItems.map((item,index:number) => (
                 <Grid
                   item
                   md={3}
                   sm={6}
                   xs={12}
                   style={{ marginTop: "1.5rem" }}
                   key={item.id}
                 >
                   <Box style={webStyle.gridItem}>
                     <img src={baseURL + item.logo} width={'80px'} height={'80px'} style={{borderRadius:'50%'}} />
                     <Typography style={{ margin: "1rem", fontWeight:600,fontSize:'14px',lineHeight:'22px',
                     fontFamily:"Poppins",color:'#475569'  }}>
                       {item.name}
                     </Typography>
                     <Box style={webStyle.counterBox}>
                       <Remove
                         style={webStyle.counterButton}
                         onClick={() =>
                           this.handleAmountChange(item.id, "remove")
                         }
                         data-test-id={`remove-btn${index}`}
                       />
                       <Typography style={{fontWeight:400,fontSize:'16px',fontFamily:'Poppins'}}>{item.amount}</Typography>
                       <Add
                         style={webStyle.counterButton}
                         onClick={() => this.handleAmountChange(item.id, "add")}
                         data-test-id={`add-btn${index}`}
                       />
                     </Box>
                   </Box>
                 </Grid>))
                   )
              }
            </Grid>
          </Box>
         {this.state.filteredItems.length === 0 && 
          <Box style={{width:'100%',height:'80%',display:'flex',justifyContent:'center',alignItems:'center'}}>
          <img src={frameIcon} alt="" width='457px' height='442px' />
        </Box>}
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  countText:{fontFamily: 'Poppins'},
  backText: {color: '#334155', fontSize: '14px', fontWeight: 600, fontFamily: 'Poppins'},
  stepperText: { fontFamily: 'Poppins', whiteSpace: 'nowrap', fontSize: '14px', lineHeight: '22px'},
  pageTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  buttonsBlock: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  addMemberBtn: {
    display: "flex",
    gap: "0.5rem",
    cursor: "pointer",
  },
  addMemberIcon: {
    width: "20px",
    height: "20px",
    color: "#237182",
  },
  addMemberText: {
    fontWeight: 600,
    color: "#237182",
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins'
  },
  saveDraftBtn: {
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#E0EDF0",
    color: "#325962",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    fontFamily:'Poppins',
    textTransform: "none" as "none",
  },
  topBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchInput: {
    border: "1px solid #D3E4E9",
    background: "#FCFDFE",
    borderRadius: "8px",
    width:'392px',
    height:'32px',
    padding:'10px 16px 10px 16px',
    fontSize:'16px',
    fontWeight:400,
    fontFamily:'Poppins',
    color:'#94A3B8'
  },
  searchIcon: {
    color: "#DADADA",
    width: "20px",
    height: "20px",
    marginRight: "0.5rem",
  },
  sortText: {
    color: "#334155",
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,fontFamily: 'Poppins'
  },
  gridItem: {
    background: "white",
    borderRadius: "8px",
    textAlign: "center" as "center",
    padding: "1.75rem 0",
  },
  counterBox: {
    display: "flex",
    alignItems: "center" as "center",
    justifyContent: "center",
    gap: "1rem",
  },
  counterButton: {
    width: "20px",
    height: "20px",
    color: "#0F172A",
    cursor: "pointer",
  },
  itemsGrid: {
    overflowY: "auto" as "auto",
    margin: "0px",
    marginTop:'10px',
  },
  areaInput:{
    width:'150px',
    height:'44px',
    outline: 'none'
  },
  tabButtons:{
      display: "flex",
      alignItems: "center",
      padding: "10px 8px 10px 8px",
      backgroundColor:" white",
      borderRadius: '8px',
      fontWeight:400,
      fontSize:'16px',
      border:'1px solid #e0e5e9',
      width:'125px',
      height:'44px',
      justifyContent:'space-between'
  },
  clearAll:{width:'110px',
            height:'32px',
            borderRadius:'8px',
           fontWeight:600,fontSize:'14px',
           padding:'6px 10px 6px 10px',
           backgroundColor:'#E0EDF0',
           color:'#325962'},
  apply:{width:'110px',
          height:'32px',
          borderRadius:'8px',
         fontWeight:600,
         fontSize:'14px',
         padding:'6px 10px 6px 10px',
         backgroundColor:'#237182',
         color:'#FFFFFF'},
         date :{
          gap:'7px',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          margin:0,
          coursor:'pointer',
         },
         Qty:{width:'90px',
          height:'44px',
          borderRadius:'8px',
          padding:'10px 8px 8px',
          backgroundColor:'white',
          border:'1px solid rgba(0, 0, 0, 0.38)',
          display:'flex',
          alignItems:'center'
          ,justifyContent:'center'
        },
        THead:{
          width:'90px',
          fontWeight:600,
          fontSize:'12px'
        },
};

const InputContainer = styled("div")(({ theme }) => ({
  '& input::placeholder':{
    color: '##A6B0BF'
  }
})); 

const ToggleButtonStyle = {
  PersonalInformationBox: {
    padding: "24px 48px 24px 48px", fontFamily: "Poppins",
    marginLeft:"6rem"
  },
  WDH: {
    padding: "16px 18px 16px 18px",borderRadius: "50px",
    fontFamily: "Poppins",fontWeight: 600,
    cursor: "pointer", ...(window.innerWidth < 600 && {
     textAlign: "center"
    }),
  },
  AreaParameter: {
    padding: "12px 18px 12px 18px",borderRadius: "50px",
    fontFamily: "Poppins",fontWeight: 600,
    cursor: "pointer",lineHeight: "27px",
    margin: "0px",...(window.innerWidth < 600 && {
      textAlign: "center"
    })
  },
  OptionBox: {
    display: "flex",justifyContent: "start",
  },
  OptionsBox: {
    display: "flex", backgroundColor: "white",
    borderRadius: "50px", padding: "4px"
  },
  personInfoDetailBox: {
    display: "flex", gap: "24px"
  },
  PersonalInformationDetailBox: {
    marginTop: "24px", padding: "30px 32px 30px 32px",
    backgroundColor: "white", borderRadius: "16px"
  }
}

// Customizable Area End
