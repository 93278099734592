// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, isEmail, setStorageData } from "../../../framework/src/Utilities";
import { IImageUrl, IPresignUrl, ImageUrl } from "./types";
import { Country, State } from "country-state-city";
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { FormikHelpers } from "formik";
import React from "react";
import { styled, Typography } from "@material-ui/core";
import { JpgIcon, PdfIcon, PngIcon } from "./Icons";
import { bedImage, buildNewIcon, mockIcon } from "./assets";
import _ from "lodash"


export const configJSON = require("./config");
interface Section {
  attributes: any;
  id: number;
  name: string;
  mandatory: boolean;
}

interface Meta {
  message: string;
}
export interface Props {
  navigation: any;
  id: string;
  classes: any;
  data: {
    id: string;
    type: string;
    attributes: Section;
  }[];
  meta: Meta;
}

interface BackendErrors {
  projectName?: string;
  name?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  streetName?: string;
  cityName?: string;
  postCode?: string;
  country?: string;
  state?: string;
  additionalInfo?: string;
}

export interface FormValues {
  projectName: string;
  clientDetails: string;
  teamSize: string;
  projectUrl: string;
  developerEmail: string;
  developerFullName: string;
  firstName: string;
  projectDescription: string;
  lastName: string;
  postCode: string;
  city: string;
  email: string;
  streetName: string;
  phoneNumber: string;
  startDate: Date | null;
  endDate: Date | null;
  country: string;
  state: string;
  countryCode: string;
  draft:boolean,
  projectImages: {
    [x: string]: any; [key: number]: FileData[] 
};
  projectDocuments: IPresignUrl[];
  projectImagesUrl: {
    name: string;
    imageUrl?: ImageUrl;
    imageObj: any;
    id?: number | null;
  }[];
}

interface Shell {
  id: number;
  logo: any;
  name: string;
  amount: number;
}
export interface FileData {
  isUploading: boolean;
  section_id? : number;
  file:File;
  file_name: string;
  size: string;
  content_type: string;
  image: File;
  error?: string;
  progress?: number;
  attachment_id :number;
}

interface FileUploaderState {
  fileCounts: { [key: number]: number };
}

interface UploadedFile {
  file_name: string;
  content_type: string;
  section_id: number;
  url: string;
  size: string;
  attachment_id: number;
}

interface UploadProjectResponse {
  [x: string]: any;
  message: string;
  project: {
      id: number;
      name: string;
  };
  uploaded_files: UploadedFile[];
}


interface S {
  planId: number | string | null;
  snackbarOpen: boolean,
  snackbarMessage: string
  isDirty:boolean,
  openDialogLeave:boolean,
  path: string;
  stateList: { isoCode: string; name: string }[];
  countryList: any[];
  anchorEls: any[];
  token: string;
  loading: boolean;
  currentDate: string;
  isDisabled: boolean;
  projectId: string;
  projectDataId: string;
  formValues: FormValues;
  imageUrl: IImageUrl[];
  documentUrl: IImageUrl[];
  images: IPresignUrl[];
  documents: IPresignUrl[];
  openDialog:boolean
  draft:boolean
  formError: {
    projectNameError: string;
    projectDescriptionError: string;
    clientDetailsError: string;
    teamSizeError: string;
    projectUrlError: string;
    developerEmailError: string;
    developerFullNameError: string;
    firstNameError: string;
    lastNameError: string;
    emailError: string;
    streetNameError: string;
    countryCodeError: string;
    cityError: string;
    phoneNumberError: string;
    startDateError: string;
    endDateError: string;
    countryError: string;
    postCodeError: string;
    stateError: string;
  };
  activeStep: number;
  completed: { [k: number]: boolean };
  selectedType: number;
  selectedScopes: number[];
  searchInputText: string;
  shellsSubstructures: Shell[];
  filteredItems: Shell[];
  specialistCostPlanList: Section[];
  uploadedFiles: {
    [x: string]: any; [key: number]: FileData[] 
};
  fileListExpanded: { [key: number]: boolean };
  fileCounts: { [key: number]: number }
  uploadProjectResponse: UploadProjectResponse;
  deleteProjectUploadResponse : {}
  fileNotFound:boolean;
  projectTypes:[object]
  handleNavigation:number
  scopeCategories:any[],
  rooms:any,
  toggle:boolean,
  anchorEl:any,
  uploadedImageFiles: FileData[];
  anchorElT:any
  area:any,
  buttonId:string,
  stages:any,
  checkValue:string,
  activeButton:boolean,
  taskList:any[],
  tableElT:boolean,
  tableIndex:number,
  subTaskActive:boolean,
  subTaskId:number,
  startDateOpen:boolean,
  endDateOpen:boolean,
  tempDate:any,
  shellsData:any[]
  errors: {
    projectName: string
  }
  roomsData:any[]
  scopeId:Number
  searchError:boolean,
  backendErrors:BackendErrors,
}

interface SS {
  id: any;
}

const shellsMock = [
  {
    id: 1,
    logo: mockIcon,
    name: "Ground Floor – Timber/Brick",
    amount: 0,
  },
  { id: 2, logo: mockIcon, name: "Pitched Roof", amount: 0 },
  {
    id: 3,
    logo: mockIcon,
    name: "Ground Floor – Brick/Block",
    amount: 0,
  },
];

const substructuresMock = [
  {
    id: 1,
    logo: mockIcon,
    name: "Ground Floor – Brick/Block",
    amount: 0,
  },
  { id: 2, logo: mockIcon, name: "Ground Floor – Timber/Brick", amount: 0 },
  {
    id: 3,
    logo: mockIcon,
    name: "Stubbed Attic",
    amount: 0,
  },
];


const selectingRoomMock = [
  {
    id: 1,
    logo: bedImage,
    name: "Ground Floor – Brick/Block",
    amount: 0,
  },
  { id: 2, logo: bedImage, name: "Ground Floor – Timber/Brick", amount: 0 },
  {
    id: 3,
    logo: bedImage,
    name: "Stubbed Attic",
    amount: 0,
  },
];

const RedTextTypography = styled(Typography)({
  color: "#FF0000",
  marginTop: 2,
  fontSize: "small",
  fontFamily: "Poppins",
});

export default class CreateEditProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCreateProjectCallId: string = "";
  apiDraftProjectCallId:string="";
  apiGetProjectbyIDListCallId: string = "";
  getSpecialistCostPlanListCallId: string = "";
  uploadProjectFileCallId: string = "";
  deleteProjectUploadFileCallId: string = "";
  handelStripeCallId: string = "";
  apiGetProjectByIdCallId: string | null = "";
  projectTypeGetCallId:string = '';
  projectScopesGetCallId:string = '';
  shellsGetCallid:string = '';
  roomsGetCallid:string = '';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      snackbarOpen: false,
      snackbarMessage: '',
      planId: null,
      isDirty:false,
      path: "",
  openDialogLeave:false,
      stateList: [],
      countryList: Country.getAllCountries(),
      anchorEls: [],
      token: "",
      loading: false,
      currentDate: "",
      isDisabled: false,
      projectId: "",
      projectDataId: "",
      openDialog:false,
      draft:false,
      formValues: {
        projectName: "",
        draft:false,
        clientDetails: "",
        teamSize: "",
        projectUrl: "",
        startDate: null,
        endDate: null,
        developerEmail: "",
        developerFullName: "",
        firstName: "",
        projectDescription: "",
        lastName: "",
        postCode: "",
        city: "",
        email: "",
        streetName: "",
        phoneNumber: "",
        country: "",
        state: "",
        countryCode: "+44",
        projectImages: {},
        projectDocuments: [],
        projectImagesUrl: []
      },
      images: [],
      documents: [],
      imageUrl: [],
      documentUrl: [],
      formError: {
        projectNameError: "",
        projectDescriptionError: "",
        clientDetailsError: "",
        teamSizeError: "",
        projectUrlError: "",
        developerEmailError: "",
        developerFullNameError: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        countryCodeError: "",
        phoneNumberError: "",
        streetNameError: "",
        cityError: "",
        startDateError: "",
        endDateError: "",
        postCodeError: "",
        countryError: "",
        stateError: "",
      },
      activeStep: 0,
      completed: {},
      selectedType: 0,
      selectedScopes: [],
      searchInputText: "",
      shellsSubstructures: [],
      filteredItems: [],
      specialistCostPlanList:[],
      uploadedFiles: {},
      fileListExpanded: {},
      fileCounts: {},
      uploadProjectResponse: {
        message: '',
        project: {
            id: 0,
            name: ''
        },
        uploaded_files: []
    },
      deleteProjectUploadResponse : {},
      fileNotFound:false,
     
      
      projectTypes:[{}],
      handleNavigation:0,
      scopeCategories:[{}],
      rooms : [
        {
          "Room": "Bedroom","Width": "4.00m",
          "Depth": "3.50m","Height": "2.50m"
        },
        {
          "Room": "Living Room","Width": "5.00m",
          "Depth": "4.00m", "Height": "2.80m"
        },
        {
          "Room": "Kitchen","Width": "3.50m",
          "Depth": "3.00m", "Height": "2.40m"
        },
          {"Room": "Storage Room", "Width": "2.00m",
          "Depth": "2.50m","Height": "2.40m"}
      ],
      toggle:true,
      anchorEl:null,
      uploadedImageFiles: [],
      area:[
        {"Room": "Bedroom","Area":"6.00 m²",
          "Perimeter":"4.00 m"
        },
        {"Room": "Living Room","Area":"2.00 m²","Perimeter":"10.00 m"},
        {"Room": "Kitchen",
          "Area":"5.00 m",
        "Perimeter":"10.00 m"},
        {"Room": 
          "Storage Room",
          "Area":
          "3.00 m²",
        "Perimeter":
        "5.00 m"}
      ],
      buttonId:'',
      stages : [],
      checkValue:'',
      activeButton:false,
      taskList: [
        {
          Room: "Common Area",
          StartDate: "Jun 1, 2024",
          EndDate: "Jun 5, 2024",
          TotalEarn:
           "£ 300.00",
          row: {
            history: [
              {
                date: "Jun 2, 2024",
                customerId: 'abc',
                amount:
                 '£ 50.00',
                subTask: [
                  {
                    "Task":
                     "Electrical Wiring",
                    "Specifications": 
                    "Copper wire, 12 gauge",
                    "Qty": 100,
                    "Totalprice": 1500,
                    "Measurementunit": "feet",
                    "Labourcostunit": 
                    10,
                    "Materialscostunit": 5,
                    "Unitcost": 15,
                    "TotalCost":
                     1500,
                    "ClientSupplied": "No"
                  },
                  {
                    "Task": 
                    "Drywall Installation",
                    "Specifications": "1/2 inch drywall sheets",
                    "Qty":
                     50,
                    "Totalprice": 1200,
                    "Measurementunit": "sheets",
                    "Labourcostunit":
                     8,
                    "Materialscostunit": 20,
                    "Unitcost": 28,
                    "TotalCost":
                     1400,
                    "ClientSupplied":
                     "Yes"
                  },
                  {
                    "Task": "Flooring",
                    "Specifications": "Hardwood, Oak",
                    "Qty": 800,
                    "Totalprice": 6000,
                    "Measurementunit": "sq ft",
                    "Labourcostunit": 2,
                    "Materialscostunit": 6,
                    "Unitcost": 8,
                    "TotalCost": 6400,
                    "ClientSupplied": "No"
                  },
                  {
                    "Task": "Plumbing", "Specifications"
                    : 
                    "PVC pipes, various sizes",
                    "Qty": 200,
                    "Totalprice": 800,
                    "Measurementunit":
                     "pieces",
                    "Labourcostunit": 5,
                    "Materialscostunit": 2,
                    "Unitcost": 7,
                    "TotalCost": 1400,"ClientSupplied": "Yes"
                  },
                  {
                    "Task": "Painting",
                    "Specifications": "Interior latex paint",
                    "Qty": 10,
                    "Totalprice": 300,  "Measurementunit": "gallons",
                    "Labourcostunit":
                     15,
                    "Materialscostunit": 
                    25,
                    "Unitcost": 40,
                    "TotalCost": 400, "ClientSupplied": "No"
                  }
                ]
              },
              {
                date: 
                "Jun 3, 2024",
                customerId:
                 'abc',
                amount: 
                '£ 90.00',
                subTask: [
                  {
                    "Task": "Electrical Wiring", "Specifications": "Copper wire, 12 gauge",
                    "Qty": 100,"Totalprice": 1500,
                    "Measurementunit": "feet", "Labourcostunit": 10,
                    "Materialscostunit": 5,
                    "Unitcost": 15,"TotalCost": 1500,
                    "ClientSupplied": "No"
                  },
                  {
                    "Task": 
                    "Plumbing",
                    "Specifications": "PVC pipes, various sizes",
                    "Qty": 200,
                    "Totalprice":
                     800,
                    "Measurementunit": "pieces",
                    "Labourcostunit": 5,
                    "Materialscostunit"
                    : 2,
                    "Unitcost": 7,
                    "TotalCost": 1400,
                    "ClientSupplied": 
                    "Yes"
                  },
                  {
                    "Task": "Painting",
                    "Specifications":
                     "Interior latex paint",
                    "Qty": 10,
                    "Totalprice": 300,
                    "Measurementunit":
                     "gallons",
                    "Labourcostunit": 15,
                    "Materialscostunit":
                     25,
                    "Unitcost": 40,
                    "TotalCost": 400,
                    "ClientSupplied": "No"
                  }
                ]
              },
              {
                date: "Jun 5, 2024",
                customerId: 'abc',
                amount: 
                '£ 40.00',
                subTask: [
                  {
                    "Task":
                     "Electrical Wiring",
                    "Specifications": "Copper wire, 12 gauge",
                    "Qty": 100,
                    "Totalprice": 
                    1500,
                    "Measurementunit": "feet",
                    "Labourcostunit": 10,
                    "Materialscostunit":
                     5,
                    "Unitcost": 15,
                    "TotalCost":
                     1500,
                    "ClientSupplied": "No"
                  },
                  {
                    "Task": "Drywall Installation",
                    "Specifications": "1/2 inch drywall sheets",
                    "Qty": 50,
                    "Totalprice": 1200,
                    "Measurementunit": "sheets",
                    "Labourcostunit": 8,
                    "Materialscostunit": 20,
                    "Unitcost": 28,
                    "TotalCost": 1400,
                    "ClientSupplied": "Yes"
                  },
                  {
                    "Task": "Flooring","Specifications": "Hardwood, Oak",
                    "Qty": 800,"Totalprice": 6000,
                    "Measurementunit": "sq ft", "Labourcostunit": 2,
                    "Materialscostunit": 6,"Unitcost": 8,
                    "TotalCost": 6400,"ClientSupplied": "No"
                  }
                ]
              }
            ]
          }
        }]
      ,
      anchorElT:null,
      tableElT:false,
      tableIndex:0,
      subTaskActive:false,
      subTaskId:0,
      startDateOpen:false,
      endDateOpen:false,
      tempDate:null,
      shellsData:[],
      roomsData:[],
      errors: {
        projectName: ''
      },
      scopeId:0,
      searchError:false,
      backendErrors: {
        projectName:''
      } as BackendErrors,
      
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(
        "Dummy text because Enum does not exist"
      );
      responseData && this.setState({ projectId: responseData });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiCreateProjectCallId) {
        this.createProjectHandleResponse(responseJson);
      } if (apiRequestCallId === this.apiDraftProjectCallId) {
        this.handleSaveDraftProjectHandleResponse(responseJson);
      } 
    }
    this.receive1(message);
    this.receive2(message);
    this.receive3(message);
    this.receive4(message);
    this.receive5(message);
  }
 receive1=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getSpecialistCostPlanListCallId) {
      this.specialistCostPlanListResponse(responseJson);
    }

    if (apiRequestCallId === this.projectTypeGetCallId) {
           this.handleProjectTypeData(responseJson)
           
    }

    if (apiRequestCallId === this.projectScopesGetCallId) {
      this.handleScopeCategories(responseJson)
}
  }


 }
 receive2=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.uploadProjectFileCallId) {
      this.setState({uploadProjectResponse:responseJson});
      this.getProjectDetailsById();
    }
  }


 }
 receive3=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.deleteProjectUploadFileCallId) {
      this.setState({deleteProjectUploadResponse:responseJson})
     this.deleteProjectUploadResponse(responseJson);
    }
  }


 }
 receive4=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.handelStripeCallId) {
      this.stripeResponse(responseJson);
    }
  }
 }
 receive5=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.apiGetProjectByIdCallId) {
        this.getProjectByIdResponse(responseJson);
       
      }
      if (apiRequestCallId === this.shellsGetCallid) {
        this.handleShellsResponse(responseJson.data)   
      }
      if (apiRequestCallId === this.roomsGetCallid) {
        this.handleRoomsResponse(responseJson.data)
      }
    }

  }

}

 getIds() {
  let url = window.location.pathname;
  let parts = url.split("/");

  if (parts.length >= 3) {
    const planId = parts[2];

    if (parts.length >= 5 && parts[3] === "project_id") {
      const projectId = parts[4];
      this.setState({ planId: planId, projectId: projectId });
      return { planId, projectId };
    }
   
    this.setState({ planId: planId, projectId: '' });
    return { planId, projectId: null };
  }
  return { planId: null, projectId: null };
}

  async componentDidMount() {
    let token = await getStorageData("token");
    const path = window.location.pathname
    this.setState({ token: token });
    let date = new Date();
    this.getIds();
    this.setState({ currentDate: date.toISOString().split("T")[0] });
    this.getspecialistCostPlanList();
    this.getProjectDetailsById()
    this.getProjectType();
    this.getProjetScopes()
    this.setState({handleNavigation:Number(path.split("/")[2])})
  }


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    this.handleUploadedFilesUpdate(prevState);
    this.handleCountryChange(prevState);
  
  }
  
  private handleUploadedFilesUpdate(prevState: Readonly<S>) {
    const { uploaded_files } = this.state.uploadProjectResponse;
  
    if (prevState.uploadProjectResponse.uploaded_files !== uploaded_files) {
      const uploadedFilesArray = Array.isArray(uploaded_files)
        ? uploaded_files
        : Object.values(uploaded_files).flatMap((file) => (Array.isArray(file) ? file : [file]));
  
      this.setState((prevState) => ({
        formValues: {
          ...prevState.formValues,
          projectImages: Array.isArray(prevState.formValues.projectImages)
            ? [...prevState.formValues.projectImages, ...uploadedFilesArray]
            : [...uploadedFilesArray]
        }
      }));
    }
  }
  handleCountryChangeAndGetState = (
    event: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: any
  ) => {
    const selectedCountryCode = event.target.value as string;
    
    const states = State.getStatesOfCountry(selectedCountryCode);
  
    setFieldValue('country', selectedCountryCode);
    setFieldValue('state', '');
  
    this.setState({ stateList: states || [] });
  };

  private handleCountryChange(prevState: Readonly<S>) {
    const currentCountry = this.state.formValues.country;
    const previousCountry = prevState.formValues.country;
  
    if (currentCountry !== previousCountry) {
      const stateList = State.getStatesOfCountry(currentCountry).map((state) => ({
        isoCode: state.isoCode,
        name: state.name,
      }));
      this.setState({
        stateList,
        formError: {
          ...this.state.formError,
          stateError: "",
        },
      });
    }
    if (
      (prevState.activeStep === 0 || prevState.activeStep === 2) &&
      this.state.activeStep === 1
    ) {  
        this.setState({
          shellsSubstructures: this.state.shellsData,
          filteredItems: this.state.shellsData,
        });
      }
      if (
      (prevState.activeStep === 1 || prevState.activeStep === 3) &&
      this.state.activeStep === 2
      ) {
        this.setState({
          shellsSubstructures:this.state.roomsData,
          filteredItems: this.state.roomsData,
        });
      }   
   
    if(prevState.buttonId !== this.state.buttonId){
        this.handleButton()
    }

    if(prevState.anchorEl !== this.state.anchorEl){
        this.setState({activeButton:!this.state.activeButton})
    }
    
  }
  
 
  handleStateChange = (event: React.ChangeEvent<any>) => {
    const selectedState = event.target.value;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        state: selectedState,
      },
      formError: {
        ...prevState.formError,
        stateError: "",
      },
    }));
  };

  getSteps() {
    return [
      "Project Information",
      "Select Shells",
      "Select rooms",
      "Select rooms and all",
      "Task List",
    ];
  }

  handleTitle(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectName: text },
      formError: { ...this.state.formError, projectNameError: "" },
    });
  }
  handleProjectDescription(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectDescription: text },
      formError: { ...this.state.formError, projectDescriptionError: "" },
    });
  }
  handleClientDetails(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, clientDetails: text },
      formError: { ...this.state.formError, clientDetailsError: "" },
    });
  }
  handleTeamSize(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, teamSize: text },
      formError: { ...this.state.formError, teamSizeError: "" },
    });
  }
  handleProjecturl(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectUrl: text },
      formError: { ...this.state.formError, projectUrlError: "" },
    });
  }

  handleStartDate(date: Date) {
    this.setState({
      formValues: { ...this.state.formValues, startDate: date },
    });
  }
  handleEndDate(date: Date) {
    this.setState({ formValues: { ...this.state.formValues, endDate: date } });
  }

  handleOpenDialog=()=>{
  this.setState({openDialog:true})
  }

  handleOpenDialogLeave = (path: string) => {
    const { formValues } = this.state;
  
    const isFormEmpty = Object.values(formValues).every((value, index) => {
      return (
        !value ||                         
        value === "+44" ||                        
        (Array.isArray(value) && value.length === 0) ||  
        (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0)
      );
    });
    
    if (isFormEmpty) {
      this.props.navigation.navigate(path);
    } else {
      this.setState({
        path,
        openDialogLeave: true,
      });
    }
  };
  
  handleCloseDialog=()=>{
    this.setState({openDialog:false,openDialogLeave:false,snackbarOpen:false})

  }
  imagePicker = async () => {
    try {
      const imageResults = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.images],
      });
      let imgData: IPresignUrl[] = this.state.images;
      let newImageUrls: IImageUrl[] = this.state.imageUrl;

      imageResults.forEach((item: DocumentPickerResponse) => {
        imgData.push({
          file_name: item.name,
          file_size: item.size ? item.size : 0,
          category: "image",
        });
      });
      imageResults.forEach((item: DocumentPickerResponse) => {
        newImageUrls.push({
          name: item.name,
          type: item.type,
          uri: item.uri,
        });
      });
      this.setState({ images: imgData, imageUrl: newImageUrls });
    } catch (error) {}
  };
  docPicker = async () => {
    try {
      const documentResults = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.allFiles],
      });
      let documentData: IPresignUrl[] = this.state.documents;
      let newDocumentUrls: IImageUrl[] = this.state.documentUrl;
      documentResults.forEach((item: DocumentPickerResponse) => {
        documentData.push({
          file_name: item.name,
          file_size: item.size ? item.size : 0,
          category: "document",
        });
      });
      documentResults.forEach((item: DocumentPickerResponse) => {
        newDocumentUrls.push({
          name: item.name,
          type: item.type,
          uri: item.uri,
        });
      });
      this.setState({ documents: documentData, documentUrl: newDocumentUrls });
    } catch (error) {}
  };
  handleEmail(text: string) {
    let regex = isEmail("email", text);
    if (regex.status === false) {
      this.setState({
        formValues: { ...this.state.formValues, developerEmail: text },
        formError: {
          ...this.state.formError,
          developerEmailError: "Please enter valid email",
        },
      });
      return false;
    } else {
      this.setState({
        formValues: { ...this.state.formValues, developerEmail: text },
        formError: { ...this.state.formError, developerEmailError: "" },
      });
      return true;
    }
  }
  handleFullName(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, developerFullName: text },
      formError: { ...this.state.formError, developerFullNameError: "" },
    });
  }
  handlePhoneNumber(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, phoneNumber: text },
      formError: { ...this.state.formError, phoneNumberError: "" },
    });
  }

  updateCountryCode = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          countryCode: event.target.value as string,
        },
      },
      this.validateCountryCode
    );
  };

  validateCountryCode = () => {
    const { countryCode } = this.state.formValues;
    let countryCodeErrorMsg = "";
    if (!countryCode) {
      countryCodeErrorMsg = "Select the country code";
    }
    this.setState({
      formError: {
        ...this.state.formError,
        countryCodeError: countryCodeErrorMsg,
      },
    });
  };

  createProjectHandleResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ loading: false });
      setStorageData("project_id",responseJson.data.attributes.id)
      this.navigateToCostPlanUpload(responseJson.data.attributes.id);
    } else if (responseJson.error || responseJson?.errors) {
      this.setState({ loading: false, isDisabled: false,
        backendErrors: { ...this.state.backendErrors, projectName: responseJson.errors[0].project_name }
      });
     

      this.showAlert(
        "Error",
        responseJson.error || responseJson.errors.toString()
      );
    }
  };


  handleSaveDraftProjectHandleResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ loading: false });
      setStorageData("project_id",responseJson.data.attributes.id)
      this.props.navigation.navigate("ProjectPortfolio");
    } else if (responseJson?.error || responseJson?.errors) {
      this.setState({ loading: false, isDisabled: false,
        backendErrors: { ...this.state.backendErrors, projectName: responseJson.errors[0].project_name }
      });
     
    }
  };

  

  getPlanName = (planId: string | number | null) => {
    switch (planId) {
      case "1":
        return "automated_cost_plan";
      case "2":
        return 'manual_cost_plan';
      case "3":
        return "specialist_cost_plan";
      default:
        break;
    }
  }

handleSaveDraftUploadFile=()=>{
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "ProjectPortfolio"
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
  
}

handleSaveDraftAPI = async () => {
  const { formValues, planId } = this.state;
  const currentPath = window.location.pathname;
  const projectIdMatch = currentPath.match(/\/project_id\/(\d+)/);
  const isUpdate = projectIdMatch !== null;

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.state.token,
  };

  this.setState({
    loading: false,
    openDialog: false,
    draft: true,
    openDialogLeave: false,
  });

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiDraftProjectCallId = requestMessage.messageId;

  const commonAttributes = {
    address: formValues.streetName,
    country: formValues.country,
    post_code: formValues.postCode,
    address2: formValues.streetName,
    city: formValues.city,
    state: formValues.state
  };

  const optionalAttributes = planId === 3 
    ? { email: null, name: null, last_name: null, phone: null }
    : { email: formValues.email, name: formValues.firstName, last_name: formValues.lastName, phone: formValues.phoneNumber };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    isUpdate && projectIdMatch ? `bx_block_profile/projects/${projectIdMatch[1]}` : "bx_block_profile/projects"
  );

  const body = {
    project: {
      plan_type: await this.getPlanName(planId),
      project_name: formValues.projectName,
      description: formValues.projectDescription,
      draft: "true",
      status: 'Draft',
      addressable_attributes: {
        ...commonAttributes,
        ...optionalAttributes,
      },
    },
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    isUpdate ? "PUT" : "POST"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
submitData = async () => {
  const { formValues, planId } = this.state;

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.state.token,
  };

  this.setState({ loading: true });

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiCreateProjectCallId = requestMessage.messageId;


  const commonAttributes = {
    address: formValues.streetName,
    address2: formValues.streetName,
    city: formValues.city,
    country: formValues.country,
    post_code: formValues.postCode,
    state: formValues.state,
  };


  const optionalAttributes = planId === 3 
    ? { email: null, name: null, last_name: null, phone: null }
    : { email: formValues.email, name: formValues.firstName, last_name: formValues.lastName, phone: formValues.phoneNumber };

  
  const body = {
    project: {
      plan_type: await this.getPlanName(planId),
      project_name: formValues.projectName,
      description: formValues.projectDescription,
      draft: "true",
      status:"Draft",
      files_uploaded : false,
      addressable_attributes: {
        ...commonAttributes,
        ...optionalAttributes,
      },
    },
  };

  
  const currentPath = window.location.pathname;
  const projectIdMatch = currentPath.match(/\/project_id\/(\d+)/); 
  const isUpdate = projectIdMatch !== null;
  if (isUpdate && projectIdMatch && projectIdMatch[1]) {
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_profile/projects/${projectIdMatch[1]}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
    );
} else {
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createProjectEndPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPiEndMethod
    );
}

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

getTrimmedValues = (values: FormValues) => ({
  projectName: values.projectName.trim(),
  firstName: values.firstName.trim(),
  lastName: values.lastName.trim(),
  email: values.email.trim(),
  phoneNumber: values.phoneNumber.trim(),
  streetName: values.streetName.trim(),
  city: values.city.trim(),
  postCode: values.postCode.trim(),
  country: values.country.trim(),
  state: values.state.trim(),
  projectDescription: values.projectDescription.trim(),
});
handleSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
  const trimmedValues = this.getTrimmedValues(values);
  this.setState({
    formValues: {
      ...this.state.formValues,
      ...trimmedValues,
    },
  },()=>{this.submitData();resetForm();});
  

 
  ;
};

handleSaveDraft = (values: FormValues) => {
  const trimmedValues = this.getTrimmedValues(values);

  this.setState(
    {
      formValues: {
        ...this.state.formValues,
        ...trimmedValues,
        draft: true,
      },
    },
    () => {
      this.handleSaveDraftAPI(); 
      this.handleCloseDialog();
    }
  );

};




getError = (fieldName: keyof FormValues, formik: any, backendErrors:any = {}) => {
  const isTouched = formik.touched[fieldName];
  const validationError = formik.errors[fieldName];
  const backendError = backendErrors[fieldName];

  if (backendError) {
    return backendError;
  }

  if (isTouched && validationError) {
    return validationError;
  }

  return '';
};

  saveDraftDisable = () => {
    const obj = {
      projectName: this.state.formValues.projectName,
      projectDescription: this.state.formValues.projectDescription,
      postCode: this.state.formValues.postCode,
      city: this.state.formValues.city,
      streetName: this.state.formValues.streetName,
      country: this.state.formValues.country,
      state: this.state.formValues.state,
    };
    return Object.values(obj).some(value => value.trim() !== '');
  }

  handleScopeClick = (selectedScope: number) => {
    localStorage.setItem("scopeId",JSON.stringify(selectedScope))
    this.setState({scopeId:selectedScope})
  };

  handleTypeClick = (selectedType: number) => this.setState({ selectedType });

  handleAmountChange = (id: number, action: string) => {
    const itemsCopy = this.state.shellsSubstructures;
    const selectedItemIndex = itemsCopy.findIndex((item) => item.id === id);
    switch (action) {
      case "add":
        itemsCopy[selectedItemIndex].amount += 1;
        break;
      case "remove":
        if (itemsCopy[selectedItemIndex].amount !== 0) {
          itemsCopy[selectedItemIndex].amount -= 1;
        }
        break;
      default:
        break;
    }
    this.setState({ shellsSubstructures: itemsCopy });
  };


planLogo = (type: string, isError: boolean) => {
    const iconColor = isError ? "#DC2626" : "#237182";
    switch (type) {
      case "image/jpeg":
      case "image/jpg":
        return <JpgIcon color={iconColor} />;
      case "image/png":
        return <PngIcon color={iconColor} />;
      default:
        return <PdfIcon color={iconColor} />;
    }
};


handleFileChange = async (sectionId: number, acceptedFiles: File[] | File) => {
 
  const filesArray = Array.isArray(acceptedFiles) ? acceptedFiles : [acceptedFiles];
  
  
  const currentCount = this.getFileCountBySection(sectionId);
  const MAX_FILES = 5;

  
  if (currentCount + filesArray.length > MAX_FILES) {
    const remainingSlots = MAX_FILES - currentCount;
    const filesToUpload = filesArray.slice(0, remainingSlots);

    for (const file of filesToUpload) {
      await this.handleFileUpload(file, sectionId);
    }

    if (remainingSlots < filesArray.length) {
      console.warn(`Only ${remainingSlots} more files were allowed for section ${sectionId}`);
    }
  } else {
  
    for (const file of filesArray) {
      await this.handleFileUpload(file, sectionId);
    }
  }
};

handleFileUpload = async (file: File, sectionId: number) => {
  const MAX_FILE_SIZE_MB = 10;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  if (file.size > MAX_FILE_SIZE_BYTES) {
    this.addFileError(sectionId, file, `File exceeds maximum size of ${MAX_FILE_SIZE_MB} MB`);
    return;
  }

  if (this.checkForDuplicateFile(file.name, sectionId)) {
    this.addFileError(sectionId, file, 'File already exists');
    return; 
  }

  this.trackFileUploadProgress(file, sectionId);
  this.getFileCountBySection(sectionId);
};
getFileCountBySection = (sectionId: number): number => {
  const { projectImages } = this.state.formValues;

  const sectionFiles = Object.values(projectImages)
    .flat()
    .filter((fileData) => fileData.section_id === sectionId);

  const sectionFileCount = sectionFiles.length;

  this.setState((prevState) => ({
    fileCounts: {
      ...prevState.fileCounts,
      [sectionId]: sectionFileCount,
    },
  }));

  return sectionFileCount;
};

checkForDuplicateFile(fileName: string, sectionId: number): boolean {
  const projectImages = this.state.formValues.projectImages;
  const fileNamesForSection = Object.values(projectImages)
    .flat()
    .filter((fileData) => fileData.section_id === sectionId)
    .map((fileData) => fileData.file_name);
  
  return fileNamesForSection.includes(fileName);
}

addFileError(sectionId: number, file: File, error: string) {
  this.setState((prevState) => {
    const updatedImages = { ...prevState.formValues.projectImages };
    const existingImages = Array.isArray(updatedImages[sectionId]) ? updatedImages[sectionId] : [];
    
    updatedImages[sectionId] = [
      ...existingImages,
      {
        section_id: sectionId,
        file_name: file.name,
        error: error,
        file: file,
        size: "",
        content_type: "",
        image: undefined,
        attachment_id: 0
      } as unknown as FileData
    ];

    return {
      formValues: {
        ...prevState.formValues,
        projectImages: updatedImages,
      },
    };
  });
}

trackFileUploadProgress = (file: File, sectionId: number) => {
  this.setState((prevState) => {
    const updatedImages = { ...prevState.formValues.projectImages };
    const existingImages = Array.isArray(updatedImages[sectionId]) ? updatedImages[sectionId] : [];

   
    if (existingImages.some((uploadedFile) => uploadedFile.file_name === file.name && uploadedFile.isUploading)) {
     
      return prevState; 
    }

  
    updatedImages[sectionId] = [
      ...existingImages,
      {
        section_id: sectionId,
        file_name: file.name,
        progress: 0,
        isUploading: true,
        error: "",
        file: file,
        size: file.size.toString(),
        content_type: file.type,
        image: undefined,
        attachment_id: 0,
      } as unknown as FileData,
    ];

    return {
      ...prevState,
      formValues: {
        ...prevState.formValues,
        projectImages: updatedImages,
      },
    };
  });


  const uploadInterval = setInterval(() => {
    this.setState((prevState) => {
      const updatedImages = { ...prevState.formValues.projectImages };
      const filesInSection = Array.isArray(updatedImages[sectionId]) ? updatedImages[sectionId] : [];
      const uploadingFileIndex = filesInSection.findIndex(
        (uploadedFile) => uploadedFile.file_name === file.name && uploadedFile.isUploading
      );

      if (uploadingFileIndex === -1) return prevState; 
      const currentFile = filesInSection[uploadingFileIndex];
      const newProgress = Math.min((currentFile.progress || 0) + 10, 100);

      const updatedFiles = filesInSection.map((uploadedFile, index) => {
        if (index === uploadingFileIndex) {
          return { ...uploadedFile, progress: newProgress };
        }
        return uploadedFile;
      });

      updatedImages[sectionId] = updatedFiles;

      const nextState = {
        ...prevState,
        formValues: {
          ...prevState.formValues,
          projectImages: updatedImages,
        },
      };

      if (newProgress === 100) {
        clearInterval(uploadInterval);
        this.updateUploadStatus(sectionId, file);
        this.uploadProjectFile(sectionId, file);
      }

      return nextState;
    });
  }, 200);
};



checkUploadCompletion(sectionId: number, file: File, uploadInterval: NodeJS.Timeout) {

  this.setState((prevState) => {
    const updatedImages = { ...prevState.formValues.projectImages };
    const filesInSection = Array.isArray(updatedImages[sectionId]) ? updatedImages[sectionId] : [];

    const isComplete = filesInSection.some(
      (uploadedFile) => uploadedFile.file_name === file.name && uploadedFile.progress === 100 && uploadedFile.isUploading
    );

    if (isComplete) {
      clearInterval(uploadInterval);
      this.updateUploadStatus(sectionId, file);
      this.uploadProjectFile(sectionId, file); 
    }
  });
}

updateUploadStatus(sectionId: number, file: File) {
  this.setState((innerState) => {
    const updatedImages = { ...innerState.formValues.projectImages };
    const files = updatedImages[sectionId] || [];

    const updatedFiles = files.map((uploadedFile) =>
      uploadedFile.file_name === file.name ? { ...uploadedFile, isUploading: false } : uploadedFile
    );

    updatedImages[sectionId] = updatedFiles;

    return {
      formValues: {
        ...innerState.formValues,
        projectImages: updatedImages,
      },
    };
  });
}
uploadProjectFile = async (sectionId: number, files: File | File[]) => {


   const token = await getStorageData("token");
  const formData = new FormData();
  formData.append("project_id", this.state.projectId.toString());

  const fileArray = Array.isArray(files) ? files : [files];

  fileArray.forEach((fileDetail) => {
    if (fileDetail instanceof File) {
      formData.append(`files[][section_id]`, sectionId.toString());
      formData.append(`files[][file_name]`, fileDetail.name);
      formData.append(`files[][content_type]`, fileDetail.type);
      formData.append(`files[][file]`, fileDetail);
    } else {
      console.warn(`Expected file but received: ${fileDetail}`);
    }
  });

  try {
    const header = {
      "token": token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadProjectFileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadProjectFileAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadProjectFileAPiEndMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    await new Promise((resolve) => setTimeout(resolve, 500));
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
    
    return true;
  } catch (error) {
    console.error(`Error in upload for section ${sectionId}:`, error);
    return false;
  }
};
handleFileDelete = async (sectionId: number, fileName: string, error: string = '') => {
  const { projectImages } = this.state.formValues;

  const attachmentIdsToRemove = this.getAttachmentIdsToRemove(sectionId, fileName, projectImages);

  if (error) {
    this.updateStateForError(sectionId, fileName);
  } else {
    await this.handleFileDeletion(sectionId, fileName, attachmentIdsToRemove);
  }
};

getAttachmentIdsToRemove = (sectionId: number, fileName: string, projectImages: { [key: number]: FileData[] }): number[] => {
  return Object.values(projectImages)
    .flat()
    .filter((file: FileData) => file.file_name === fileName && file.section_id === sectionId)
    .map(file => file.attachment_id);
};

updateStateForError = (sectionId: number, fileName: string) => {
  this.setState(prevState => {
    const updatedProjectImages = this.removeFileFromImages(prevState.formValues.projectImages, fileName);
    const updatedFileCounts = {
      ...prevState.fileCounts,
      [sectionId]: Math.max((prevState.fileCounts[sectionId] || 0) - 1, 0)
    };

    return {
      formValues: { ...prevState.formValues, projectImages: updatedProjectImages },
      fileCounts: updatedFileCounts,
    };
  });
  this.getProjectDetailsById()
};

handleFileDeletion = async (sectionId: number, fileName: string, attachmentIdsToRemove: number[]) => {
  if (attachmentIdsToRemove.length > 0) {
    const deleteSuccess = await this.deleteProjectUploadFile(sectionId, fileName, String(attachmentIdsToRemove[0]));
    if (deleteSuccess) {
      this.setState(prevState => {
        const updatedProjectImages = this.removeFileFromImages(prevState.formValues.projectImages, fileName);
        const updatedFileCounts = {
          ...prevState.fileCounts,
          [sectionId]: Math.max((prevState.fileCounts[sectionId] || 0) - 1, 0)
        };

        return {
          formValues: { ...prevState.formValues, projectImages: updatedProjectImages },
          fileCounts: updatedFileCounts,
        };
      });
    } else {
      this.getProjectDetailsById();
    }
  }
};

removeFileFromImages = (projectImages: { [key: number]: FileData[] }, fileName: string): { [key: number]: FileData[] } => {
  return Object.entries(projectImages).reduce((result, [key, files]) => {
    result[parseInt(key)] = Array.isArray(files)
      ? files.filter((file: FileData) => file.file_name !== fileName)
      : files;
    return result;
  }, {} as { [key: number]: FileData[] });
};




isSubmitDisabled = () => {
  const { formValues } = this.state;
  let isMandatoryIncomplete = false;

  this.state.specialistCostPlanList.forEach((section) => {
    
      const sectionId = section.attributes.id;
      const isMandatory = section.attributes.mandatory;

      const hasImages = Array.isArray(formValues.projectImages) 
          ? formValues.projectImages.filter(image => {
             

              return image.section_id === sectionId; 
          })
          : [];
      
      if (isMandatory && hasImages.length === 0) {
          isMandatoryIncomplete = true;         
      } 
  });

  
 
  const isError = Object.values(formValues.projectImages).flat().some(file => file.error);
 
  return isMandatoryIncomplete || isError; 
};
isSaveDraftDisabled = () => {
  const { formValues } = this.state;
  let hasAnyImages = false; 

  if (Array.isArray(formValues.projectImages)) {
    hasAnyImages = formValues.projectImages.some(image => image.section_id);
  }

  const isError = Object.values(formValues.projectImages).flat().some(file => file.error);
  const shouldDisable = !hasAnyImages || isError;


  return shouldDisable; 
};

deleteProjectUploadFile = async (section_id: number, file_name: string, attachment_id: string): Promise<boolean> => {
  try {
    const token = await getStorageData("token");
    const project_id = this.state.projectId;

    const header = {
      "token": token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteProjectUploadFileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteProjectUploadFileAPiEndPoint}/${project_id}/delete_file/${attachment_id}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteProjectUploadFileAPiEndMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return  true;
  } catch (error) {
    console.error("Error deleting file:", error);
    return false;
  }
};

deleteProjectUploadResponse=(responseJson:any)=>{
if(responseJson){
  this.getProjectDetailsById()
return true

}
}

navigateToType = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectType"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigateToShells = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectPortfolioShells"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleSearchInputChange = (search: string) => {
    const filteredItems = this.state.shellsSubstructures.filter((shell) =>
      shell.name.toLowerCase().includes(search.toLowerCase())
    );
    this.setState({ filteredItems, searchInputText: search , searchError: filteredItems.length === 0 });
  };

  navigateToScopes = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectScope"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigateToCostPlanUpload = (project_id:any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
       this.state.handleNavigation !== 3 ? "ProjectType" : "ProjectCostPlanUpload"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `3/project_id/${project_id}`);
    this.send(message);
  };

  getspecialistCostPlanList = async() => {
    this.setState({loading:true})
    const token = await getStorageData("token") 
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSpecialistCostPlanListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpecialistCostPlanListAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  } 

  specialistCostPlanListResponse = (responseJson:any) => {
    this.setState({specialistCostPlanList:responseJson.data,loading:false});
  }
 


  handleStripe = async() => {
    const token = await getStorageData("token");
    const plan_id = 3
    const project_id = await getStorageData("project_id"); 
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
     plan_id: plan_id,
     files_uploaded: true
    };  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.handelStripeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stripeAPiEndPoint}/${project_id}/pay_specialist_plan`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.stripeAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  stripeResponse=(responseJson:any)=>{
    window.location.href = responseJson.checkout_url;
  }

  goBack = () => {
    if (this.state.activeStep <= 1) {
      this.props.navigation.goBack();
    } else {
      this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }));
    }
    setTimeout(() => {
      if (this.state.projectId) {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "CreateEditProjectPortfolio"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), `3/project_id/${this.state.projectId}`);
        this.send(message);
      }
    }, 500); 
  };

  goBack2 = (formik:any) => {
    if(!this.handleToggleButton(formik.errors) && formik.dirty){
      this.setState({openDialogLeave:true})
    }else{
    if (this.state.activeStep <= 1) {
      this.props.navigation.goBack();
    } else {
      this.setState((prev) => ({ activeStep: prev.activeStep - 1 }));
    }
  }

};
  getProjectDetailsById=()=>{
    const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.state.token,
  };

 

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetProjectByIdCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getProjectByIdAPIEndPoint}/${this.state.projectId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPiEndMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);


}

  getProjectType = async() => {
    const token = await getStorageData("token") 
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };
    
    this.setState({loading:true})

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectTypeGetCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectTypeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  } 

  handleProjectTypeData = (res:any)=>{
         const data = res.data.map((item:any)=>{
           return { id : item.id ,
                   icon : buildNewIcon ,
                   title:item.attributes.name,
                   description:"Textlabel textlabel textlabel"}
          })
                this.setState({projectTypes:data,loading:false})
         
  }

  getProjetScopes = async() => {
    const token = await getStorageData("token") 
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };

    this.setState({loading:true})

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectScopesGetCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectScopesCategoriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  } 

  handleScopeCategories = (res:any)=>{
    const data = res.map((item:any)=>{
      return {
        id: item.id,
        icon: buildNewIcon,
        title: item.name,
        description: "Building a new construction and fitting out",
      }
    })
    this.setState({scopeCategories:data,loading:false})
  }

  handleCondition = (condition:any,True:any,False:any)=>{
       return condition ? True :False
  }

  handleChangePage = (val: boolean) => {
      this.setState({toggle:val})
  }

  handleClose = ()=>{
      this.setState({anchorEl:null})
  }

  handlePopup = (event: React.MouseEvent<HTMLButtonElement>,id:string) => {
    this.setState({ anchorEl: event.currentTarget , buttonId:id });
  };
  getProjectByIdResponse=(responseJson:any) =>{
    this.setState({
      formValues:{projectName: responseJson.data.attributes.project_name,
      clientDetails: "",teamSize: "", projectUrl: "",
      developerEmail: "",
      developerFullName: "",
      projectDescription: responseJson.data.attributes.description,
      firstName: responseJson.data.attributes.addressable.name,
      postCode: responseJson.data.attributes.addressable.post_code,
      lastName: responseJson.data.attributes.addressable.last_name,
      email: responseJson.data.attributes.addressable.email,
      city: responseJson.data.attributes.addressable.city,
      phoneNumber: responseJson.data.attributes.addressable.phone,
      streetName: responseJson.data.attributes.addressable.address,
      startDate: responseJson.data.attributes.start_date,
      country: responseJson.data.attributes.addressable.country,
      endDate: responseJson.data.attributes.end_date,
      countryCode: responseJson.data.attributes.addressable.country,
      state: responseJson.data.attributes.addressable.state,
      draft: responseJson.data.attributes.draft,
      projectDocuments: [],
      projectImagesUrl: [],
      projectImages: responseJson.data.attributes.uploaded_files || [],
    }})}

  handleTaskListPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElT: event.currentTarget });
  };

  handleClose2 = ()=>{
    this.setState({anchorElT:null})
}

  handleDateChange = (event:any)=>{
        if (event) {
         this.setState({tempDate:event})
        }
  }

   getShellsData = async() => {
    const token = await getStorageData("token") 
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const storedScopeId = localStorage.getItem("scopeId");
    const scopeId = storedScopeId ? JSON.parse(storedScopeId) : null;

    
    this.setState({loading:true})

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shellsGetCallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_categories/categories?scope_category_id=${scopeId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  } 

  handleShellsResponse = (res:any)=>{
    let response = res.map((item:any)=> {
      return  {
        id: item.id,
        logo: item.attributes.icon_url,
        name: item.attributes.name,
        amount:  0,
      }
    } )
    this.setState({shellsData:response, shellsSubstructures:response,
      filteredItems: response})
  }

  getRoomsData = async() => {
    const token = await getStorageData("token")

    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };

    this.setState({loading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.roomsGetCallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/sub_categories?category_id=1"
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  
  handleRoomsResponse = (res: any) => {
    const seenNames = new Set();
    
    const uniqueArray = res.filter((value: any) => {
      const name = value.attributes.name;
      if (seenNames.has(name)) {
        return false; 
      }
      seenNames.add(name);
      return true;
    });
  
    let response = uniqueArray.map((item: any) => {
      return {
        id: item.id,
        logo: item.attributes.icon_url,
        name: item.attributes.name,
        amount: 0,
      };
    });
  
    this.setState({
      roomsData: response,
    });
  };

  handleButton = ()=>{
    const {buttonId} =this.state
    if (buttonId === "button1") {
       this.setState({stages:["Preliminaries","Foundations and substructure","External wall structure and lining"]})
    }
    if (buttonId === "button2") {
     this.setState({stages:["Bathroom","Bathroom(1)","Bathroom(2)"]})
    }
    if (buttonId === "button3") {
     this.setState({stages:["Labour","Material"]})
    }
  }
 
  handleToggleButton = (object:object) => {
    let isBlank = false;
    _.forEach(object,(data:string)=>{
    if(data.includes("can't be blank")){
      isBlank = true
      return false;
    }
    })
    return isBlank;
  }
} 



                                                                                                                                                                                                                                             
// Customizable Area End
